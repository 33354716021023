<template>
  <nav class="navbar navbar-expand-lg topbar">

    <div class="logo" v-if="!isSectionAllowed('organizations')">
      <div class="logo-img">
        <img v-bind:src="getOrganization().loginLogoUrl" />
      </div>
    </div>


    <div class="container-fluid">
      <div
      v-if="isSectionAllowed('organizations') && organizations && organizations.length > 1"
      class="filterContainer"
    >
      <div class="organizationSelectorContainer filterElement">
        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="logo-img">
              <span class="label" v-if="!selectedOrganizationLogo">
                {{ selectedOrganizationName}}
              </span>
              <img v-if="selectedOrganizationLogo" v-bind:src="selectedOrganizationLogo" />
            </div>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              v-for="(item, index) in organizations"
              class="dropdown-item"
              href="#"
              @click="changeSelectedOrganization($event, index)"
            >{{item.name}}</a>
          </div>
        </div>
      </div>
    </div>
      <a class="navbar-brand" href="#"></a>
      <!-- breaks mobile/responsive if removed -->
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{toggled: $sidebar.showSidebar}"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style="display: none;"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-if="isCreateAllowed('projects')">
            <a class="nav-link" v-bind:href="studioUrl" target="_blank" rel='noopener' style="border-right: solid 1px rgba(255,255,255,0.5);">Studio</a>
          </li>

          <li class="nav-item dropdown">
            <a id="navbarDropdown"
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
              {{getUserName()}}
            </a>
            <div
              class="dropdown-menu"
              aria-labelledby="navbarDropdown"
              style="top: 8px !important;"
            >
              <a v-on:click="logout" href="#" class="dropdown-item">{{$t('signOut')}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
const config = require("config");
const baseUrl = config.apiUrl.split(".")[1];
import LoginService from "src/services/login";
import Utils from "src/mixins/utils";
import OrgService from "src/services/organization";

export default {
  mixins: [Utils],
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      studioUrl: `https://studio.${baseUrl}.com/`,
      selectedOrganizationName: null,
      selectedOrganizationLogo: null,
      organizations: []
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      try {
        await LoginService.logout();
        window.location = "/";

      } catch (err) {
        console.error(err);
      }
    },
    updateOrganization(index) {
      const org = this.organizations[index];

      // update logo and name
      this.selectedOrganizationLogo = null;
      this.selectedOrganizationName = null;

      if (org) {
        this.setOrganizationPublicId(org.publicId);
        this.selectedOrganizationLogo = org.loginLogoUrl;
        this.selectedOrganizationName = org.name;
      }

      // and dispatch event to all pages
      var event = new Event('selectedOrganizationChanged');
      window.dispatchEvent(event);
    },
    changeSelectedOrganization(event, index) {
      event.preventDefault();
      this.updateOrganization(index);
    },
    populateOrganizations: function() {
      const index = this.organizations.findIndex(el => el.publicId == this.getOrganizationPublicId());
      this.updateOrganization(index);
    }
  },
  beforeMount() {
    if (this.isSectionAllowed("organizations")) {
      let scoper = this;
      OrgService.listOrganizations()
        .then(function(results) {
          results.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          scoper.organizations = results;
          scoper.populateOrganizations();

        }).catch(err => {
          console.error(err)
        });
    }
  }
};
</script>


<style scoped>
.username {
  pointer-events: none;
  cursor: default;
}

.topbar{
  background: #000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
}


.filterContainer {
  text-align: center;
  left: 15px;
}
.filterElement {
  text-align: center;
  margin-top: 0px;
  width: min-content;
}

.logo {
  text-align: center;
}
.logo-img {
  display: inline-block;
}

.logo-img img {
  max-width: 180px;
  max-height: 39px;
  margin-right: 10px;
}

.logo-img .label {
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 10px;
}


.dropdown-menu {
  max-height: 355px;
  overflow: auto;
  top: 15px !important;
  margin-top: 30px !important;
}

#dropdownMenuButton {
  border: none;
      margin: 0px;
    padding: 0px;
    opacity: 1;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #ddd !important;
    padding: 3px 15px !important;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #fff !important;
}
</style>
