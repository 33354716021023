<template>
  <div id="objectContainer" class='container'>
  </div>
</template>

<script>
  import Vue from 'vue'
  import EmbedService from 'src/services/embed'
  import Utils from "src/mixins/utils";

  export default {
    mixins: [Utils],
    mounted() {
      let objectEl = document.createElement('object');
      objectEl.setAttribute('type', 'text/html');
      objectEl.setAttribute('width', '100%');
      objectEl.setAttribute('height', '100%');
      objectEl.setAttribute('style', 'overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px');

      let embedEl = document.createElement('embed');
      embedEl.setAttribute('width', '100%');
      embedEl.setAttribute('height', '100%');

      let errorEl = document.createTextNode("Error: Embedded data could not be displayed.");

      objectEl.appendChild(embedEl);
      objectEl.appendChild(errorEl);

      let divEl = document.getElementById("objectContainer");
      divEl.appendChild(objectEl);

      let embedCode = this.$route.params.embedCode;

      EmbedService.retrieveEmbed(embedCode)
        .then(result => {
          let view = result.views;

          this.setAuthZToken(result.accessToken);
          this.setOrganization(view.landscape.organization);

          if (view && view.projectUrl) {
            document.title = view.name;

            let viewUrl;
            if (view.projectUrl.startsWith('#')) {
              const baseUrl = require('config').apiUrl.split('.')[1];
              viewUrl = `https://studio.${baseUrl}.com/${view.projectUrl}`;
            } else {
              viewUrl = view.projectUrl;
            }

            objectEl.setAttribute('data', viewUrl);
            embedEl.setAttribute('src', viewUrl);
          }
        })
        .catch(err => console.log(err));
    }
  }
</script>

<style>
</style>
