const api = require('./api-caller');
const { getOrganizationPublicId, getUserScreenName } = require('../mixins/utils').methods;

const baseEndpoint = '/organizations';


module.exports = {
    listProjects: (groupBy = 'name') => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/projects?groupBy=${groupBy}`
        return api(endpoint, 'GET')
    }, 

    getCurrentUserProjects: () => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/projects?user=${getUserScreenName()}`;
        return api(endpoint, 'GET')
    }
}
