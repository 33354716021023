<template>
  <center>
    <div class="o-page c-login-page">
      <div class="c-login-page__content">
        <h1 class="c-login-page-title">Terms Of Use</h1>
        <div class="c-login-page-form">
          <textarea readonly>
              {{$t('termsOfUse')}}
          </textarea>
        </div>
        <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
      </div>
    </div>
  </center>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {};
  }
};
</script>

<style>
.c-login-page-title {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.form-container {
  width: 300px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-left: 50%;
  left: -150px;
  position: relative;
  top: 200px;
  text-align: center;
}

.c-login-page-form {
    margin-top: 20px;
    margin-bottom: 20px;
}

.c-login-page__input {
  background-color: white;
  color: black;
}

.form-container input {
  margin-bottom: 20px;
}

.form-container button {
  margin-bottom: 20px !important;
}

.error {
  padding: 5px;
  background-color: #f44336;
  color: white;
  margin-bottom: 20px !important;
}

textarea {
   resize: none;
   width: 800px;
   height: 800px;
   font-size: large;
}

textarea:focus {
    outline: none;
}

</style>
