<template>
    <div id="createEditModal" class="screen-area ">
        <div class="modal-container ">
            <div class="col-md-12 order-md-1" v-if="!readOnly">
                <h4 id="createEditModalTitle" class="mb-3"></h4>
                <form v-on:submit.prevent id="createEditForm" action="" method="post">

                    <!--  ------------ UPLOAD MODE ------------------------- -->

                    <div v-if="mode=='upload'">
                        <!-- --- FILE UPLOAD DROP AREA --- -->
                        <div class="mb-3 dropbox" v-if="!isEdit" :style="{ opacity: fileUrl=='' ? 1: 0.3}">
                            <input type="file" id="fieldFile" name="file" ref="file" accept=".csv, .xls, .xlsx" @change="fileChanged" />
                            <p v-if="this.fileName == '' " class="control-label" for="fieldFile">{{$t('dsCreateEdit.form.csv.title')}} <br/> {{$t('dsCreateEdit.form.csv.subTitle')}}</p>
                            <p v-if="this.fileName != '' " class="control-label" for="fieldFile">{{ this.fileName }}</p>
                        </div>
                        <BaseButton v-if="fileName" style="float: right; color: #999; border-color: #999; padding-top: 7px; padding-bottom: 7px; margin-top: -10px;" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.clear')" :onClick="resetUpload" buttonType="submit"></BaseButton>

                        <!-- --- UPLOAD URL ---  -->
                        <div class="mb-3" v-if="!isEdit">
                            <label class="control-label" for="fileUrl">{{$t('dsCreateEdit.form.fileUrl')}}</label>
                            <input v-model="fileUrl" type="text" id="fileUrl" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.fileUrl_placeholder')" @keyup="fileUrlChanged" :style="{ 'border-color': fileUrl!=='' ? '#999': '#E3E3E3'}"/>
                        </div>


                        <span v-if="type === 'csv' && type !== ''">
                            <div class="mb-3">
                                <label class="control-label" for="fieldSeparator">{{$t('dsCreateEdit.form.separator')}}</label>
                                <select v-model="separator" name="rowNames" id="fieldSeparator" class="form-control small" @change="updateExampleCsvTemplate">
                                    <option value=",">{{$t('dsCreateEdit.form.delimiters.comma')}}</option>
                                    <option value=";">{{$t('dsCreateEdit.form.delimiters.semicolon')}}</option>
                                    <option value="	">{{$t('dsCreateEdit.form.delimiters.tabular')}}</option>
                                    <option value="|">{{$t('dsCreateEdit.form.delimiters.pipe')}}</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldHeaders">{{$t('dsCreateEdit.form.headers.title')}}</label>
                                <select v-model="rowNames" name="rowNames" id="fieldHeaders" class="form-control small" @change="updateExampleCsvTemplate">
                                    <option value="auto">{{$t('dsCreateEdit.form.headers.auto')}}</option>
                                    <option value="yes">{{$t('dsCreateEdit.form.headers.yes')}}</option>
                                    <option value="no">{{$t('dsCreateEdit.form.headers.no')}}</option>
                                </select>
                            </div>
                            <div class="mb-3" v-if="!isEdit">
                                <label class="control-label" for="fieldFile">{{$t('dsCreateEdit.form.example_csv')}}</label>
                                <div class="csv_example" v-html="exampleCsv"></div>
                            </div>
                        </span>



                        <span v-if="['excel'].includes(type) && type !== ''">
                            <div class="mb-3" v-if="!isEdit">
                                <label class="control-label" for="fileUrl">{{$t('dsCreateEdit.form.fileUrl')}}</label>
                                <input v-model="fileUrl" type="text" id="fileUrl" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.fileUrl_placeholder')" @keydown="fileUrlChanged"/>
                            </div>
                        </span>
                    </div>

                    <!--  ------------ DATABASE MODE ------------------------- -->

                    <div v-if="mode=='database'">
                        <h4>{{$t('dsCreateEdit.form.tech_info')}}</h4>
                        <div class="mb-3">
                            <label class="control-label" for="fieldType">{{$t('dsCreateEdit.form.type')}}</label>

                            <select v-model="type" name="type" id="fieldType" class="form-control small">
                                <option name="" value="">{{$t('datasources.choose_ds_type')}}</option>
                                <option name="" value="redshift">AWS RedShift</option>
                                <option name="" value="mariadb">MariaDB</option>
                                <option name="" value="mongodb">MongoDB</option>
                                <option name="" value="mysql">MySQL</option>
                                <option name="" value="oracle">Oracle</option>
                                <option name="" value="postgres">PostgreSQL</option>
                                <option name="" value="sqlserver">SQL Server</option>
                                <option name="" value="db2">IBM DB2</option>
                                <option name="" value="saphana">SAP HANA</option>
                            </select>
                        </div>
                        <span v-if="!['', 'csv', 'excel'].includes(type)">
                            <div class="mb-3">
                                <label class="control-label" for="fieldHost">{{$t('dsCreateEdit.form.host')}}</label>
                                <input v-model="host" type="text" id="fieldHost" name="host" class="form-control medium" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldCloduProvider">{{$t('dsCreateEdit.form.cloudProvider')}}</label>
                                <select v-model="cloudProvider" name="cloduProvider" id="cloduProvider" class="form-control small">
                                    <option v-for="cloud in $t('dsCreateEdit.form.cloudProvider_options')" :value="cloud.value" :selected="cloud.value == cloudProvider">
                                        {{cloud.text}}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="contryServer">{{$t('dsCreateEdit.form.country')}}</label>
                                <v-autocomplete
                                    :items="countriesOption"
                                    class="form-control small"
                                    solo
                                    v-model="country"
                                    label="Choose Country"
                                >
                                </v-autocomplete>
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldPort">{{$t('dsCreateEdit.form.port')}}</label>
                                <input v-model="port" type="number" id="fieldPort" name="port" class="form-control micro" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldUser">{{$t('dsCreateEdit.form.user')}}</label>
                                <input v-model="user" type="text" id="fieldUser" name="user" class="form-control small" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldPassword">{{$t('dsCreateEdit.form.password')}}</label>
                                <input v-model="password" type="password" id="fieldPassword" name="password" class="form-control small" />
                            </div>
                            <div class="mb-3">
                                <label class="control-label" for="fieldDatabase">{{$t( type== 'oracle' ? 'dsCreateEdit.form.serviceName' : 'dsCreateEdit.form.database')}}</label>
                                <input v-model="database" type="text" id="fieldDatabase" name="database" class="form-control medium" />
                            </div>
                            <span v-if="!['mongodb'].includes(type)">
                              <div class="mb-3">
                                  <label class="control-label" for="fieldSchema">{{$t('dsCreateEdit.form.schema')}}</label>
                                  <input v-model="schema" type="text" id="fieldSchema" name="schema" class="form-control medium" />
                              </div>
                            </span>
                        </span>
                    </div>


                    <!--  ------------ API MODE ------------------------- -->

                    <div v-if="mode=='api'">
                        <h4>{{$t('dsCreateEdit.form.tech_info')}}</h4>
                        <div class="mb-3">
                            <label class="control-label" for="fieldType">{{$t('dsAPI.plataform')}}</label>

                            <select v-model="type" name="type" id="fieldType" class="form-control small">
                                <option name="" value="">{{$t('dsAPI.choose_plataform')}}</option>
                                <option name="" value="shopify">Shopify</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldHost">{{$t('dsCreateEdit.form.host')}}</label>
                            <input v-model="host" type="text" id="fieldHost" name="host" class="form-control medium" />
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldUser">{{$t('dsAPI.apiKey')}}</label>
                            <input v-model="user" type="text" id="fieldUser" name="user" class="form-control small" />
                        </div>

                        <div class="mb-3">
                            <label class="control-label" for="fieldPassword">{{$t('dsCreateEdit.form.password')}}</label>
                            <input v-model="password" type="password" id="fieldPassword" name="password" class="form-control small" />
                        </div>
                    </div>


                    <!--  ------------ COMMON INFO (Available for both modes) ------------------------- -->

                    <h4>{{$t('dsCreateEdit.form.general_info')}}</h4>
                    <div class="mb-3">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.name')}}</label>
                        <input :readonly="isEdit ? true : false" v-model="nameValue" type="text" id="fieldName" name="name" class="form-control medium" />
                    </div>
                    <div class="mb-3">
                        <label class="control-label" for="fieldDescription">{{$t('dsCreateEdit.form.description')}}</label>
                        <textarea v-model="description" type="text" id="fieldDescription" name="description" class="form-control medium" style="min-height: 100px;"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.provenance')}}</label>
                        <input v-model="provenance" type="text" id="fieldName" name="name" class="form-control medium" :placeholder="$t('dsCreateEdit.form.provenance_placeholder')"/>
                    </div>
                    <div class="mb-3">
                        <label class="control-label">{{$t('dsCreateEdit.form.rightHolder')}}</label>
                        <select v-model="rightHolder" name="rightHolder" id="fieldrightHolder" class="form-control small">
                            <option v-for="holder in rightsHolderOptions" :value="holder.value">
                                {{holder.text}}
                            </option>
                        </select>
                    </div>


                    <br/><h4>{{$t('dsCreateEdit.form.pii_info')}}</h4>
                    <div class="mb-3">
                        <label class="control-label">{{$t('dsCreateEdit.form.includesPII')}}</label>
                        <select v-model="includesPII" name="includesPII" id="fieldincludesPII" class="form-control small">
                            <option v-for="option in $t('dsCreateEdit.form.includesPIIOptions')" :value="option.value">
                                {{option.text}}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="includesPII=='true'">
                        <label class="control-label">{{$t('dsCreateEdit.form.piiDataFormat')}}</label>
                        <select v-model="piiDataFormat" name="piiDataFormat" id="fieldpiiDataFormat" class="form-control small">
                            <option v-for="format in $t('dsCreateEdit.form.piiDataFormatOptions')" :value="format.value">
                                {{format.text}}
                            </option>
                        </select>
                    </div>

                    <hr class="mb-4">
                    <BaseButton style="float: right; color: green; border-color: green" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t( mode == 'upload' ? 'dsCreateEdit.upload' : 'dsCreateEdit.connect' )" :onClick="validateForm" buttonType="submit"></BaseButton>
                    <BaseButton style="float: right" class="btn btn-primary btn-lg btn-block" :buttonLabel="$t('basebutton.cancel')" :onClick="onClickQuit"></BaseButton>
                </form>
            </div>

            <div v-if="readOnly">
                <br/>
                <div class="row">
                    <div class="col-md-4">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.name')}}: </label>
                        {{ nameValue }}
                    </div>
                    <div class="col-md-4">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.createdAt')}}: </label>
                        {{ createdAt }}
                    </div>
                    <div class="col-md-4">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.updatedAt')}}: </label>
                        {{ updatedAt }}
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-12">
                        <label class="control-label" for="fieldName">{{$t('dsCreateEdit.form.description')}}: </label>
                        {{ description }}
                    </div>
                </div>
                <hr/>
            </div>

            <div v-if="isEdit" class="row panelsContainer">
              <div class=" commonPanel datasetsPanel">
                <div class="row panelRow">
                  <div class="col-10 panelTitle">{{$t('datasources.datasets')}}</div>
                  <div class="col-2 panelCounter">{{datasets.length}}</div>
                </div>
                <div class="panelBody">
                  <div class="container" v-if="!datasets.status_msg">
                    <div class="row panelRow columnNames">
                      <div class="col-6">{{$t('datasources.name')}}</div>
                      <div class="col-2">{{$t('datasources.fields')}}</div>
                      <div class="col-4">Right Holder</div>
                    </div>
                    <div class="row panelRow" v-for="item in datasets">
                      <div class="col-6"><a :href="'/#/datasets/'+item.publicId">{{item.name}}</a></div>
                      <div class="col-2">{{item.fields}}</div>
                      <div class="col-4">
                          <select name="rightHolder" id="fieldrightHolder" class="form-control small">
                            <option v-for="holder in rightsHolderOptions" :value="holder.value" :selected="holder.value == item.rightHolder">
                                {{holder.text}}
                            </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="sqsMessage" v-if="datasets.status_msg">
                    {{datasets.status_msg}}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="false" class="row dataProductsContainer">
              <div class=" commonPanel datasetsPanel">
                <div class="row panelRow">
                  <div class="col-10 panelTitle">{{$t('datasources.open_with')}}</div>
                </div>
                <div class="panelBody">
                  <div class="container">
                    <div class="">
                      {{$t('datasources.no_data_products')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>


<script>
import BaseButton from './BaseButton.vue'
import { pathToFileURL } from 'url';
import { countries } from '../services/countries';
import Swal from 'sweetalert2'
import Utils from "src/mixins/utils";

export default {
    name: 'CreateEditModal',
    mixins: [Utils],
    components: {
        'BaseButton': BaseButton,
    },
    data() {
        var rightsHolderOptions = this.$t("dsCreateEdit.form.rightsHolderOptions");
        for (let i = 0; i < rightsHolderOptions.length; i++) {
            const element = rightsHolderOptions[i];
            if (element.value == 'own_company') {
                element.text = this.getOrganization().name;
            }
        }
        return {
            mode: this.$route.params.mode,
            readOnly: false,
            nameValue: '',
            description: '',
            provenance: this.$route.params.mode === 'api' ? 'API' : '',
            includesPII: '',
            piiDataFormat: '',
            connection_type: '',
            rightHolder: '',
            type: '',
            createdAt: '',
            updatedAt: '',
            // DB only values
            host: '',
            cloudProvider: '',
            country: '',
            port: 5432,
            user: '',
            password: '',
            database: '',
            schema: 'public',

            // CSV only values
            fileUrl: '',
            file: '',
            separator: ',',
            rowNames: 'auto',
            fileName: '',
            content: '',

            // Parsed
            rightsHolderOptions: rightsHolderOptions,

            datasets: [],
            exampleCsvTemplateHeader: 'Super Name,Real Name,Publisher,Creators,Strength',
            exampleCsvTemplateBody: 'Superman,Clark Kent,DC Comics,Action Comics,100<br> Batman,Bruce Wayne,DC Comics,Action Comics,18<br> Spiderman,Peter Parker,Marvel,Stan Lee,55',
            exampleCsv: '',
            countriesOption: countries,
            serverOptions: ['AWS', 'Azure', 'Google Cloud', 'Oracle Cloud', 'IBM Cloud', 'Alibaba Cloud', 'Huawei Cloud', 'DigitalOcean', 'On Premise', 'Private Data Center']
        }
    },
    methods: {
        updateExampleCsvTemplate(){
            console.log("updateExampleCsvTemplate: ", this.separator );
            if( this.rowNames == 'no' ){
                this.exampleCsv = '';
            }else{
                this.exampleCsv = this.exampleCsvTemplateHeader + '<br/>';
            }
            this.exampleCsv += this.exampleCsvTemplateBody;
            this.exampleCsv = this.exampleCsv.replace(/,/g, this.separator );
        },
        resetData() {
            Object.assign(this.$data, this.$options.data());
        },
        resetForm: function() {
            [...document.getElementsByClassName("form-control")].map(input => {
                input.classList.remove("invalidField");
            });
        },
        resetUpload(){
            this.fileName = '';
            this.content = '';
            this.nameValue = '';
            this.fileUrl = '';
        },
        getFileName: function(filePath){
            let filePath_parts = filePath.split('.');
            if( filePath_parts.length > 1 )
                filePath_parts.pop()
            filePath_parts = filePath_parts.join('.').split('/')
            let fileName = filePath_parts[ filePath_parts.length-1 ];
            return fileName;
        },
        getFileExtension: function(filePath) {
            let parts = filePath.split('.');
            let extension = parts.pop().toLowerCase();

            parts = extension.split('?');
            // if ? was not found, splits return the whole string in an array
            extension = parts[0];

            return extension;
        },
        fileUrlChanged: function (event) {
            $("#fieldFile").val('');
            this.fileName = '';
            this.content = '';
            const type = this.getFileExtension( $("#fileUrl").val() );
            this.nameValue = this.getFileName( $("#fileUrl").val() );

            if(['xls', 'xlsx'].includes(type)) {
                this.type = 'excel';
            } else if(['csv'].includes(type)) {
                this.type = 'csv';
            }else{
                this.type = '';
            }
        },
        fileChanged: function (event) {
            this.fileUrl = '';
            var vueCtrl = this;
            let file = event.target.files[0];
            vueCtrl._data.fileName = file.name;
            const type = this.getFileExtension( file.name );
            this.nameValue = this.getFileName( file.name );
            var reader = new FileReader();
            if(['xls', 'xlsx'].includes(type)) {
                this.type = 'excel';
                reader.onload = function(fileReadeEvent) {
                    const data = fileReadeEvent.target.result;
                    let content = Buffer.from(data, 'binary');
                    vueCtrl._data.content = content
                }
                reader.onerror = e => console.log("File could not be read! " + event.target.error.code);
                reader.readAsBinaryString(file);
            } else if(['csv'].includes(type)) {
                this.type = 'csv';
                reader.onload = function(fileReadeEvent) {
                    let content = fileReadeEvent.target.result;
                    vueCtrl._data.content = content;
                }
                reader.onerror = e => console.log("File could not be read! " + event.target.error.code);
                reader.readAsText(file);
            }else{
                Swal.fire({
                    title: 'Error!',
                    text: this.$t('datasources_erros.unsupported_file'),
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
                $("#fieldFile").val('');
                this.fileName = '';
                this.content = '';
            }
        },
        validateForm: function() {

            let errorMessage;
            if ( this.nameValue == '' ) {
                errorMessage = this.$t('datasources_erros.name')
            } else if (this.provenance == '') {
                errorMessage = this.$t('datasources_erros.provenance')
            } else if ( this.rightHolder == '' ) {
                errorMessage = this.$t('datasources_erros.rightHolder')
            } else if ( this.includesPII == '' ) {
                errorMessage = this.$t('datasources_erros.includesPII')
            } else if ( this.includesPII == 'true' && this.piiDataFormat == '' ) {
                errorMessage = this.$t('datasources_erros.piiDataFormat')
            } else if ( this.fileUrl && this.type == '' ) {
                errorMessage = this.$t('datasources_erros.unsupported_file');
            } else if (this.type == '') {
                errorMessage = this.$t('datasources_erros.type')
            } else if ( this.type == "csv" || this.type == "excel" ) {
                if ( this.fileName == '' && this.fileUrl == '' )
                    errorMessage = this.$t('datasources_erros.upload_file')
                else if ( this.fileName == '' && this.fileUrl != '' ){
                    // If URL is provided, confirm it has a valir URL format
                    var isValidUrl = this.fileUrl.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                    if( !isValidUrl )
                        errorMessage = this.$t('datasources_erros.invalid_url')
                }
            } else if (!['csv', 'excel'].includes(this.type)) {
                if (this.host == '') {
                    errorMessage = this.$t('datasources_erros.host')
                } else if (this.cloudProvider == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.cloud_provider')
                } else if  (this.country == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.country')
                } else if (this.port == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.port')
                } else if (this.user == '') {
                    errorMessage = this.$t('datasources_erros.user')
                } else if (this.database == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.database')
                } else if (this.schema == '' && this.mode != 'api') {
                    errorMessage = this.$t('datasources_erros.schema')
                }
            }

            if( errorMessage){
                Swal.fire({
                    title: 'Error!',
                    text: errorMessage,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    //onBeforeOpen: () => Swal.hideLoading()
                });
                return;
            }

            let formJSON = {
                "name": this.nameValue,
                "description": this.description,
                "provenance": this.provenance,
                "includesPII": this.includesPII,
                "piiDataFormat": this.piiDataFormat,
                "connection_type": this.connection_type,
                "rightHolder": this.rightHolder,
                "type": this.type,
            };

            switch (this.mode) {
                case 'database':
                    formJSON.config = {
                        "credentials": {
                            "type": this.type,
                            "host": this.host,
                            "port": this.port,
                            "user": this.user,
                            "password": this.password,
                            "database": this.database,
                            "schema": this.schema
                        },
                        "hosting": {
                            "provider": this.cloudProvider,
                            "country": this.country
                        }
                    };
                    break;

                case 'api':
                    formJSON.config = {
                        "credentials": {
                            "type": this.type,
                            "host": this.host,
                            "apiKey": this.user,
                            "password": this.password
                        },
                    };
                    break;

                default:
                    formJSON.config = {
                        "parsing": {
                            "headers": this.rowNames,
                            "delimiter": this.separator
                        }
                    };
                    formJSON.file = {
                        "name": this.fileName,
                        "content": this.content
                    };
                    formJSON.fileName = this.fileName;
                    formJSON.url = this.fileUrl;
                    break;
            }

            this.onFormValidated(formJSON, this.publicId);
            return formJSON;
        },

        editItem(item,datasets) {
            this.nameValue = item.name;
            this.description = item.description;
            this.provenance = item.provenance;
            this.type = item.type;
            this.createdAt = item.createdAt.split("T")[0];
            this.updatedAt = item.updatedAt.split("T")[0];
            if(this.type != 'csv') {
                this.host = item.config.credentials.host;
                this.port = item.config.credentials.port;
                this.user = item.config.credentials.user;
                this.password = item.config.credentials.password;
                this.database = item.config.credentials.database;
                this.schema = item.config.credentials.schema;
            }
            else {
                this.separator = item.config.parsing.delimiter,
                this.rowNames = item.config.parsing.headers,
                this.file = `${item.config.aws.s3.Bucket}/${item.config.aws.s3.Key}`
            }
            this.rightHolder = datasets[0].rightHolder;
            this.includesPII = datasets[0].includesPII;
            this.piiDataFormat = datasets[0].piiDataFormat;
            this.datasets = datasets;
        },
    },
    props: {
        isEdit: {
            type: Boolean,
            required: true
        },
        onClickQuit: {
            type: Function,
            required: true
        },
        onFormValidated: {
            type: Function,
            required: true
        }
    },
    mounted(){
        console.log( "route param type: ", this.mode );
        this.updateExampleCsvTemplate();
    }
}
</script>


<style scoped lang="scss">
.modal-container {
    display: block;
    padding: 10px
}
.invisible {
    display: none;
}

.dropbox {
    outline: 2px dashed grey;
    /* the dash box */
    outline-offset: -10px;
    background: #d4dff7;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
    width: 100%;
}

.dropbox:hover {
    background: #e6edfb;
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

select{
    -webkit-appearance: menulist-button !important;
}

.csv_example {
    background: #f4f4f4;
    border: solid 1px #bbd;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 1.0em;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 15px;
    display: block;
    word-wrap: break-word;
}

.panelsContainer{
    position: relative;
    padding: 0px;
    width: 100%;
}

.datasetsPanel{
}

.columnNames{
    font-weight: bold;
}



.panelCounter{
    padding: 10px 23px 0px 0px;
    text-align: right;
}

.panelFooter{

}

.panelRow{
    border-bottom: solid 1px #ccc;
    padding: 10px 0px 10px 0px;
}

.createNew{

}

.seeMore{
    padding: 0px 23px 0px 0px;
    text-align: right;
}

.sqsMessage{
    padding: 20px;
}
</style>
