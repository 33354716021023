<template>
  <v-app>
  <div class="content" id="users">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('auditUsers.title')}}</div>
              <p class="card-category">{{$t('auditUsers.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput ref="searchInput" addonRightIcon="fa fa-search" placeholder="Search" :onClick="searchInputValue" :onKeyUp="searchInputValue"></BaseInput>
              </form>
            </div>

            <div style="clear: both;"/>
            <div v-if="searchWord && !searchResults.length" class="loadingMessage">{{$t('general.no_results')}}</div>
            <div v-if="!table || !table.data" class="loadingMessage">{{$t('general.loading')}}</div>
            <data-table v-if="table.data && (!searchWord || (searchWord && searchResults.length))" :items="table.data" :headers="table.columns" page="reportUsers">
            </data-table>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import DataTable from 'src/components/DataTable.vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import AuditService from 'src/services/audit'
  import Swal from 'sweetalert2'

  export default {
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable
    },
    data () {
      let columns = [
        { text: this.$t('auditUsers.table.name'), value: 'name', align: 'left' },
        { text: this.$t('auditUsers.table.role'), value: 'role' },
        { text: this.$t('auditUsers.table.dsp_created'), value: 'assetsCreated' },
        { text: this.$t('auditUsers.table.dsp_shared'), value: 'assetsTransferred' },
        { text: this.$t('auditUsers.table.projects_created'), value: 'numProjects' },
      ];
      return {
        table: {
          columns: columns,
          data: null
        },
        users: {},
        searchWord: '',
        searchResults: []
      }
    },
    methods: {
      searchInputValue(e) {
        let results = [];
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.users.map((row, index) => {
          let matchName = row.name === undefined ? false : row.name.toUpperCase().includes( this.searchWord );
          if (matchName) {
            results.push(this.users[index]);
          }
        })
        this.searchResults = results;
        this.table.data = this.searchResults;
      },

      async loadUsers() {
        try {
          let result = await AuditService.listUsers();
          this.users = result;
          this.table.data = result;

          this.users.map(row => {
            row.role = row.role ? row.role.name : null;
            row.assetsCreated = row.numViews + row.numDatasets;
            row.assetsTransferred = row.numTransferredViews + row.numTransferredDatasets;
          });
        } catch (error) {
          console.log("API ERROR", error);
          Swal.fire({
              title: 'Error!',
              text: 'Users not found',
              type: 'error',
              confirmButtonText: 'Ok'
          });
        }
      },

      onSelectedOrganizationChanged: function(e){
        this.loadUsers();
      }
    },
    async mounted () {
      await this.loadUsers()
    },
    created() {
      window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    },
    beforeDestroy() {
      window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
    }
  }

</script>

<style>
.searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }
</style>
