<template>
  <div class="mainContainer">
    <div class="title">
      <div class="titleValue">{{ count }}</div>
      <div class="titleName"> {{title}}</div>
    </div>
  </div>
</template>


<script>

export default {
  name: "AnalyticsPad",
  data() {
    return {
      count: "..."
    };
  },
  props: {
    title: {
      type: String,
      description: "Title to be displayed"
    }
  },
  methods: {
    updateCount(count){
      this.count = count;
    }
  },
  mounted() {
  }
};
</script>


<style scoped>
.mainContainer {
  border-bottom: solid 1px #ccc;
  border-radius: 5px;
  padding: 30px 0px 30px;
  width: 320px;
  display: inline-block;
  margin: 10px;
}

.title {
  color: #666;
  display: flex;
  flex-wrap: wrap;
  border: none;
  align-items: flex-end;
  margin-bottom: 25px;    
}

.titleValue{
  flex-grow: 1;
  width: 40%;
  text-align: right;
  padding: 0px 15px 0px 0px;
  font-weight: 300;
  color: #8cc640;
  font-size: 35px;
  line-height: 26px;
}
.titleName{
  flex-grow: 1;
  width: 60%;
  text-align: left;
  font-weight: 300;
}

/*          Changes for portrait mobile      */

@media (max-width: 440px){
  .mainContainer{
    margin: 0px;
    padding: 0px;
    width: 100%;
    border: none;
  }    
}

</style>
