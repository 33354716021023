<template>
  <button @click="onClick" class="baseButton" :id="buttonId">
    <i :class="addonLeftIcon"></i>
    {{buttonLabel}}
  </button>
</template>


<script>
export default {
  name: "BaseButton",
  props: {
    buttonLabel: {
      type: String,
      description: "Button lable"
    },
    buttonId: {
      type: String,
      description: "Button name"
    },
    onClick: {
      type: Function,
      required: true
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    }
  }
};
</script>


<style scoped>
.baseButton {
  display: inline-block;
  min-width: fit-content; /* Chrome and Safari */
  min-width: -moz-max-content; /* Firefox */
  width: 10px;
  margin: 0.5em;
  padding: 1em 2em;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 3px;
  color: gray;
  font-size: 1em;
  line-height: 1;
  transition: 0.3s;
}

.baseButton:hover {
  background: #e8e5e5;
  color: #555;
}
</style>