<template>
    <center>
        <div class="o-page c-login-page">
            <div>
                <div>
                  <h2 class="title text-danger">{{ $t('notFoundOrganization.title') }}</h2>
                  <h2 class="title">{{ $t('notFoundOrganization.organization') }} 
                      <span class="text-danger">{{ getSubdomain() }}</span>
                       {{ $t('notFoundOrganization.message_text') }}<br/>
                       {{ $t('notFoundOrganization.message_text_extended') }}</h2> 
                  
                </div>
                <div>
                  <a href="mailto:support@lichen.com" target="_blank">
                      <button class="c-login-page-submit uppercase">
                    {{ $t('notFoundOrganization.button_label') }} 
                  </button>
                  </a>
                </div>
                <br>
                <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
            </div>
        </div>
    </center>
</template>



<script>
  export default {
    methods:{
      getSubdomain(){
          let host = window.location.host;
          let subdomain = host.split(".")[0];
          return subdomain;
      }
    }
  }
</script>
