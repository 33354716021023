

<template>
  <center style="padding-top: 80px;">
    <div class="topBar">
      <img :src="organizationLogo" style="height: 40px;"/>
    </div>
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div></div>
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <div class="logo_container"><img :src="organizationLogo" /></div>
          <div class="login_form" @keydown.enter="submitForm">
            <div>
              <div>
                <h2 class="title text-danger">{{ $t('notFoundPage.title') }}</h2>
                <h2 class="title">{{ $t('notFoundPage.description') }}</h2>
                
              </div>
              <div>
                <button v-on:click="gotoHome" class="baseButton">
                  {{$t('notFoundPage.button_label')}}
                </button>
              </div>
              <br>
              <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
          </div>
          </div>
        </div>
      </div>
  </center>
</template>



<script>
import LoginService from 'src/services/login'
import defaultLogo from 'src/assets/imgs/default/drumwave_logo.png'

  export default {
    data(){
      return {
        organizationLogo: '',
        organizationPublicId: ''
      }
    },
    methods:{
      gotoHome(){
        window.location.href="/";
      }
    },
    async beforeMount() {
      const subdomain = window.location.host.split('.')[0];

      try {
        const org = await LoginService.getOrganization(subdomain);
        this.organizationLogo = org.loginLogoUrl || defaultLogo;
        this.organizationPublicId = org.publicId;

      } catch (err) {
        console.error(err);
      }
    }
  }

</script>


<style scoped>

.topBar{
  position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background-color: #252223;
    z-index: 100;
    padding: 5px;
    text-align: left;
}

.logo_container img{
      max-width: 400px;
    max-height: 200px;
}

.logo_container{
     background-color: #ddd;
    width: min-content;
    margin: 10px 40px 40px 40px;
    border-radius: 20px;
    padding: 10px;
    display: none;
}

.login_form{
  width: 400px;
  font-family: 'titillium_webregular';
}

.login_form input {
  margin-bottom: 20px;
  font: Arial, Helvetica, sans-serif;
  border: 1px solid #ccc;
}


.login_form input:hover{
  border-color: #333;
}

.login_form input:focus{
  border-color: #333;
}

.login_form input::placeholder {
  color: #999;
}

.baseButton{
  display: inline-block;
  min-width: fit-content;
  min-width: -moz-max-content;
  width: 10px;
  margin: 0.5em;
  padding: 1em 2em;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: gray;
  font-size: 1em;
  line-height: 1;
  transition: 0.3s;
}
.baseButton:hover{
  background: #e8e5e5;
  color: #555;
}

.error {
  padding: 5px;
  background-color: #f44336;
  color: white;
  margin-bottom: 20px !important;
}
</style>
