import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let userLocale = window.navigator.language
if (userLocale === 'pt-BR') {
  userLocale = 'pt';
}

export const i18n = new VueI18n({
  locale: userLocale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages: {
    en: require('./en'),
    pt: require('./pt')
  }
})
