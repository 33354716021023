const config = require('config');
const request = require('request');
const { getAuthZToken, setAuthZToken } = require('../mixins/utils').methods;

const baseUrl = config.apiUrl;
const rejectCodes = [400, 404, 403, 401, 500, 422];

module.exports = (endpoint, method, body, form, headers, fullResponse) => {
  let options = {
    uri: baseUrl + endpoint,
    method: method,
    headers: headers || { 'Authorization': `Bearer ${getAuthZToken()}` },
    json: true
  };

  if (body) options.body = body;
  if (form) options.form = form;

  return new Promise((resolve, reject) => {
    request(options, (err, response, body) => {

      if (response.headers.token) {
        setAuthZToken(response.headers.token);
      }

      if (rejectCodes.includes(response.statusCode)) {
        reject([response.statusCode, body]);

      } else {
        if (fullResponse) {
          resolve(response);

        } else {
          resolve(body);
        }
      }
    });
  });
}
