<template>
  <div style="position: relative;width: min-content;" @mouseleave="hideOptionsPanel"  @mousewheel="hideOptionsPanel">
    <div style="width: max-content;">

      <div v-if="page !== 'exchange'" class="iconContainer iconContainerRound col-3">
        <i class="fa fa-times" style="cursor: pointer;" title="Remove" @click="deleteRow(item)"></i>
      </div>
      <div v-else class="iconContainer col-3"></div>

      <!-- Transfers. Applies to all cases -->
      <div v-if="canTransfer" class="iconContainer iconContainerRound col-3">
        <i class="fa fa-share-alt" style="cursor: pointer;" :title="this.$t('miscellania.data_apps.transfer')"  @click="openShareDialog(item)"></i>
      </div>
      <div v-else class="iconContainer col-3"></div>

      <div v-if="item.type=='dataproduct' && page !== 'exchange' && isCurrentUser" class="iconContainer col-3">
        <img class="dataAppIcon" style="cursor: pointer;" src="../assets/imgs/dataApps/embed.png" title="Embed code" @click="openEmbedDialog(item)" />
      </div>
      <div v-else class="iconContainer col-3">
        <div class="dataAppIcon"></div>
      </div>

    </div>
  </div>
</template>

<script>
  import Utils from '../mixins/utils';
  import Swal from 'sweetalert2';

  export default {
    props: {
      item: {
        type: Object
      },
      transferCallback: Function,
      deleteItemCallback: Function,
      embedCallback: Function,
      canTransfer: Boolean,
      isCurrentUser: Boolean,
      page: String
    },
    mixins: [Utils],
    data() {
      return {
        base_view_url: '/#/views/',
        showOptions: false,
        overedSda: null
      };
    },
    methods:{
      openShareDialog( item ){
        this.transferCallback( item  );
      },
      async deleteRow(item) {
        await this.deleteItemCallback(item);
      },
      openEmbedDialog(item) {
        this.embedCallback(item);
      },
      hideOptionsPanel(){
        this.showOptions = false;
      }
    }
  }
</script>

<style>
  .dataAppIcon{
    margin: 0px 2px;
    width: 20px;
    height: 20px;
  }

  .shareBtn{
    cursor: pointer;
    float: right;
    padding: 5px 0px;
    opacity: 0.3;
  }
  .shareBtn:hover{
    opacity: 1;
  }

  .disabledDataApp{
    opacity: 0.2;
  }

  .iconContainer{
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 4px;
  }

  .iconContainerRound{
    margin-right: -1px;
  }

  .arrow_box {
    background: #ffffff;
    border: 1px solid #949494;
    border-radius: 5px;
    padding: 0px;
    position: fixed;
    z-index: 1000;
    width: 250px;
    box-shadow: 2px 4px 8px -4px #888;
  }
  .arrow_box:after, .arrow_box:before {
    top: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 5px;
    margin-left: -5px;
  }
  .arrow_box:before {
    border-color: rgba(148, 148, 148, 0);
    border-top-color: #949494;
    border-width: 6px;
    margin-left: -6px;
  }

  .tooltipTitle{
    font-size: 16px;;
    font-weight: bold;
    margin-bottom: 2px;

  }
  .tooltipThumbContainer{
    width: 100%;
    height: 170px;
    overflow: hidden;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    border-radius: 4px;
  }

  .tooltipButtons{
    text-align: right;
  }
  .tooltipButtons img{
    width: 16px;
    height: 16px;
    opacity: 0.8;
    cursor: pointer;
  }

  .tooltipButtons img:hover{
    opacity: 1;
  };
</style>
