

module.exports = {
  general: {

    error: {
      generic: 'Houve um problema. Por favor tente novamente mais tarde.'
    },
    loading: 'Carregando, por favor espere...',
    no_results: 'Nenhum resultado correspondente à sua pesquisa'


  },
  authN: {

    password: 'Senha',
    passwordConfirm: 'Confirmar a senha',
    button: {

      confirm: 'Confirmar',
    },
    login: {
      forgot: 'Esqueceu sua senha?',
      error: {
        emailEmpty: 'E-mail não pode ser vazio.',
        passwordEmpty: 'Senha não pode ser vazia.',
        wrongCredentials: 'O e-mail e a senha fornecidos não correspondem às informações em nossos registros. Verifique-as e tente novamente.'
      }
    },
    forgotPassword: {
      title: 'Recuperação de senha',
      subTitle: 'Por favor, digite seu e-mail',
      success: {
        title: 'Email de recuperação enviado!',
        p1: 'Uma mensagem foi enviada para seu e-mail com um link, válido por 24 horas, que permite a troca de sua senha.',
        p2: 'Por favor, siga as intruções na mensagem enviada para recuperar o acesso à sua conta.'
      },
      error: {
        emailEmpty: 'E-mail não pode ser vazio.',
        emailNotFound: 'Não encontramos uma conta associada com este email.'
      }
    },
    newPassword: {
      title: 'Escolha uma senha',
      firstTimeTitle: 'Escolha uma senha',
      termsOfUse1: 'Eu aceito os',
      termsOfUse2: 'Termos de uso',
      success: {
        title: 'Nova senha cadastrada!',
        p1: 'Sua senha foi cadastrada com sucesso.',
        p2: 'Redirecionando para a página de login...'
      },
      error: {
        empty: 'As senhas não podem ser vazias.',
        dontMatch: 'A senhas não podem ser diferentes.',
        termsOfUse: 'Por favor aceite os termos de uso.',
        length: 'A senha deve ter no mínimo 6 caracteres.'
      }
    }
  },
  hamburgerMenu: {
    home: 'Pagina Inicial',
    dashboard: 'Visão Geral',



    users: 'Usuários',
    organizations: 'Organizações',



    open: 'Abrir'
  },

  overview: {
    title: 'Visão Geral',
  },
  home: {
    title: 'Pagina Inicial',
    cards: {
      organizations: 'Organizações',
      users: 'Usuários',

      projects: 'Projetos de Studio',


    }
  },




  projects: {


    selector: {
      option1: 'Pastas',
      option2: 'Usuários'
    },
    table: {
      name: 'Nome',
      creator: 'Criador',
      createdAt: 'Data de Criação',
      updatedAt: 'Data de Modificação'
    }
  },
  walletCreate: {
    title: 'Criar Carteira',
    title_edit: 'Detalhes da Carteira',

    details: {
      title: 'Detalhes',
      name: 'Nome',
      description: 'Descrição',
      status: 'Tipo'
    },
    status: [{
      text: 'Organização',
      value: 'private'
    }],
    subscription: {
      title: 'Planos de Assinatura'
    },
    periodUnit: [{
      text: 'Mensal',
      value: 'months'
    }],
    datasets: {
      title: 'Datasets',
      headers: [{
          text: '',
          value: '',
          sortable: false
        },
        {
          text: 'Nome',
          value: 'name'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Origem',
          value: 'source'
        }
      ]
    },
    create: 'Criar',
    update: 'Atualizar'
  },
  walletEdit: {
    plan: 'Plano',
    currency: 'Moeda',
    frequency: 'Período',
    price: 'Preço',
    add_new: 'Adicionar Novo',
    subscription: 'Subscrição',
    monthly: 'Mensalmente',
    subscribe_now: 'ASSINAR AGORA',
    unsubscribe: 'CANCELAR ASSINATURA',
    already_subscribed: 'Already subscribed but it\'ll expire on',
    datasets: 'Datasets',
    name: 'Nome',
    source: 'Fonte',
    data_products: 'Data Apps',
    status: [{
        text: 'Organização',
        value: 'private'
      },
      {
        text: 'Marketplace',
        value: 'public'
      }
    ]
  },
  auditData: {


    create: 'Adicionar dados',
    delete: 'Delete Dataset',
    deleted: 'Dataset deleted!',
    datasets: 'Datasets',
    name: 'Name',
    fields: 'Fields',
    open_with: 'Open with...',
    no_data_products: 'No data apps available yet for this data source',
    myself: "Myself",
    table: {
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      dimScore: 'DIM',
      size: '# Células',
      origin: 'Origin',



      actions: 'Actions',
      openWith: 'Abrir com'

    }
  },
  auditUsers: {
    title: 'Wallets Report',
    subTitle: '',
    table: {
      name: 'Nome',
      role: 'Role',
      dsp_created: '# Data Assets criados',
      dsp_shared: '# Data Assets licenciados',
      projects_created: '# Projetos criados'
    }
  },
  dwallet: {
    createCSV: 'Upload Excel/CSV',
    createDB: 'Conectar a um banco de dados',
    connectAPI: 'Conectar a uma API',
    table: {
      name: 'Nome',
      updatedAt: 'Última modificação',
      dimScore: 'DIM',
      origin: 'Origem',
      size: '# Células',
      actions: 'Ações',
      openWith: 'Abrir com',
      open: 'Abrir'
    },
    public_access: {
      title: 'Acesso Público ao {0}'
    },
    delete: {
      dataset: {
        error: {
          text: '<p>Você compartilhou <strong>{0}</strong> com outros usuários. Por favor, descompartilhe antes de remover.</p>'
        },
        warning: {
          text: '<p>Você deve primeiro remover o data source <strong>{0}</strong> para remover o dataset <b>{1}</b>.</p>'
        }
      },
      datasource: {
        error: {
          text: '<p>O data source <strong>{0}</strong> têm <strong>{1}</strong> datasets que foram compartilhados com outros usuários. Por favor, descompartilhe antes de remover.</p>'
        }
      },
      dataApp: {
        error: {
          text: '<p>Você compartilhou <strong>{0}</strong> com outros usuários. Por favor, descompartilhe antes de remover.</p>'
        }
      },
      confirm: {
        title: 'Você têm certeza?',
        confirmButton: 'Ok',
        cancelButton: 'Cancelar'
      },
      success: '<strong>{0)</strong> foi removido'
    }
  },
  share: {
    processing: 'Processando',
    erro: 'Algo deu errado!'
  },
  unshare: {
    confirm_dataset: {
      title: 'Você têm certeza?',
      text: '{0} foi compartilhado com você pelo(a) {1}. Você não terá mais acesso.',
      confirmButton: 'Ok',
      cancelButton: 'Cancelar'
    },
    confirm_data_app: {
      title: 'Você têm certeza?',
      text: '{0} foi compartilhado com você pelo(a) {1}. Você não terá mais acesso.',
      confirmButton: 'Ok',
      cancelButton: 'Cancelar'
    },
    success: '<strong>{0)</strong> foi descompartilhado'
  },
























  datasources: {
    title: 'Fonte de Dados',

    create: 'Conectar Fonte de Dados',
    delete: 'Apagar Fonte de Dados',
    deleted: 'Fonte de Dados apagada!',
    embed_error: 'Error: Embedded data could not be displayed.',
    choose_ds_type: 'Escolha uma base de dados',
    datasets: 'Datasets',
    name: 'Nome',
    fields: 'Campos',
    open_with: 'Abrir com...',
    no_data_products: 'Nenhum data product disponível ainda para este data source',
    table: {
      name: 'Nome',
      creator: 'Criador',
      createdAt: 'Data de Criação',
      countDatasets: 'Datasets',
      type: 'Tipo',
      size: '# Células',
    }
  },
  datasources_erros: {
    name: 'Por favor, preencha o campo nome',
    provenance: 'Por favor, forneça a provenance da fonte de dados',
    rightHolder: 'Por favor, forneça o detentor legal da fonte de dados',
    includesPII: 'Por favor, forneça informações de PII',
    piiDataFormat: 'Por faovr, forneça o formato de PII',
    type: 'Por favor, selecione um tipo de fonte',
    upload_file: 'Por favor, forneça a URL ou o arquivo',
    host: 'Por favor, forneça o hostname',
    cloud_provider: 'Por favor, especifique o provedor de cloud',
    country: 'Por favor, especifique o país onde a fonte está hospedada',
    port: 'Por favor, forneça a porta do banco de dados',
    user: 'Por favor, forneça o usuário do banco de dados',
    database: 'Por favor, forneça o banco de dados',
    schema: 'Por favor, forneça o schema do banco de dados',
    invalid_url: 'O URL fornecido não é válido, por favor forneça um válido ',
    unsupported_file: 'Este formato de arquivo não é suportado. Forneça um arquivo Excel ou CSV'
  },










  datawallets: {
    title: 'Data Wallets',
    subTitle: '',
    create: 'Criar',
    delete: 'Deletar Data Wallet',
    deleted: 'Data Wallet deletado!',
    createdAt: 'Criado em',
    updatedAt: 'Atualizado em',
    description: 'Descrição',
    publisher: 'Editor',
    table: {
      name: 'Nome',
      creator: 'Criador',
      publisher: 'Editor',
      status: 'Tipo',
      countDatasets: 'Datasets'
    }
  },
  datasets: {
    title: 'Relatório DIM',
    create: 'Adicionar dados',
    delete: 'Deletar Dataset',
    deleted: 'Dataset deletado!',
    organization: 'Organização',
    creation_date: 'Data de Criação',
    user: 'Origem',
    type: 'Tipo',
    connectionType: 'Tipo de Conexão',
    updated_date: 'Data da Atualização',
    fields: 'Campos',
    rightsHolder: 'Detentor Legal',
    provenance: 'Provenance',
    version: 'Versão',
    numberOfRows: '# Linhas',
    dimscore_info: `DIM score calcula o valor de um dataset a partir de três perspectivas:<br/>
        <ul>
          <li><b>Dado</b>: tamanho e frequência de atualização</li>
          <li><b>Informação</b>: estrutura interna, diversidade e riqueza das relações</li>
          <li><b>Significado</b>: relevância contextual, incluindo tempo, localização, sociedade e interrelação com a realidade que o dado está descrevendo</li>
        </ul>`,






    table: {
      position: 'Posição',
      name: 'Nome',
      creator: 'Criador',
      createdAt: 'Data de Criação',

      includesPII: 'Incluí PII?'

    }
  },
  dsAPI: {
    plataform: 'Plataforma',
    choose_plataform: 'Escolha uma plataforma de API',
    apiKey: 'Chave da API ou Usuário'
  },
  dsCreateEdit: {
    uploadTitle: 'Upload Excel/CSV',
    connectDbTitle: 'Conectar-se a um banco de dados',
    apiTitle: 'Conectar-se a uma API',
    editTitle: 'Detalhes da fonte de Dados',
    createdAt: 'Criado em',
    updatedAt: 'Atualizado em',
    upload: "Subir arquivo",
    connect: "Conectar",
    uploading: 'Enviando...',
    upload_success: 'Arquivo enviado!',
    connecting: 'Conectando......',
    connect_success: 'Banco de dados conectado!',
    connect_success_api: 'API conectada!',
    update: 'Atualizar',
    country: 'País',
    form: {
      general_info: 'Informações Gerais',
      tech_info: 'Informações Técnicas',
      name: 'Nome',
      description: 'Descrição',
      provenance: 'Provenance',
      provenance_placeholder: 'Aplicação onde esta fonte dados está vindo, por exemplo ERP, CRM e etc',
      type: 'Tipo',
      host: 'Hostname',
      port: 'Porta',
      user: 'Usuário',
      password: 'Senha',
      database: 'Banco de Dados',
      serviceName: 'Nome do Serviço',
      schema: 'Schema',
      separator: 'Separador dos campos',
      file: 'Ou escolha um arquivo',
      example_csv: 'Examplo do CSV',
      rightHolder: 'Detentor Legal',
      rightsHolderOptions: [{
          text: 'Escolha um Detentor',
          value: ''
        },
        {
          text: 'Própria Empresa',
          value: 'own_company'
        },
        {
          text: 'Outra Empresa',
          value: 'other_company'
        },
        {
          text: 'Consumidor',
          value: 'consumer'
        },
        {
          text: 'Domínio Público',
          value: 'public_domain'
        }
      ],
      includesPII: 'Os Dados contém PII?',
      includesPIIOptions: [{
          text: 'Escolha uma Opção',
          value: ''
        },
        {
          text: 'Sim',
          value: 'true'
        },
        {
          text: 'Não',
          value: 'false'
        },
        {
          text: 'Não sei',
          value: 'unknown'
        }
      ],
      piiDataFormat: 'Formato do Dado PII',
      piiDataFormatOptions: [{
          text: 'Escolha o Formato do Dado PIOI',
          value: ''
        },
        {
          text: 'Nominativo',
          value: 'nominative'
        },
        {
          text: 'Anonimizado',
          value: 'anonimized'
        },
        {
          text: 'Codificado',
          value: 'coded'
        }
      ],
      headers: {
        title: 'Nome das colunas na primeira linha?',
        auto: 'Automático',
        yes: 'Sim',
        no: 'Não'
      },
      delimiters: {
        comma: 'Vírgula',
        semicolon: 'Ponto e vírgula',
        tabular: 'Tab',
        pipe: 'Barra Vertical'
      },
      csv: {
        title: 'Arraste seu arquivo aqui ou clique aqui',
        subTitle: 'Recomendamos arquivos de até 20MB'
      },
      fileUrl: "Indicar local remoto (URL)",
      fileUrl_placeholder: "https://..."
    }
  },
  users: {
    title: 'Usuários',
    subTitle: '{used} de {total} licencas de "{license}"',
    create: 'Criar novo',
    delete: 'Apagar Usuário',
    table: {
      name: 'Nome',
      locale: 'Idioma',
      createdAt: 'Data de Criação',
      acceptedAt: 'Data de Início',
      lastActive: 'Ativo pela última vez',
      organization: 'Organização',
      role: 'Funçao'
    }
  },
  userCreate: {
    createTitle: 'Criar Usuário',
    editTitle: 'Editar Usuário',
    validName: 'O nome precisa ser válido.',
    noLicenses: 'Não há licenças para esta organização',
    form: {
      name: 'Nome',
      userName: 'Nome do usuário',
      email: 'E-mail',
      policy: 'Política de acesso',
      role: 'Funçao',
      locale: 'Idioma'
    }
  },
  organizations: {
    title: 'Organizações',
    create: 'Criar nova',
    delete: 'Apagar Organização',
    table: {
      name: 'Nome',
      subdomain: 'Subdomínio',
      createdAt: 'Data de Criação',
      trialEndsAt: 'Data de Fim de Teste',
      totalLicenses: 'Total de licenças',
      usedLicenses: 'Licenças usadas'
    }
  },
  organizationCreate: {
    createTitle: 'Criar Organização',
    editTitle: 'Editar Organização',
    form: {
      name: 'Nome',
      subdomain: 'Subdomínio',
      seats: 'Licenças',
      logoLogin: 'Logo do Login',
      logoHome: 'Logo da Home',
      trial: 'Dias de Teste',
      logoSize: 'Tamanho mínimo: 600 x 100 px',
      cropSize: 'Tamanho selecionado'
    }
  },
  dataexplorer: {
    title: 'Data Explorer',
    dwallet: 'dWallet',
    name: 'Nome',
    type: 'Tipo',
    datasets: 'Datasets',
    create_new: 'Criar Novo',
    see_all: 'Ver todos',
    business_account: 'Business Account',
    publisher: 'Editor',
    wallets: 'Wallets'
  },
  modal: {
    delete: {
      question: "Tem certeza que deseja apagar <span id='elementToBeDeleted'></span>?",
      confirm: 'Apagar',
      cancel: 'Cancelar'
    }
  },
  basebutton: {
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    clear: 'Limpar',
    choose_image: 'Escolha a Imagem',
    get_started: 'Iniciar'
  },
  signOut: 'Encerrar sessão',
  billing: {
    credit_card: 'Cartão de Crédito',
    first_name: 'Primeiro Nome',
    last_name: 'Sobrenome',
    address: 'Endereço',
    city: 'Cidade',
    country: 'País',
    postal_code: 'CEP',
    subscribe: 'Subscribe'
  },
  notFoundPage: {
    title: 'Oops! Página na encotrada',
    description: 'A URL solicitada não existe',
    button_label: 'Ir para página inicial'
  },
  notFoundOrganization: {
    title: 'Oops! Houve um problema',
    organization: 'Organização',
    message_text: 'não existe.',
    message_text_extended: 'Por favor, verifique a URL, pode ser erro de digitação',
    button_label: 'Precisa de suporte?'
  },
  transfers: {
    email_already_in_list: "O email já está na lista",
    invalid_email: "Digite um endereço de email válido",
    start_date_invalid: "A data de início é inválida",
    end_date_invalid: "A data de término é inválida",
    intent_invalid: "É necessário selecionar uma intenção",
    title: 'Licencie "{assetName}" para',
    email: "Email",
    start: "Data de início",
    end: "Data de fim",
    intent: "Intenção",
    cancel: "CANCELAR",
    confirm: "CONFIRMAR",
    choose_intent: "Escolha uma intenção",
    intents: [
      "Business Intelligence",
      "Product enhancement",
      "Legal requirement",
      "Banking reasons",
      "Marketing",
      "Research",
      "Others"
    ]
  },
  miscellania: {
    data_apps: {
      open_in_studio: "Abrir este dataset no Studio",
      open_in_ezdash: "Explorar este dataset com EzDash",
      launch_dataproduct: "Abrir este Data Product",
      transfer: "Transferir para outro usuário"
    }
  },
  termsOfUse: `
  TERMOS E CONDIÇÕES DE USO DA PLATAFORMA DA DRUMWAVE
Estes termos e condições de uso (adiante denominado “Termo”) são celebrados entre Drumwave, Contratante e Usuários da Plataforma, conforme qualificação informada quando do cadastro de sua conta na referida Plataforma da Drumwave.

  ACEITAÇÃO DOS TERMOS E FUTURAS ALTERAÇÕES:
ANTES DE UTILIZAR A PLATAFORMA, A CONTRATANTE E CADA UM DE SEUS USUÁRIOS DEVERÃO LER ATENTAMENTE ESTE TERMO. A CONTRATANTE E SEUS USUÁRIOS CONFIRMAM QUE, AO SE CADASTRAREM E UTILIZAREM QUAISQUER DAS FUNCIONALIDADES DA PLATAFORMA, TERÃO LIDO, ENTENDIDO E ACEITADO TODOS OS TERMOS E CONDIÇÕES LISTADOS ABAIXO.

A Plataforma poderá, a qualquer tempo e sem aviso prévio à Contratante e aos Usuários, alterar, modificar ou ajustar este Termo (“Termos Adicionais”), que integrarão este Termo e que, em caso de conflito, prevalecerão em relação à matéria conflitante. Eventuais modificações que restrinjam os direitos da Contratante e/ou dos Usuários, ou modifiquem o tratamento de Dados Pessoais ou de Dados sensíveis (conforme definidos abaixo), serão comunicadas devida e oportunamente. Isto não afasta a necessidade da Contratante e dos Usuários de reverem o Termo com frequência, pois se subordinam àquele vigente no momento de seu acesso e uso. Caso não concorde com as alterações, a Contratante e seus Usuários deverão descontinuar o uso da Plataforma e cancelar a dWallet, de acordo com as disposições previstas neste Termo.

APLICAÇÃO E VALIDADE: Este Termo é válido e aplicável ao acesso da Plataforma, dentro ou fora do Brasil, e regula as funcionalidades e características de uso da Plataforma da Drumwave.

1. DEFINIÇÕES

1.1. Para os fins deste Termo, os seguintes termos têm as definições abaixo:
  (i) “Banco de Dados” significa o banco de dados da Contratante contendo apenas Dados.
  (ii) “Controladora” é a pessoa natural ou jurídica a quem compete as decisões referentes
  ao Tratamento.
  (iii) “Dados” significam informações constantes no Banco de Dados da Contratante ou na base de Dados de terceiros, conforme o caso.
  (iv) “Dados Anonimizados” significam dados relativos a titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.
  (v) “Dados Pessoais” significam dados ou informações relacionadas a uma pessoa natural identificada ou identificável, nos termos da Lei Geral de Proteção de Dados Pessoais.
  (vi) “Data Apps” significa Dados tratados, combinados e/ou agregados, que podem ser criados pela Drumwave, pela Contratante ou por terceiros, de acordo com a licença da Plataforma adquirida e conforme contratado pela Contratante. Conforme contratado pela Contratante, os Data Apps poderão ser criados exclusivamente com base nos Dados constantes no Banco de Dados da Contratante e/ou Dados da Contratante combinados com informações publicamente disponíveis, decorrentes, em todos os casos, apenas de Dados Anonimizados.
  (vii) “Drumwave” significa a DRUMWAVE BRASIL TECNOLOGIA LTDA., sociedade com sede na Cidade de São Paulo, Estado de São Paulo, na Avenida Imperatriz Leopoldina no 957, conjunto 208, Vila Leopoldina, CEP 05305-011, inscrita no CNPJ/ME sob no 34.791.805/0001- 12.
  (viii) “dWallet” significa a conta aberta pela Contratante na Plataforma com o objetivo de possibilitar, conforme aplicável de acordo com as funcionalidades contratadas descritas no Plano de Serviços contratado, (a) o acesso remoto pela Drumwave ao Banco de Dados; (b) o mapeamento dos Dados da Contratante (data discovery) para a construção de um mapa de fontes, atributos, propriedade das relações entre dados, sistemas e usuários do sistema da Contratante; e (c) a visualização de informações referentes ao seu Banco de Dados, Dados e/ou Data Apps da Contratante.
  (ix) “Lei Geral de Proteção de Dados Pessoais” significa a Lei no 13.709, de 14 de agosto de 2018.
  (x) “Metadados” significam dados sobre os Dados, ou seja, informações geradas a partir da comunicação de Dados, que permitem a compreensão do contexto dos Dados, sua organização ou classificação de suas características.
  (xi) “Operadora” é a pessoa natural ou jurídica que realiza o Tratamento em nome da Controladora.
  (xii) “Parte” significa, conforme o caso, a Drumwave ou a Contratante, e “Partes” significam a Drumwave e a Contratante, em conjunto.
  (xiii) “Plano de Serviços” significa o plano escolhido pela Contratante para a prestação dos Serviços, contendo a Remuneração e o prazo para a prestação dos Serviços, bem como a quantidade de Usuários que poderão ter acesso a dWallet da Contratante.
  (xiv) “Plataforma” significa a plataforma online da Drumwave, por meio da qual a Contratante tem acesso aos diversos serviços prestados pela Drumwave.
  (xv) “Serviços” significam os serviços relacionados à Plataforma, nas funcionalidades contratadas, e os Serviços de Armazenamento e Processamento de Dados, conforme previstos no Plano de Serviços contratado pela Contratante.
  (xvi) “Serviços de Armazenamento e Processamento de Dados” significam os serviços específicos de armazenamento e processamento de Dados e/ou Data Apps, bem como dos resultados computacionais derivados que a Contratante obtiver por meio dos Serviços de Armazenamento e Processamento de Dados.
  (xvii) “Software” significa qualquer software da Drumwave relacionado à Plataforma.
  (xviii) “Termo” significa estes Termos e Condições de Uso da Plataforma.
  (xix) “Titular” é a pessoa natural a quem se referem os Dados Pessoais que são objeto de
  Tratamento;
  (xx) “Tratamento” significa toda e qualquer operação realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
  (xxi) “Usuário” significa a pessoa física indicada pela Contratante, conforme o caso, para ter acesso à Plataforma, em nome e sob a responsabilidade da Contratante, por meio de cadastro específico realizado na Plataforma.

1.2. Todas as referências as cláusulas e anexos utilizados neste Termo são referências as cláusulas e anexos contidos neste Termo, a não ser se de outra forma especificado.

1.3. Termos definidos no singular têm o significado correspondente no plural, e vice-versa. A não ser que o contexto deste Termo claramente indique o contrário, palavras no gênero masculino incluem os gêneros feminino e vice-versa. Os termos “inclui” e “incluindo” significam “incluindo, sem limitação”.

2. OBJETO

2.1. Observados os termos e condições previstos neste Termo, constitui objeto do presente Termo, (i) a abertura e utilização da dWallet da Contratante; e (ii) a prestação dos Serviços pela Drumwave.

2.2. A Contratante declara neste ato, que possui plenos poderes, capacidade civil e autoridade legal para contratar o Plano de Serviços, seja em nome próprio, seja em nome de terceiros. Caso a Contratante esteja celebrando em nome de terceiro, declara também que possui plena autoridade legal para vincular este terceiro aos termos e condições deste Termo.

2.3. Caso a Contratante tenha interesse em acessar outras funcionalidades da Plataforma ou contratar outros serviços da Drumwave, incluindo, sem limitação, serviços profissionais de treinamento e certificação, a Contratante deverá entrar em contato por meio do e-mail: contato@drumwave.com.

3. CADASTRO NA PLATAFORMA

3.1. A Contratante, ao contratar o Plano de Serviços, deverá se cadastrar na Plataforma, por meio da inserção de e-mail e criação de senha pessoal e intrasferível, e criação de um Usuário. Caso a Contratante seja pessoa jurídica e queira incluir novo(s) Usuário(s), o(s) novo(s) Usuário(s) deverá se cadastrar na Plataforma, e seu cadastro(s) poderá(ão) ser vinculado(s) a dWallet da Contratante.

3.1.1. Para que os Usuários tenham acesso a dWallet, cada Usuário deverá fazer um único cadastro na Plataforma, que será realizado por meio da inserção de seu e-mail corporativo e escolha de uma senha pessoal e intransferível.
3.1.2. A Contratante poderá categorizar seus usuários em diferentes tipos, conforme tipos de usuários disponíveis na Plataforma.

3.2. O login e senha da Contratante e de cada Usuário são confidenciais e sigilosos, devendo a Contratante e o Usuário adotarem as cautelas e diligências necessárias para impedir a sua utilização indevida por terceiros.

3.2.1. O cadastro da Contratante e dos Usuários deverá conter apenas informações verídicas, sob pena da Contratante ser responsabilizada civil e criminalmente por falsidade ideológica. A Drumwave não se responsabiliza pela idoneidade ou capacidade civil da Contratante e dos Usuários, cabendo a cada um deles agir com cautela e discernimento, respondendo por toda atividade realizada por meio da Plataforma.

3.2.2. As informações de cadastro da Contratante e dos Usuários ficarão armazenadas na Plataforma, de acordo com as disposições constantes neste Termo e na Política de Privacidade da Drumwave, e poderão ser corrigidas pela Contratante e pelo Usuário, caso necessário.

3.2.3. Em caso de perda ou furto de sua senha de acesso, a Contratante e/o Usuário, conforme aplicável, deverá imediatamente solicitar a alteração de sua senha na Plataforma. Caso tal solicitação não seja possível por qualquer motivo, o Usuário e/ou a Contratante deverá comunicar imediatamente a Drumwave, sob pena de ambos permanecerem responsáveis por todas as ações na Plataforma feitas por meio do cadastro de tal Usuário.

3.2.4. A Drumwave poderá negar ou cancelar o cadastro de qualquer Usuário, independentemente de qualquer aviso ou notificação prévia, caso (i) alguma informação apresentada seja irregular, insuficiente ou falsa, (ii) seja identificado qualquer indício de fraude ou irregularidade no cadastro ou na utilização da Plataforma, ou (iii) o cadastro originário do Usuário tenha sido cancelado por infração às condições e termos deste Termo.

3.3. A Contratante poderá, a qualquer momento, solicitar o cancelamento do cadastro de qualquer de seus Usuários, bem como a substituição de qualquer cadastro existente pelo cadastro de um novo Usuário.

4. UTILIZAÇÃO DA PLATAFORMA

4.1. A Drumwave concede à Contratante o direito de uso limitado não exclusivo e intransferível para acessar e usar a Plataforma apenas para a(s) finalidade(s) prevista(s) neste Termo e exclusivamente para a funcionalidade contratada no Plano de Serviços, responsabilizando-se a Contratante integralmente por todas e quaisquer violações, por seus Usuários, dos termos e condições estabelecidos neste Termo e nas leis aplicáveis.

4.2. A Contratante reconhece que algumas ou todas as funcionalidades da Plataforma disponibilizadas pela Drumwave podem estar em desenvolvimento e, com isso, apresentar defeitos ou deficiências que não podem ou não serão corrigidos pela Drumwave, seus licenciantes ou outros fornecedores.

4.3. A Contratante obriga-se a observar, providenciar e assegurar, constantemente, a manutenção das condições mínimas exigidas pela Drumwave no tocante ao sistema, infraestrutura, ambiente operacional e equipamentos necessários da Contratante, bem como seguir as orientações expressas da Drumwave para o uso da Plataforma.

4.4. A Contratante reconhece e concorda que o uso da Plataforma pode requerer que determinado software de terceiros (incluindo sistema operacional, browser e/ou serviços de terceiros) seja instalado, acessado e/ou operado de maneira correta. Os serviços e conteúdos disponíveis em sites de terceiros são de única e exclusiva responsabilidade do terceiro. A Contratante reconhece e concorda que a Drumwave não garante, não endossa, não assume e não terá qualquer responsabilidade com relação a produtos, serviços ou sites de terceiros.

4.5. A Contratante concorda que a Drumwave e/ou seus licenciantes podem coletar informações relacionadas ao uso da Plataforma pela Contratante, incluindo, entre outras, frequência e natureza do uso, para que sejam utilizadas pela Drumwave em relação à avaliação e melhoria das funcionalidades da Plataforma.

5. PROPRIEDADE INTELECTUAL DA PLATAFORMA

5.1. A Contratante reconhece que a Plataforma é protegida por leis e tratados internacionais relativos à propriedade intelectual. A organização, a estrutura, a sequência, a lógica, os fluxogramas, o código objeto e o código fonte da Plataforma constituem e/ou incorporam segredo de empresa, direitos autorais e/ou outros direitos de propriedade intelectual exclusivos e não publicados da Drumwave, que se reserva e detém todos os direitos, títulos e interesses a eles relacionados.

5.2. O presente Termo não transfere, concede ou cede qualquer direito de propriedade intelectual entre as Partes. Cada uma das Partes manterá todos os direitos sobre quaisquer ideias, orientações, planos, modelos, base de dados decorrentes dos Serviços, conceitos, know-how, propriedade intelectual, metodologias, processos, tecnologias, algoritmos, modelos estatísticos, software ou ferramentas de desenvolvimento e quaisquer outros materiais técnicos de propriedade ou informações que antes da data de início da vigência deste Termo sejam de sua propriedade, estejam na sua posse, ou que sejam adquiridas ou desenvolvidas exclusivamente pela Parte ainda que sejam relacionados ao cumprimento deste Termo após a data de início da vigência deste Termo. Portanto, a Contratante reconhece e aceita, neste ato, que a Drumwave permanecerá a titular exclusiva da Plataforma e de todos os programas de computador que a compõem.

5.3. Todos os Serviços, os algoritmos, modelos estatísticos, Application Programming Interface (“API(s)”), códigos fonte de sistemas e software ou bens passíveis de direito de propriedade intelectual que for(em) desenvolvido(s) pela Drumwave durante este Termo serão de propriedade exclusiva da Drumwave, salvo se expressamente determinado de outra forma, por escrito, pelas Partes.

5.4. A Drumwave declara que todos e quaisquer recursos e subsídios que serão utilizados para a prestação dos Serviços não violarão quaisquer direitos de propriedade intelectual e/ou de personalidade de terceiros, incluindo, mas sem limitação, direitos autorais, marcas, patentes, segredos de indústria e comércio e direitos de imagem de terceiros e que obterá previamente todas as licenças e autorizações necessárias.

5.5. A Contratante concorda em notificar imediatamente a Drumwave por escrito sobre qualquer ação movida por violação de direitos autorais, patentes, segredos comerciais ou outro tipo de violação de direitos de propriedade intelectual, movida contra a Contratante em relação aos recursos e subsídios da Drumwave que serão utilizados na prestação dos Serviços.

6. SERVIÇOS

6.1. Serviços da Plataforma. A Drumwave prestará os serviços relacionados às funcionalidades da Plataforma, conforme previsto no Plano de Serviços. As funcionalidades e características da Plataforma estão descritas no Plano de Serviços, sendo que:
  (a) Visualização de Dados e/ou Data Apps: Sempre que a funcionalidade da Plataforma permitir a visualização de Dados e/ou Data Apps, a Contratante deverá preencher todas as informações indicadas como obrigatórias na Plataforma, a fim de permitir a visualização das informações. A Contratante reconhece que esta funcionalidade da Plataforma não salva, armazena ou faz cópia de quaisquer Dados contidos no Banco de Dados da Contratante, mantendo registro apenas de Metadados, com a finalidade exclusiva de informar a Contratante sobre eventuais alterações contidas em seu Banco de Dados (como a inclusão ou exclusão de informações). A Contratante reconhece, entretanto, que os Metadados mantidos pela Plataforma não permitem a identificação e individualização da alteração eventualmente ocorrida, cabendo à Contratante verificar em seu próprio Banco de Dados o conteúdo e o momento de tal alteração.
  (b) Upload de Dados: Sempre que a Plataforma permitir o upload de Dados, a Contratante poderá preencher todas as informações solicitadas na Plataforma que sejam indicadas como obrigatórias, responsabilizando-se integralmente pela veracidade de tais informações.
  (c) Criação de Data Apps: Caso a funcionalidade da Plataforma contratada permita a criação de Data Apps, a Contratante reconhece que a Drumwave não se responsabiliza pelo conteúdo, veracidade e/ou qualidade de quaisquer Dados e/ou Data Apps de terceiros eventualmente utilizados para a criação dos Data Apps pela Contratante, uma vez que a Drumwave não é proprietária dos Dados ou da base de Dados utilizada para a sua criação.

6.1.1. A Contratante reconhece que a Plataforma não é (e não deve ser entendida como sendo) um ambiente virtual para back-up dos Dados e/ou Data Apps da Contratante, de modo que a Drumwave não terá qualquer responsabilidade perante a Contratante em caso de perda ou danos causados à cópia dos Dados e/ou aos Data Apps registrados ou armazenados na Plataforma, independentemente do motivo.

6.1.2. Os Usuários da Contratante poderão compartilhar, entre si, a visualização dos Dados registrados ou armazenados na Plataforma, caso tal compartilhamento seja permitido pela funcionalidade da Plataforma contratada.

6.1.3. Confidencialidade. Todas as informações fornecidas pela Contratante para a utilização da Plataforma, bem como os Dados armazenados na dWallet da Contratante, serão considerados informações confidenciais da Contratante, de modo que a Drumwave não poderá, sem a expressa autorização da Contratante, disponibilizar a quaisquer terceiros o acesso aos Dados da Contratante.

6.2. Serviços de Armazenamento e Processamento de Dados. A Contratante poderá, conforme estabelecido no Plano de Serviços, contratar os Serviços de Armazenamento e Processamento de Dados, que serão prestados diretamente pela Drumwave ou por seus terceiros subcontratados, sempre em conexão com a Plataforma e a dWallet.

6.2.1. A Drumwave reserva-se o direito de alterar ou encerrar quaisquer Serviços de Armazenamento e Processamento de Dados periodicamente, mediante o envio de uma notificação com 60 (sessenta) dias de antecedência, sendo certo que nenhum aviso prévio será concedido se tal aviso prévio (i) puder resultar em um problema de segurança ou propriedade intelectual para a Drumwave e/ou seus terceiros subcontratados, (ii) for econômica ou tecnicamente onerosa para a Drumwave, ou (iii) resultar em alguma violação legal para a Drumwave e/ou seus terceiros subcontratados.

6.2.2. Os Serviços de Armazenamento e Processamento de Dados poderão ser temporariamente suspensos pela Drumwave, sem necessidade de notificação prévia, caso o uso dos Serviços de Armazenamento e Processamento de Dados pela Contratante ou seus usuários (i) represente um risco de segurança para a Plataforma, para a Drumwave, seus terceiros subcontratados e/ou terceiros; ou (ii) viole os termos deste Termo ou das leis aplicáveis. A fim de evitar dúvidas, as Partes esclarecem que, nesta hipótese, a Contratante permanecerá responsável pelo pagamento da remuneração estabelecida neste Termo durante o período de suspensão.

6.2.3. A Contratante será responsável por todas as atividades desenvolvidas em sua dWallet, cabendo à Contratante garantir que o uso dos Serviços de Armazenamento e Processamento de Dados pela Contratante e seus Usuários não violará quaisquer políticas informadas pela Drumwave ou leis aplicáveis. Assim, é de inteira responsabilidade da Contratante o desenvolvimento, conteúdo, operação e manutenção do conteúdo transferido pela Contratante por meio de seus Usuários para ser armazenado e/ou processado por meio dos Serviços de Armazenamento e Processamento de Dados, e quaisquer resultados computacionais deles derivados.

7. REMUNERAÇÃO, FATURAMENTO, CONDIÇÕES DE PAGAMENTO, REAJUSTES E TRIBUTOS

7.1. Pelo direito de uso da Plataforma e pelos Serviços, a Contratante pagará à Drumwave a remuneração indicada no Plano de Serviços (“Remuneração”).

7.2. A Remuneração será paga pela Contratante à Drumwave conforme as opções de pagamento disponíveis na Plataforma e selecionada pela Contratante no momento da contratação.

7.2.1. No caso de contratação da Plataforma por um período de experiência (trial), a Drumwave poderá, a seu exclusivo critério, solicitar desde o momento do cadastro da Contratante os dados de um cartão de crédito válido da Contratante, sendo certo que qualquer cobrança somente será efetuada pela Drumwave se, findo o período de experiência, a Contratante optar por permanecer utilizando os Serviços.

7.2.2. Havendo atraso imotivado no pagamento, a Contratante pagará à Drumwave juros moratórios de 1% (um por cento) ao mês sobre o valor devido, calculado "pro-rata die", desde a data do vencimento até a data do efetivo pagamento, além de multa moratória de 10% (dez por cento) do valor devido, sem prejuízo do pagamento das despesas com cobrança, inclusive custas e honorários advocatícios.

7.2.3. Fica expressamente entendido e acordado pelas Partes que o recebimento do principal pela Drumwave não significará a quitação dos demais encargos e multa.

7.2.4. Em caso de atraso no pagamento, a Drumwave poderá, a seu exclusivo critério, e sem prejuízo ao direito de posteriormente considerar rescindido o presente Termo, suspender a permissão do direito de uso da Plataforma e a execução dos Serviços contratados até que tais irregularidades sejam efetivamente sanadas.

7.3. O preço estabelecido no Plano de Serviços inclui os tributos aplicáveis, sendo seu recolhimento de responsabilidade do sujeito passivo da obrigação tributária, conforme definido em lei.

7.4. Todos os valores associados com o direito de uso da Plataforma e Serviços prestados pela Drumwave em conexão com a Plataforma somente serão alterados pela Drumwave conforme estabelecido no Plano de Serviços.

8. GARANTIA LIMITADA

8.1. AS FUNCIONALIDADES DA PLATAFORMA E OS SERVIÇOS SÃO FORNECIDOS, COM TODAS AS FALHAS, "NO ESTADO EM QUE SE ENCONTRAM", E TODO O RISCO QUANTO À QUALIDADE, DESEMPENHO, PRECISÃO E ESFORÇO SATISFATÓRIOS É DA CONTRATANTE. AS FUNCIONALIDADES DA PLATAFORMA SÃO FORNECIDAS SEM GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, ENTRE OUTROS, GARANTIAS DE QUE ESTÃO LIVRES DE DEFEITOS, LIVRES DE VÍRUS, CAPAZES DE OPERAR DE FORMA ININTERRUPTA, COMERCIALIZÁVEL, DE QUALIDADE SATISFATÓRIA, ADEQUADA PARA UMA FINALIDADE ESPECÍFICA, OU NÃO VIOLADORAS, EXCETO NA MEDIDA EM QUE ESSAS GARANTIAS SEJAM LEGALMENTE INCAPAZES DE EXCLUSÃO. A DRUMWAVE E SEUS LICENCIANTES E OUTROS FORNECEDORES NÃO GARANTEM QUE AS FUNCIONALIDADES DA PLATAFORMA CUMPRIRÃO COM AS EXIGÊNCIAS DA CONTRATANTE OU QUE OS ERROS SERÃO CORRIGIDOS. NENHUM USO DAS FUNCIONALIDADES DA PLATAFORMA ESTÁ AUTORIZADO NESTE DOCUMENTO, EXCETO NOS TERMOS DESTA LIMITAÇÃO.

8.2. A DRUMWAVE NÃO GARANTE QUE A PLATAFORMA ESTEJA ISENTA DE ERROS, POSSÍVEIS INTERRUPÇÕES OU FALHAS, OU QUE A PLATAFORMA SEJA COMPATÍVEL COM QUALQUER HARDWARE OU SOFTWARE ESPECÍFICO, MESMO QUE EXISTA UMA RECOMENDAÇÃO DA DRUMWAVE NESTE SENTIDO. A CONTRATANTE RECONHECE QUE A PLATAFORMA PODE NÃO ESTAR DISPONÍVEL OU SER INDISPONIBILIZADA POR DIVERSOS FATORES, INCLUINDO, SEM LIMITAÇÃO, MANUTENÇÕES PERIÓDICAS DO SISTEMA (PROGRAMADAS OU NÃO), MANIFESTAÇÕES DA NATUREZA, FALHAS TÉCNICAS DO SOFTWARE DA PLATAFORMA, INFRAESTRUTURA DE TELECOMUNICAÇÕES OU ATRASO OU INTERRUPÇÃO OCASIONADA POR VÍRUS, ATAQUES DE NEGAÇÃO DE SERVIÇOS, AUMENTO OU FLUTUAÇÃO DE DEMANDA, AÇÕES E OMISSÕES DE TERCEIROS OU QUALQUER OUTRA CAUSA QUE ESTEJA FORA DO CONTROLE DA DRUMWAVE.

8.3. A DRUMWAVE E SEUS FORNECEDORES FICAM ISENTOS DE QUALQUER RESPONSABILIDADE PERANTE A CONTRATANTE POR ERRO,INCLUINDO NEGLIGÊNCIA, VIOLAÇÃO DE GARANTIA, DECLARAÇÃO INCORRETA, E POR QUALQUER DANO DIRETO, INDIRETO, INCIDENTAL OU ESPECIAL, INCLUINDO QUALQUER PERDA DE LUCRO OU PERDA ECONÔMICA OU QUALQUER PERDA DE PROPÓSITO PARTICULAR, MESMO SE A DRUMWAVE TIVER SIDO AVISADA DE TAL POSSIBILIDADE. A DRUMWAVE NÃO TERÁ RESPONSABILIDADE SOBRE DANOS DE QUALQUER NATUREZA EM DECORRÊNCIA DA UTILIZAÇÃO DA PLATAFORMA NA FORMA AQUI PREVISTA.

8.4. AS PARTES RECONHECEM QUE A ISENÇÃO DE RESPONSABILIDADE DISPOSTA NESTA CLÁUSULA É PARTE SUBSTANCIAL DESTE TERMO, COM O QUAL A DRUMWAVE NÃO CONCORDARIA CELEBRAR SE NÃO FOSSE POR TAIS RENÚNCIAS.

8.5. A CONTRATANTE RECONHECE E CONCORDA QUE A PLATAFORMA É APENAS UMA FERRAMENTA DISPONIBILIZADA PELA DRUMWAVE PARA O MAPEAMENTO DE DADOS (DATA DISCOVERY) DA CONTRATANTE, E SUA DISPONIBILIZAÇÃO E A DISPONIBILIZAÇÃO DA FUNCIONALIDADE AUDIT REPRESENTAM UMA OBRIGAÇÃO DE MEIO DA DRUMWAVE, E NÃO UMA OBRIGAÇÃO DE RESULTADO, DE MODO QUE A DRUMWAVE NÃO GARANTE A ADEQUAÇÃO, QUALIDADE E/OU CORREÇÃO DO MAPEAMENTO REALIZADO POR MEIO DA PLATAFORMA. A CONTRATANTE RECONHECE QUE O MAPEAMENTO REALIZADO NÃO CONTÉM UMA ANÁLISE DA ADEQUAÇÃO E FINALIDADE DOS DADOS PARA FINS DA LEGISLAÇÃO REFERENTE À PROTEÇÃO DE DADOS PESSOAIS.

8.6. As Partes concordam expressamente que se qualquer limitação ou disposição contida nesta cláusula for considerada como sendo inválida de acordo com qualquer lei aplicável ela deverá, nessa extensão, ser considerada omitida.

9. LIMITAÇÃO DE RESPONSABILIDADE

9.1. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, EM NENHUM CASO A DRUMWAVE OU AS EMPRESAS PERTENCENTES AO MESMO GRUPO ECONÔMICO, SUAS AFILIADAS, CONSELHEIROS, EMPREGADOS E/OU PRESTADORES DE SERVIÇOS (COLETIVAMENTE, O "GRUPO DRUMWAVE") SERÃO RESPONSÁVEIS POR QUAISQUER DANOS INDIRETOS, ESPECIAIS, INCIDENTES, CONSEQUENTES OU EXEMPLARES DECORRENTES DE, OU DE QUALQUER FORMA REFERENTES A ESTE TERMO, À PRESTAÇÃO DOS SERVIÇOS OU AO USO OU IMPOSSIBILIDADE DE USAR AS FUNCIONALIDADES DA PLATAFORMA, MESMO SE A CONTRATANTE TIVER SIDO INFORMADA DESTA POSSIBILIDADE. A RESPONSABILIDADE TOTAL DA DRUMWAVE E DO GRUPO DRUMWAVE, BEM COMO O ÚNICO REMÉDIO DA CONTRATANTE SOB QUALQUER DISPOSIÇÃO DESTE TERMO, DEVERÁ SER EXCLUSIVAMENTE A RESCISÃO DESTE TERMO. NA MEDIDA EM QUE A LIMITAÇÃO ACIMA NÃO SEJA APLICÁVEL POR QUALQUER MOTIVO, A RESPONSABILIDADE DA DRUMWAVE, DO GRUPO DRUMWAVE, DOS TERCEIROS QUE FORNECEM CONTEÚDO E SEUS RESPECTIVOS REPRESENTANTES SERÁ LIMITADA NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI.

9.2. A DRUMWAVE NÃO TERÁ QUALQUER RESPONSABILIDADE PERANTE A CONTRATANTE POR QUALQUER RECLAMAÇÃO OU AÇÃO EM QUE SEJA ALEGADA VIOLAÇÃO ÀS LEIS E REGULAMENTOS DE PRIVACIDADE OU PROTEÇÃO DE DADOS RELACIONADOS A (I) USOS DE QUALQUER SOFTWARE DISPONIBILIZADO PELA DRUMWAVE DE FORMA DIFERENTE DAQUELA ESPECIFICADA PELA DRUMWAVE; (II) MODIFICAÇÕES OU CUSTOMIZAÇÕES DE QUALQUER SOFTWARE UTILIZADO PELA DRUMWAVE; OU (III) QUALQUER INCIDENTE DE SEGURANÇA OU VAZAMENTO DE DADOS OCORRIDOS NO ECOSSISTEMA DA CONTRATANTE OU EM DECORRÊNCIA DE AUSÊNCIA DE QUAISQUER MEDIDAS TÉCNICAS E ADMINISTRATIVAS NECESSÁRIAS E RELACIONADAS À SEGURANÇA DA INFORMAÇÃO.

9.3. EM NENHUMA HIPÓTESE, A RESPONSABILIDADE AGREGADA TOTAL DA DRUMWAVE E DO GRUPO DRUMWAVE SERÁ SUPERIOR A 50% (CINQUENTA POR CENTO) DO TOTAL DA REMUNERAÇÃO RECEBIDA PELA DRUMWAVE NO ÂMBITO DESTE TERMO.

10. PROTEÇÃO DE DADOS PESSOAIS

10.1. As Partes obrigam-se a observar as diretrizes da legislação aplicável quanto à proteção de dados e privacidade, incluindo, mas não se limitando à Lei Geral de Proteção de Dados Pessoais, para fins de Tratamento, assim como a cumprir com as políticas de privacidade da Drumwave.

10.2. A Contratante é a exclusiva proprietária e Controladora dos Dados Pessoais eventualmente compartilhados com a Drumwave para fins de prestação dos Serviços, inclusive dos Dados contidos nos cadastros da Contratante e/ou dos Usuários da Contratante. Compete à Contratante, portanto, todas as decisões referentes ao Tratamento a ser realizado pela Drumwave, atuando esta exclusivamente na qualidade de Operadora.

10.3. A Contratante é a responsável pelo Tratamento, integralidade e legitimidade dos Dados disponibilizados na Plataforma, coletados por esta e acessados pela Drumwave, bem como pela utilização do material resultante da prestação dos Serviços, sendo certo que o referido material não poderá, em hipótese alguma, decorrer de infração às normas de proteção de dados, incluindo, sem limitação, a Lei Geral de Proteção de Dados Pessoais, por parte da Contratante.

10.4. A Contratante fica obrigada, quando aplicável em virtude de processo judicial ou administrativo, a comprovar o fundamento legal para o Tratamento disponibilizados e tratados em decorrência deste Termo, proveniente de seu Banco de Dados. A Contratante, neste caso, deverá comprovar que a finalidade divulgada ao Titular, quando da coleta de Dados por ela realizada, coaduna com o objeto do presente Termo.

10.5. A Contratante informará à Drumwave, em até 72 (setenta e duas) horas ou no prazo definido em lei, o que for maior, sobre qualquer incidente cibernético ou violação das disposições deste Termo; qualquer notificação, reclamação, consulta ou solicitação feita por um Titular (como pedidos de acesso, retificação, exclusão), ou por uma autoridade reguladora, devido ao Tratamento relacionado a este Termo, tal solicitação será expressamente reportada à Drumwave, pela Contratante, no mesmo prazo de 72 (setenta e duas) horas.

10.6. A Drumwave se compromete a manter o sigilo de todos os Dados Pessoais a que tenha acesso em consequência desta relação contratual, devendo observar as diretrizes da legislação aplicável quanto à proteção de dados e privacidade.

10.7. A Drumwave não poderá ser responsabilizada pela divulgação e/ou acesso não autorizado dos Dados Pessoais oriundos deste Termo por ato de fornecedores ou quaisquer terceiros que venham a ter acesso a essas informações para a execução deste Termo.

10.8. A Contratante reconhece que, como Controladora, é a única responsável por quaisquer danos e prejuízos decorrentes de suas decisões acerca do Tratamento realizado, mantendo a Drumwave indene de quaisquer danos ou prejuízos que esta venha a sofrer em razão das condutas da Contratante.

10.9. Caso o Titular solicite à Contratante o exercício de seus direitos, previstos na legislação aplicável à proteção de dados, esta deverá informar tal fato à Drumwave para que esta, na qualidade de Operadora, adote as medidas cabíveis.

10.10. Caso a Contratante adote procedimentos técnicos ou administrativos considerados não seguros pela Drumwave e que possam colocar esta ou seus Serviços em situação de vulnerabilidade cibernética ou de segurança, a Drumwave poderá suspender ou rescindir este Termo, caso a Contratante se negue a adotar as recomendações de segurança.

10.11. A Contratante autoriza desde já o compartilhamento dos Dados Pessoais pela Drumwave com empresas pertencentes ao mesmo grupo econômico da Drumwave e terceiros prestadores de serviços contratados pela Drumwave no âmbito da prestação dos Serviços, os quais responderão diretamente à Contratante caso deixem de cumprir com suas obrigações no âmbito de suas contratações, na eventualidade de violação à legislação ou de incidentes cibernéticos.

11. VIGÊNCIA E PRAZO

11.1. Este Termo entrará em vigor no momento de sua assinatura e, a menos que antecipadamente rescindido, assim permanecerá pelo prazo indicado no Plano de Serviços.

11.2. O presente Termo poderá ser rescindido a qualquer momento por justa causa, independentemente de qualquer notificação ou interpelação e sem prejuízo das demais medidas legais cabíveis, na ocorrência de qualquer dos seguintes eventos:
  (i) pela Drumwave, com efeitos imediatos, se a Contratante e/ou seus Usuários descumprirem qualquer das cláusulas ou condições pactuadas neste Termo; ou
  (ii) em caso de falência, recuperação judicial ou extrajudicial ou liquidação judicial ou extrajudicial da outra Parte, sobrevindo ao término, nestas hipóteses, desde a data do pedido de falência, recuperação judicial ou liquidação, conforme o caso; ou
  (iii) em caso de evento de caso fortuito ou força maior.

11.3. Mediante o término deste Termo, toda e qualquer obrigação de pagamento da Contratante
  será imediatamente devida.

11.4. As Partes esclarecem que o término deste Termo, por qualquer motivo, não implicará a devolução de qualquer quantia paga pela Contratante referente a qualquer parte dos Serviços que tenha sido efetivamente prestada pela Drumwave.

11.5. No final da vigência deste Termo, o acesso à Plataforma será cancelado, porém a Drumwave poderá reter quaisquer Metadados, bem como usá-los, a seu exclusivo critério, para treinamento interno, pesquisa, aprimoramento, análise de ofertas e modelos de serviços da Drumwave.

12. POLÍTICA DE PRIVACIDADE

12.1. A Drumwave reconhece, nos termos da Política de Privacidade (acesse aqui) e da legislação aplicável, a confidencialidade e segurança das informações prestadas pela Contratante e pelos Usuários, empenhando-se em resguardar o seu sigilo, salvo por força de lei ou ordem judicial.

13. DISPOSIÇÕES GERAIS

13.1. Todas as notificações e autorizações que puderem ou tiverem que ser feitas ou dadas pelas Partes no âmbito deste Termo serão válidas e eficazes somente se exteriorizadas através de correspondência e enviada para, conforme o caso, o e-mail da Contratante e/ou de seus Usuários indicados em seu cadastro ou o e-mail da Drumwave expressamente indicado neste Termo, com comprovação de recebimento). Este Termo constitui acordo integral entre a Contratante e a Drumwave e substitui todos os acordos e entendimentos anteriores, sejam orais ou escritos, com relação ao objeto deste documento. Se qualquer parte deste Termo for considerada nula, inexequível ou inválida, isso não afetará as outras disposições e ele só poderá ser modificado por um documento assinado por ambas as Partes. A falha de qualquer uma das Partes em aplicar qualquer disposição deste Termo não será considerada uma renúncia à futura execução dessa ou de qualquer outra disposição. A Contratante não poderá ceder este Termo ou qualquer direito ou obrigação, sem o consentimento prévio por escrito da Drumwave. O Termo será vinculante e reverterá em benefício das Partes e seus respectivos sucessores e cessionários. Nenhuma pessoa ou entidade, além das Partes, terá o direito de fazer cumprir ou buscar a execução deste Termo. Cada Parte cumprirá suas obrigações como contratada independente e não como agente ou representante da outra Parte. Nada neste Termo será considerado ou interpretado como criação de uma parceria, joint venture ou qualquer relacionamento semelhante entre as Partes. Este Termo será regido pelas leis da República Federativa do Brasil. Em caso de dificuldade de interpretação ou execução deste Termo, ou ainda, qualquer controvérsia relacionada ou decorrente do descumprimento deste Termo, tal questão será submetida ao foro da Comarca da Capital do Estado de São Paulo, com a exclusão de qualquer outro, por mais privilegiado que seja.

Para facilitar o contato pela Contratante, a Drumwave criou um canal de atendimento por meio do e- mail contato@drumwave.com.

Estes Termos de Uso foi modificado pela última vez em 27.07.2020.
  `
};
