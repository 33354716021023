const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;

const baseEndpoint = '/organizations';


module.exports = {
    listLandscapes: () => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/landscapes`
        return api(endpoint, 'GET')
    }
}
