

module.exports = {
  general: {
    copyright: `Ⓒ Copyright ${new Date().getFullYear()} DRUMWAVE Inc.`,
    error: {
      generic: 'There was a problem. Please try again later.'
    },
    loading: 'Loading, please wait...',
    no_results: 'No results matching your search',
    copied: 'Copied!',
    not_copied: 'Not Copied!'
  },
  authN: {
    email: 'E-mail',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    button: {
      login: 'Log in',
      confirm: 'Confirm',
    },
    login: {
      forgot: 'Forgot password?',
      error: {
        emailEmpty: 'E-mail cannot be empty',
        passwordEmpty: 'Password cannot be empty',
        wrongCredentials: 'The e-mail and password you entered did not match our records. Please double-check and try again.'
      }
    },
    forgotPassword: {
      title: 'Password reset',
      subTitle: 'Please enter your email address',
      success: {
        title: 'Recovery Email Sent!',
        p1: 'A message has been sent to your e-mail with a link, valid for 24 hours, which allows you to change your password.',
        p2: 'Please follow the instructions in the message to regain access to your account.'
      },
      error: {
        emailEmpty: 'E-mail cannot be empty',
        emailNotFound: 'We cannot find an account associated with this email address.'
      }
    },
    newPassword: {
      title: 'Choose a New Password',
      firstTimeTitle: 'Choose a Password',
      termsOfUse1: 'I accept the',
      termsOfUse2: 'Terms of Use',
      success: {
        title: 'New Password Set!',
        p1: 'Your password has successfully been changed.',
        p2: 'Redirecting you to login page...'
      },
      error: {
        empty: 'Passwords cannot be empty.',
        dontMatch: 'Passwords don\'t match.',
        termsOfUse: 'Please accept the terms of use.',
        length: 'Password must be at least 6 characters.'
      }
    }
  },
  hamburgerMenu: {
    home: 'Home',
    dashboard: 'Overview',
    dWallet: 'dWallet',
    exchange: 'Exchange',
    projects: 'Studio Projects',
    users: 'Users',
    organizations: 'Organizations',
    auditCatalog: 'Data Catalog',
    auditMap: 'Data Map',
    auditWallets: 'Wallets Report',
    open: 'Open'
  },

  overview: {
    title: 'Overview',
  },
  home: {
    title: 'Home',
    cards: {
      organizations: 'Organizations',
      users: 'Users',
      views: 'Data Apps',
      projects: 'Studio Projects',
      datasources: 'Data Sources',
      datasets: 'Datasets'
    }
  },
  landscapes: {
    title: 'Data Apps',
    subTitle: ''
  },
  projects: {
    title: 'Studio',
    subTitle: '',
    selector: {
      option1: 'Folders',
      option2: 'Users'
    },
    table: {
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      updatedAt: 'Date Modified'
    }
  },
  walletCreate: {
    title: 'Create Wallet',
    title_edit: 'Wallet Details',
    add_new: 'Add New',
    details: {
      title: 'Details',
      name: 'Name',
      description: 'Description',
      status: 'Visibility'
    },
    status: [{
      text: 'Organization',
      value: 'private'
    }],
    subscription: {
      title: 'Subscription Plans'
    },
    periodUnit: [{
      text: 'Monthly',
      value: 'months'
    }],
    datasets: {
      title: 'Datasets',
      headers: [{
          text: '',
          value: '',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Source',
          value: 'source'
        }
      ]
    },
    create: 'Create',
    update: 'Update'
  },
  walletEdit: {
    plan: 'Plan',
    currency: 'Currency',
    frequency: 'Frequency',
    price: 'Price',
    add_new: 'Add New',
    subscription: 'Subscription',
    monthly: 'Monthly',
    subscribe_now: 'SUBSCRIBE NOW',
    unsubscribe: 'UNSUBSCRIBE',
    already_subscribed: 'Already subscribed but it\'ll expire on',
    datasets: 'Datasets',
    name: 'Name',
    source: 'Source',
    data_products: 'Data Apps',
    status: [{
        text: 'Organization',
        value: 'private'
      },
      {
        text: 'Marketplace',
        value: 'public'
      }
    ]
  },
  auditData: {
    title: 'Data Catalog',
    subTitle: '',
    create: 'Add Data',
    delete: 'Delete Dataset',
    deleted: 'Dataset deleted!',
    datasets: 'Datasets',
    name: 'Name',
    fields: 'Fields',
    open_with: 'Open with...',
    no_data_products: 'No data apps available yet for this data source',
    myself: "Myself",
    table: {
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      dimScore: 'DIM',
      size: '# Cells',
      origin: 'Origin',
      num_datasets: "# Datasets",
      shared: 'Shared',
      pii: 'PII',
      actions: 'Actions',
      openWith: 'Open with',
      numTransfers: '# Transfers'
    }
  },
  auditUsers: {
    title: 'Wallets Report',
    subTitle: '',
    table: {
      name: 'Name',
      role: 'Role',
      dsp_created: '# Data Assets created',
      dsp_shared: '# Data Assets shared',
      projects_created: '# Projects created'
    }
  },
  dwallet: {
    title: 'dWallet',
    subTitle: '',
    createCSV: 'Upload Excel/CSV',
    createDB: 'Connect to Database',
    connectAPI: 'Connect to API',
    table: {
      name: 'Name',
      dataSource: 'Data Source',
      updatedAt: 'Date Modified',
      dimScore: 'DIM',
      origin: 'Origin',
      size: '# Cells',
      actions: 'Actions',
      openWith: 'Open with',
      open: 'Open'
    },
    public_access: {
      title: 'Public Access to {0}'
    },
    delete: {
      dataset: {
        error: {
          text: '<p>You have shared <strong>{0}</strong> with other users. Please un-share before removing.</p>'
        },
        warning: {
          text: '<p>You must first remove the data source <strong>{0}</strong> to remove the dataset <b>{1}</b>.</p>'
        }
      },
      datasource: {
        error: {
          text: '<p>The data source <strong>{0}</strong> has <strong>{1}</strong> datasets that was shared with other users. Please un-share before removing.</p>'
        }
      },
      dataApp: {
        error: {
          text: '<p>You have shared <strong>{0}</strong> with other users. Please un-share before removing.</p>'
        }
      },
      confirm: {
        title: 'Are you sure?',
        confirmButton: 'Ok',
        cancelButton: 'Cancel'
      },
      success: '<strong>{0}</strong> has been removed'
    }
  },
  share: {
    processing: 'Processing',
    error: 'Something went wrong!'
  },
  unshare: {
    confirm_dataset: {
      title: 'Are you sure?',
      text: '<p><strong>{0}</strong> was shared with you by <strong>{1}</strong>. You will no longer have access.</p>',
      confirmButton: 'Ok',
      cancelButton: 'Cancel'
    },
    confirm_data_app: {
      title: 'Are you sure?',
      text: '<p><strong>{0}</strong> was shared with you by <strong>{1}</strong>. You will no longer have access.</p>',
      confirmButton: 'Ok',
      cancelButton: 'Cancel'
    },
    success: '<strong>{0}</strong> has been unshared'
  },
  exchange: {
    title: 'Exchange',
    subTitle: '',
    create: 'Add Data',
    delete: 'Delete Dataset',
    deleted: 'Dataset deleted!',
    datasets: 'Datasets',
    name: 'Name',
    fields: 'Fields',
    open_with: 'Open with...',
    no_data_products: 'No data apps available yet for this data source',
    table: {
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      dimScore: 'DIM',
      size: '# Cells',
      origin: 'Origin',
      shared: 'Shared',
      pii: 'PII',
      actions: 'Actions',
      openWith: 'Open with',
      numTransfers: '# Shares'
    }
  },
  datasources: {
    title: 'Data Source',
    subTitle: '',
    create: 'Add Data',
    delete: 'Delete Data Source',
    deleted: 'Data Source deleted!',
    embed_error: 'Error: Embedded data could not be displayed.',
    choose_ds_type: 'Choose database type...',
    datasets: 'Datasets',
    name: 'Name',
    fields: 'Fields',
    open_with: 'Open with...',
    no_data_products: 'No data apps available yet for this data source',
    table: {
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      countDatasets: 'Datasets',
      type: 'Origin',
      size: '# Cells',
    }
  },
  datasources_erros: {
    name: 'Please provide a name for this data source',
    provenance: 'Please provide the data source provenance',
    rightHolder: 'Please specify who will hold the data rights',
    includesPII: 'Please provide PII info',
    piiDataFormat: 'Please provide PII format',
    type: 'Please select a source type',
    upload_file: 'Please specify a URL or upload a file',
    host: 'Please provide the database host',
    cloud_provider: 'Please specify the cloud provider',
    country: 'Please specify the cloud provider country',
    port: 'Please provide the database port',
    user: 'Please provide the database user',
    database: 'Please provide the database',
    schema: 'Please provide the database schema',
    invalid_url: 'The provided URL is invalid, please provide a valid one',
    unsupported_file: 'This file format is not supported. Please provide an Excel or CSV file'
  },
  marketplace: {
    title: 'Marketplace',
    subTitle: '',
    table: {
      name: 'Name',
      creator: 'Creator',
      status: 'Status',
      countDatasets: 'Datasets'
    }
  },
  datawallets: {
    title: 'Data Wallets',
    subTitle: '',
    create: 'Create',
    delete: 'Delete Data Wallet',
    deleted: 'Data Wallet deleted!',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    description: 'Description',
    publisher: 'Publisher',
    table: {
      name: 'Name',
      creator: 'Creator',
      publisher: 'Publisher',
      status: 'Status',
      countDatasets: 'Datasets'
    }
  },
  datasets: {
    title: 'DIM Report',
    create: 'Add Data',
    delete: 'Delete Dataset',
    deleted: 'Dataset deleted!',
    organization: 'Organization',
    creation_date: 'Creation Date',
    user: 'Origin',
    type: 'Type',
    connectionType: 'Connection Type',
    updated_date: 'Updated Date',
    fields: 'Fields',
    rightsHolder: 'Rights Holder',
    provenance: 'Provenance',
    version: 'Version',
    numberOfRows: '# Rows',
    dimscore_info: `DIM score measures the value of a dataset from three perspectives:<br/>
        <ul>
          <li><b>Data</b>: size and update frequency</li>
          <li><b>Information</b>: internal structure, diversity, and richness of relations</li>
          <li><b>Meaning</b>: contextual relevance, including time, location, society and interrelatedness with the reality the data is describing</li>
        </ul>`,
    score: 'Score',
    data_score: 'Data',
    info_score: 'Information',
    meaning_score: 'Meaning',
    pii: 'Personally identifiable information',
    certificate_num: 'CERTIFICATE NUM',
    table: {
      position: 'Position',
      name: 'Name',
      creator: 'Creator',
      createdAt: 'Date Created',
      type: 'Data Type',
      includesPII: 'Includes PII?',
      piiDataFormat: 'PII Data Format'
    }
  },
  dsAPI: {
    plataform: 'Plataform',
    choose_plataform: 'Choose an API plataform',
    apiKey: 'API Key or User'
  },
  dsCreateEdit: {
    uploadTitle: 'Upload File',
    connectDbTitle: 'Connect to a Database',
    apiTitle: 'Connect to an API',
    editTitle: 'Edit Data Source',
    detailsTitle: 'Data Source Details',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    upload: "Upload file",
    connect: "Connect",
    uploading: 'Uploading...',
    upload_success: 'File uploaded!',
    connecting: 'Connecting...',
    connect_success_db: 'Database connected!',
    connect_success_api: 'API connected!',
    update: 'Update',
    form: {
      name: 'Name',
      description: 'Description',
      provenance: 'Provenance',
      provenance_placeholder: 'Application where this data source is coming from, such as ERP, CRM etc',
      type: 'Source Type',
      host: 'Hostname',
      port: 'Port',
      user: 'User',
      password: 'Password',
      database: 'Database',
      serviceName: 'Service Name',
      schema: 'Schema',
      country: 'Country',
      cloudProvider: 'Cloud Provider',
      cloudProvider_options: [{
          text: 'Choose Cloud Provider',
          value: ''
        },
        {
          text: 'AWS',
          value: 'AWS'
        },
        {
          text: 'Azure',
          value: 'Azure'
        },
        {
          text: 'Google Cloud',
          value: 'Google Cloud'
        },
        {
          text: 'Oracle Cloud',
          value: 'Oracle Cloud'
        },
        {
          text: 'IBM cloud',
          value: 'IBM Cloud'
        },
        {
          text: 'Alibaba Cloud',
          value: 'Alibaba Cloud'
        },
        {
          text: 'Huawei Cloud',
          value: 'Huawei Cloud'
        },
        {
          text: 'DigitalOcean',
          value: 'DigitalOcean'
        },
        {
          text: 'On Premise',
          value: 'On Premise'
        },
        {
          text: 'Private Data Center',
          value: 'Private Data Centerae'
        }
      ],
      separator: 'Field separator character',
      file: 'Or choose file',
      example_csv: 'Example CSV',
      connection_type: 'Connection Type',
      brand: 'Brand',
      frequency: 'Frequency',
      version: 'Version',
      size: 'Size',
      purpose: 'Purpose',
      includesPII: 'Data includes PII?',
      includesPIIOptions: [{
          text: 'Choose an Option',
          value: ''
        },
        {
          text: 'Yes',
          value: 'true'
        },
        {
          text: 'No',
          value: 'false'
        },
        {
          text: 'I don\'t know',
          value: 'unknown'
        }
      ],
      piiDataFormat: 'PII Data format',
      piiDataFormatOptions: [{
          text: 'Choose PII Data Format',
          value: ''
        },
        {
          text: 'Nominative',
          value: 'nominative'
        },
        {
          text: 'Anonimized',
          value: 'anonimized'
        },
        {
          text: 'Coded',
          value: 'coded'
        }
      ],
      modalities_permission_to_capture: "Modalities of permission to capture",
      registered_lia: "Legitimate Interest has a registered LIA?",
      pii_purpose: "Description of purpose of pii use",
      general_info: "Datasource Details",
      pii_info: "Personally identifiable information (PII)",
      rightHolder: "Rights Holder",
      rightsHolderOptions: [{
          text: 'Choose Rights Holder',
          value: ''
        },
        {
          text: 'Own company',
          value: 'own_company'
        },
        {
          text: 'Other company',
          value: 'other_company'
        },
        {
          text: 'Consumer',
          value: 'consumer'
        },
        {
          text: 'Public domain',
          value: 'public_domain'
        }
      ],
      tech_info: "Tech Info",
      headers: {
        title: 'Field names in first row?',
        auto: 'Auto',
        yes: 'Yes',
        no: 'No'
      },
      delimiters: {
        comma: 'Comma',
        semicolon: 'Semicolon',
        tabular: 'Tab',
        pipe: 'Pipe'
      },
      csv: {
        title: 'Drag your file here to begin or click to browse',
        subTitle: 'We recommend files to have up to 20MB'
      },
      fileUrl: "Or upload by URL:",
      fileUrl_placeholder: "https://..."
    }
  },
  users: {
    title: 'Users',
    subTitle: '{used} of {total} "{license}" licenses',
    create: 'Add User',
    delete: 'Delete User',
    table: {
      name: 'Name',
      email: 'E-mail',
      locale: 'Language',
      createdAt: 'Date Created',
      acceptedAt: 'Date Started',
      lastActive: 'Last Active',
      organization: 'Organization',
      role: 'Role'
    }
  },
  userCreate: {
    createTitle: 'Create User',
    editTitle: 'Edit User',
    validName: 'Valid first name is required.',
    noLicenses: 'There are no licenses for this organization',
    form: {
      name: 'Name',
      userName: 'User Name',
      email: 'Email',
      policy: 'Policy',
      role: 'Role',
      locale: 'Locale'
    }
  },
  organizations: {
    title: 'Organizations',
    subTitle: '',
    create: 'Add Organization',
    delete: 'Delete Organization',
    table: {
      name: 'Name',
      subdomain: 'Subdomain',
      createdAt: 'Date Created',
      trialEndsAt: 'Date Trial End',
      totalLicenses: 'Total licenses',
      usedLicenses: 'Used licenses'
    }
  },
  organizationCreate: {
    createTitle: 'Create Organization',
    editTitle: 'Edit Organization',
    form: {
      name: 'Name',
      subdomain: 'Subdomain',
      seats: 'Seats',
      logoLogin: 'Logo Login',
      logoHome: 'Logo Home',
      trial: 'Trial',
      logoSize: 'Minimum size: 600 x 100 px',
      cropSize: 'Selected size',
      licenses: 'Licenses'
    }
  },
  dataexplorer: {
    title: 'Data Explorer',
    dwallet: 'dWallet',
    name: 'Name',
    type: 'Type',
    datasets: 'Datasets',
    create_new: 'Create New',
    see_all: 'See all',
    business_account: 'Business Account',
    publisher: 'Publisher',
    wallets: 'Wallets'
  },
  modal: {
    delete: {
      question: "Are you sure you want to delete <span id='elementToBeDeleted'></span>?",
      confirm: 'Delete',
      cancel: 'Cancel'
    }
  },
  basebutton: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    clear: 'Clear',
    choose_image: 'Choose Image',
    get_started: 'Get Started'
  },
  signOut: 'Sign out',
  billing: {
    credit_card: 'Credit Card',
    first_name: 'First Name',
    last_name: 'Last name',
    address: 'Address',
    city: 'City',
    country: 'Country',
    postal_code: 'Postal Code',
    subscribe: 'Subscribe',
  },
  notFoundPage: {
    title: 'Oops! Page not found',
    description: 'The requested URL dos not exist',
    button_label: 'Go to Home Page',
  },
  notFoundOrganization: {
    title: 'Oops! There is a problem',
    organization: 'Organization',
    message_text: 'doesn\'t exist.',
    message_text_extended: 'Please check the URL, there may be a typo',
    button_label: 'Need support?',
  },
  transfers: {
    email_already_in_list: "E-mail is already in the list",
    invalid_email: "Please type a valid e-mail address",
    start_date_invalid: "Start date's format is incorrect",
    end_date_invalid: "End date's format is incorrect",
    intent_invalid: "You must select an Intent",
    title: 'Share "{assetName}" with',
    email: "Email",
    start: "Start",
    end: "End",
    intent: "Intent",
    cancel: "CANCEL",
    confirm: "CONFIRM",
    choose_intent: "Choose an intent",
    intents: [
      "Business Intelligence",
      "Product enhancement",
      "Legal requirement",
      "Banking reasons",
      "Marketing",
      "Research",
      "Others"
    ]
  },
  miscellania: {
    data_apps: {
      open_in_studio: "Open this dataset in Studio",
      open_in_ezdash: "Explore this dataset in EzDash",
      launch_dataproduct: "Launch this Data Product",
      transfer: "Share with another user"
    }
  },
  termsOfUse: `
  DRUMWAVE TERMS OF USE
These Terms of Use (“Terms”) are a legal agreement between you and DrumWave Inc. (“DrumWave,” “we,” “us,” or “our”) that governs your use of DrumWave’s platform and any related services operated or provided by DrumWave (collectively, the “Services”). The words “you,” “your,” “yourself,” or “user” refer to entities or individuals that access or use the Services. If you are using the Site on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity’s behalf. BY ACCESSING OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS, INCLUDING THE DISCLAIMER OF WARRANTIES AND LIMITATIONS ON LIABILITY. Any new or additional features, tools, services, or content that are added to the Services will also be subject to these Terms.

  1. REGISTERING WITH US
Access to and use of certain functionalities of the Services require you to register for a user account (“Account”) with us. To register an Account, you must select a payment plan, a term, and any other options that may be presented to you during the registration process(collectively, the “Service Plan”). After selecting your Service Plan, you must create a user ID and password to complete your registration and access your Account. You agree to provide us with accurate, complete and current information about yourself during Account registration and at all other times, and you agree to update all information provided to us or requested by us if, and as soon as, such information changes and before you make any purchase related to the Services. You agree to keep your log-in information confidential and to not authorize any third party to use your Account. We will not be liable for any loss or damage that results from the unauthorized use of your Account, either with or without your knowledge. You are fully responsible for your failure to safeguard information or for permitting any other person to access or use the Services via your Account, and you agree that we may attribute all use of your Account to you. You agree to notify us immediately at using the contact information provided in Section 14 if you suspect any unauthorized use of your Account or any other breach of security. You may not sell or otherwise transfer your Account. We have the right to cancel or suspend your registration for any reason or for no reason at any time, as determined in our sole discretion.

  2. PERMITTED USE
All information, materials, and other content available through the Services (collectively, the “Content”) is the sole and exclusive property of DrumWave or its licensors. Subject to your compliance with these Terms, we grant to you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Services, and to access, use, view, and print any Content, solely for your personal and internal business purposes. Except for the limited rights set forth in this Section, you may not copy, sell, rent, distribute, modify, publicly perform, publicly display, transfer, create derivative works of, or sublicense the Services or Content. When using the Services, you must not:
  • decompile, decipher, disassemble, translate, modify, reverse engineer, or otherwise attempt to access the source code of the Services or Content, except where permitted by law notwithstanding this limitation;
  • remove any proprietary notices on the Services or Content, or attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or security system used as part of the Services or Content;
  • upload to the Services the personal information of others that you are not authorized to provide;
  • provide any content, data or information to DrumWave that contains viruses or malicious code or is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy or right of publicity, hateful, or racially, ethnically or
  otherwise objectionable;
  • use the Services or Content in a way that suggests you are a representative of DrumWave;
  • use the Services or Content as a platform for external applications, or to develop applications,
  services, websites, or any other functionalities that leverage the Services or Content;
  • infringe or misappropriate the intellectual property, proprietary, or privacy rights of any third
  party;
  • interfere with or disrupt the proper functioning of the Services or Content, DrumWave or any
  third-party systems used to host the Services, or other equipment or networks used to provide
  the Services or Content;
  • communicate the Content to the public or otherwise grant access to functionalities of the
  Services only available to Account holders, or any part thereof;
  • circumvent the user authentication or security of the Services or any host, network, or account
  related thereto;
  • use any application programming interface to access the Services or Content;
  • make any use of the Services or Content that violates any applicable local, state, national,
  international, or foreign law including United States and foreign export regulations and
  restrictions;
  • fail to use commercially reasonable efforts to prevent the unauthorized license, access, sale,
  transfer, lease, transmission, distribution, or other disclosure of the Services or Content;
  • allow any individual to use any Account log-in credentials (e.g., user identification(s), code(s), password(s), procedure(s), or user key(s) issued to, or selected by, DrumWave for someone other
  than the individual(s) identified in the Account information; or
  • cause damage to DrumWave’s business, reputation, employees, members, facilities, or to any other person or legal entity.

Any scraping, automated access, or other unauthorized access to, and storage of, Services or Content will result in immediate termination of your access to the Services, Content, and your Account. Use of the Services or Content for any purpose other than what is described in this Section is prohibited.

  3. PAYMENTS AND FEES
You agree to pay DrumWave, according to the Service Plan selected by you, for the right to access and use the Services. In the event of a delay in payment, you agree to pay DrumWave a late payment fee of 10% (ten percent) of the amount due and interest of 1% (one percent) over the amount due per month. Interest is calculated pro-rata from the due date until the date of the effective payment. After determining a delay in payment, DrumWave may: (i) suspend your access to and use of the Services; or (ii) terminate, after suspension, these Terms and your access to and use of the Services.

  4. OWNERSHIP AND INTELLECTUAL PROPERTY
The Services and Content are protected by copyright, trademark, patent, and other intellectual property and proprietary right laws. All title, ownership rights, and intellectual property rights in and to the Content and Services are owned by us or our licensors. All rights are reserved. The Services and Content may contain certain licensed materials, and our licensors may protect their rights in the event of any violation of these Terms.

All trademarks, service marks, logos, trade names, and any other proprietary designations of DrumWave used herein are trademarks or registered trademarks of DrumWave or our suppliers. You may not use any of our trademarks, logos, or trade dress in connection with any product or service that is not owned or provided by us, in manner that is likely to cause confusion among customers or users, or in any manner that disparages us or our suppliers.

  5. USER FEEDBACK
By submitting any ideas, feedback, or suggestions (“Feedback”) to us through the Services or other means, you acknowledge and agree that: (i) your Feedback does not contain confidential or proprietary information of you or any third party; (ii) we are not under any obligations of confidentiality with respect to the Feedback; (iii) we may freely use, reproduce, distribute, and otherwise exploit the Feedback for any purpose; and (iv) you are not entitled to any compensation of any kind from us.

  6. PRIVACY POLICY
Please refer to our Privacy Policy, which describes our practices and policies related to the collection, use, and storage of information about users of the Services. You acknowledge and agree that you are solely responsible for the accuracy and content of your personal information. You expressly consent to the use and disclosure of your personal information and other data and information as may be defined and described in the Privacy Policy.

  7. DISCLAIMER OF WARRANTIES
TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE SERVICES AND CONTENT ARE PROVIDED “AS IS” WITHOUT WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. DRUMWAVE MAKES NO REPRESENTATIONS, WARRANTY, OR GUARANTEE OF THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY, OR COMPLETENESS OF THE SERVICES OR CONTENT. THE SERVICES AND CONTENT ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY. THE CONTENT OR ANY OTHER INFORMATION MADE AVAILABLE THROUGH THE SERVICES DOES NOT CONSTITUTE ADVICE OF ANY KIND AND SHOULD NOT BE RELIED UPON IN ANY WAY. DRUMWAVE IS NOT LIABLE FOR ANY OMISSIONS OR ERRORS OF THE SERVICES OR CONTENT. UNDER NO CIRCUMSTANCES WILL DRUMWAVE BE LIABLE TO YOU OR ANY THIRD PARTY FOR INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS AS SPECIFIED HERE AND, TO THE LEAST EXTENT AS ALLOWED BY LAW, SUCH EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU OR A THIRD PARTY.

  8. LIMITATION OF LIABILITY AND EXCLUSION OF CERTAIN DAMAGES
TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL DRUMWAVE OR ANY OF ITS LICENSORS, SERVICE PROVIDERS OR SUPPLIERS BE LIABLE UNDER, OR IN CONNECTION WITH, THESE TERMS OR THEIR SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR OTHERWISE, FOR ANY: (I) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR LOSS OF DATA, OR (II) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE.

IN NO EVENT WILL DRUMWAVE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED 50% (FIFTY PERCENT) OF THE TOTAL FEES AND CHARGES PAID OR PAYABLE BY YOU TO DRUMWAVE. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY AS SPECIFIED HERE AND, TO THE LEAST EXTENT AS ALLOWED BY LAW, SUCH EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.

  9. GOVERNING LAW; DISPUTE RESOLUTION
These Terms will be governed by and construed in accordance with the laws of the state of California, USA. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms. You and DrumWave each agree to exclusive jurisdiction and venue in the state and federal courts located in Santa Clara County, California, USA. For any dispute or claim you may have arising out of or relating to the Services, Content, or these Terms, you and DrumWave will each give the other the opportunity to resolve it by sending the other a written description of the dispute or claim, along with contact information, relevant documents, supporting information, and the proposed resolution. Notice to DrumWave will be sent to the contact information provided in Section 14 (Contact Information). DrumWave will notify you in writing at the email or mailing address that you provide to us in your notice to us or in your Account profile information. NOTWITHSTANDING ANY PROVISION TO THE CONTRARY, IF FOR ANY REASON, ANY CLAIM OR CAUSE OF ACTION, OR ANY PROCEEDING OR OTHER LITIGATION OF ANY TYPE BROUGHT BY A PARTY AGAINST THE OTHER PARTY, BASED UPON, ARISING OUT OF, OR RELATED TO THE SERVICES, CONTENT, OR THESE TERMS, WHETHER WITH RESPECT TO CONTRACT CLAIMS, TORT CLAIMS, OR OTHERWISE, PROCEEDS IN FEDERAL OR STATE COURT, THE PARTIES HEREBY WAIVE THEIR RESPECTIVE RIGHTS TO A TRIAL BY JURY. THIS WAIVER APPLIES TO ANY SUBSEQUENT AMENDMENTS, RENEWALS, SUPPLEMENTS, OR MODIFICATIONS TO THESE TERMS.

  10. CHANGES TO TERMS
We reserve the right to modify these Terms and will notify you of material modifications, such as by posting updated Terms on the Services, sending you an email to your Account email, or by other reasonable means. You will continue to be bound by the Terms you initially accepted until your acceptance of any modified Terms. You may be required to accept modified Terms to continue use of the Services.

  11. TERMINATION
You may terminate your use of the Services at any time. We may, in our sole and absolute discretion, take whatever action we deem necessary to preserve the integrity of the Services and Content. Violation of any of these Terms may result in actions being taken by us, effective immediately or at a time determined by us, which may include without limitation: (i) temporarily suspending your access to the Services, or (ii) permanently terminating your access to the Services. Without limiting the foregoing, we retain the right to decline to provide the Services to any user who violates these Terms or our Privacy Policy.

Termination will not limit any of DrumWave’s other rights or remedies. Section 3 (Ownership and Intellectual Property), Section 5 (User Feedback), Section 7 (Disclaimer of Warranties), Section 8 (Limitation of Liability and Exclusion of Certain Damages), Section 9 (Governing Law; Dispute Resolution), Section 13 (General Terms), and any other provision that, by its nature, is intended to survive termination will survive termination of these Terms.

  12. INTERNATIONAL USE
Please be aware that we are headquartered in the United States and that the Services and Content are governed by United States law. If you are using the Services or accessing the Content from outside of the United States, your information may be transferred to, stored, and processed in the United States where our servers may be located.

DrumWave makes no representation that the Services or Content are appropriate or available for use in locations outside of the United States, and access to the Services or Content from territories where such Services or Content is illegal is prohibited. Those who choose to access the Services and Content from locations outside of the United States do so on their own initiative and are responsible for compliance with applicable local laws. You may not use or export the Services or Content in violation of United States export laws and regulations.

If you use the Services or access the Content outside of the United States you, (i) consent to the transfer, storage, and processing of your information to and in the United States; (ii) will not access or use the Services or Content if you are on the United States Treasury Department’s “Specially Designated Nationals and Blocked Persons List,” or are located in a country embargoed by the United States; and (iii) agree to comply with all local laws, rules, and regulations including all laws, rules, and regulations in effect in the country in which you reside and the country from which you access the Services. The Services are not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation, or that would subject DrumWave to any registration requirement within such jurisdiction or country.

  13. GENERAL TERMS
If any provision of these Terms is held by a court of competent jurisdiction to be illegal, invalid, or unenforceable, the remaining provisions will remain in full force and effect. You and DrumWave intend that the provisions of these Terms be enforced to the fullest extent permitted by applicable law. Accordingly, you and DrumWave agree that if any provision of these Terms is deemed unenforceable, where possible, it will be modified to the extent necessary to make it enforceable, which may include its deletion. Section titles are for convenience only and have no legal or contractual significance. DrumWave may assign these Terms, in whole or in part, at any time with or without notice to you. You may not assign these Terms or assign, transfer, or sublicense any rights or delegate any duties hereunder. DrumWave’s failure to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches. A waiver will only be binding on DrumWave if it is in a written document signed by DrumWave. Both you and DrumWave warrant to each other that, in entering these Terms, neither DrumWave nor you have relied on, or will have any right or remedy based upon, any statement, representation, warranty, or assurance other than those expressly stated in these Terms. The preceding sentence will not limit or exclude any liability that cannot be limited or excluded under applicable law. No one other than you and DrumWave, or DrumWave’s successors and permitted assigns, will have any right to enforce any of these Terms.

  14. CONTACT INFORMATION
If you have any questions, please contact DrumWave at:

  Email: privacy@drumwave.com
  Mail: 125 University Ave
  Suite 88
  Palo Alto, Ca 94301
  `
};
