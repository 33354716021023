<template>
  <div>
    <card v-for="(item,index) in viewData" v-bind:key="index">
      <template slot="header">
        <h4 class="card-title">{{ item.name }}</h4>
      </template>
      <ul class="viewList">
        <li
          v-for="(views,index) in item.views"
          v-bind:key="index"
          v-if="views.visible === undefined || views.visible === true"
        >
          <a :href="'#/' + item.name + '/' + views.name" target="_blank" rel="noopener">
            <img :src="views.thumbnailUrl" />
            <br />
            {{ views.name }}
          </a>
        </li>
      </ul>
    </card>
  </div>
</template>


<script>
export default {
  name: 'ViewCard',
  props: {
    viewData: Array
  }
};
</script>