<template>
  <v-app>
  <div class="content" id="auditaccount">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('auditData.title')}}</div>
              <p class="card-category">{{$t('auditData.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <DataProductsSearch :onChange="searchInputValue" :advancedFilters="advancedFilters"/>
            <div style="clear: both;"/>

            <div style="margin-top: -40px;">
              <v-tabs v-model="selectedTab" slider-color="#EC008C">
                <v-tab>
                  <div class="tabName">Data Sources</div>
                  <div v-if="datasources_tableDataProvider"  class="tabCounter">{{datasources_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Datasets</div>
                  <div v-if="datasets_tableDataProvider" class="tabCounter">{{datasets_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Data Apps</div>
                  <div v-if="views_tableDataProvider" class="tabCounter">{{views_tableDataProvider.length}}</div>
                </v-tab>
              </v-tabs>
            </div>

            <div v-if="selectedTab==0">
              <div v-if="searchWord && !datasources_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && datasources_tableDataProvider.length)"
                :headers="columns_in_datasources"
                :items="datasources_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :transferCallback="transferCallback"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

            <div v-if="selectedTab==1">
              <div v-if="filtersApplied && !datasets_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!filtersApplied || (filtersApplied && datasets_tableDataProvider.length)"
                :headers="columns_in_datasets"
                :items="datasets_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true" ></DataTable>
            </div>


            <div v-if="selectedTab==2">
              <div v-if="filtersApplied && !views_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!filtersApplied || (filtersApplied && views_tableDataProvider.length)"
                :headers="columns_in_views"
                :items="views_tableDataProvider"
                page="auditaccount"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                defaultSortBy="updatedAt"
                :sortDesc="true" ></DataTable>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import AuditService from 'src/services/audit'
  import DataTable from 'src/components/DataTable.vue'
  import DelModal from 'src/components/DelModal.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'
  import WalletUtils from 'src/mixins/walletUtils'
  import DataProductsSearch from 'src/components/DataProductsSearch.vue'

  export default {
    mixins: [Utils, WalletUtils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      DelModal,
      DataProductsSearch
    },
    data () {
      return {
        columns_in_datasets: [
          { text: this.$t('auditData.table.dimScore'), value: 'dimScoreTotal', sortable: true, tooltip: true, tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('auditData.table.name'), align: 'left', value: 'name'},
          { text: this.$t('dwallet.table.dataSource'), align: 'left', value: 'dataSourceName'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('auditData.table.size'), value: 'size' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.numTransfers'), value: 'numberOfTransfers' },
          { text: this.$t('auditData.table.pii'), value: 'pii' },
          { text: this.$t('auditData.table.openWith'), value: 'dataApps', sortable: false }
        ],
        columns_in_views: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.numTransfers'), value: 'numberOfTransfers' },
          { text: this.$t('dwallet.table.openWith'), value: 'dataApps', sortable: false }
        ],
        columns_in_datasources: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('auditData.table.num_datasets'), value: 'numberOfDatasets' },
          { text: this.$t('dwallet.table.size'), value: 'numberOfCells' },
          { text: this.$t('auditData.table.pii'), value: 'pii' }
          // { text: this.$t('dwallet.table.openWith'), value: 'dataApps', sortable: false }
        ],
        selectedTab: 1,
        datasets: [],
        views: [],
        datasources: [],
        datasets_tableDataProvider: null,
        views_tableDataProvider: null,
        datasources_tableDataProvider: null,
        dataLoaded: false,
        editDelete: true,
        advancedFilters: {
          dim: 'all',
          shared: 'all',
          pii: 'all',
        },
        searchWord: '',
        filtersApplied: false,
      }
    },
    methods: {
      applyFilters( list ){
        let results = list.filter(row => {
          let matchTxt = row.name.toUpperCase().includes(this.searchWord) || (row.wrangler && row.wrangler.toUpperCase().includes(this.searchWord) );
          let matchDim = this.advancedFilters.dim == "all" ? true : row.dim >= this.advancedFilters.dim;
          let matchShared;
            if( this.advancedFilters.shared == "all" )
              matchShared = true;
            else if( this.advancedFilters.shared == "shared" )
              matchShared = row.numTransfers >= 1;
            else if( this.advancedFilters.shared == "notshared" )
              matchShared = row.numTransfers == 0;

          let matchPII = this.advancedFilters.pii == "all" ? true : row.pii == this.advancedFilters.pii

          let passesFilters = matchTxt && matchDim && matchShared && matchPII;
          return passesFilters;
        });
        return results;
      },

      async searchInputValue(e) {
        if( !this.dataLoaded ) {
          await this.loadAllTables();
        }
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.filtersApplied = this.advancedFilters.dim != "all" ||
                              this.advancedFilters.shared != "all" ||
                              this.searchWord;

        this.datasets_tableDataProvider = this.applyFilters( this.datasets );
        this.views_tableDataProvider = this.applyFilters( this.views );
        this.datasources_tableDataProvider = this.applyFilters( this.datasources );
      },

      async loadAllTables() {
        await this.loadDatasets();
        await this.loadViews();
        await this.loadDatasources();
        this.dataLoaded = true;
      },

      async loadDatasources() {
        // to-do: filter by savings/checking
        try{
          let datasources = await AuditService.listDatasources();
          this.parseDatasources( datasources );
          this.datasources_tableDataProvider = [...this.datasources];
        }catch(err){
          console.error('API ERROR', err)
          Swal.fire({
            title: 'Error!',
            text: 'Error loading Datasources',
            type: 'error',
            confirmButtonText: 'Ok'
          });

        }
      },

      async loadDatasets() {
        try {
          let datasets = await AuditService.listDatasets();
          this.parseDatasets( datasets );
          this.datasets_tableDataProvider = [...this.datasets];

        } catch (err) {
          console.error('API ERROR', err)
          Swal.fire({
            title: 'Error!',
            text: 'Datasets not found',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },

      async loadViews(){
          let currentUserPubId = this.getUserPublicId();
          let views = await AuditService.listViews();
          this.parseViews( views );
          this.views_tableDataProvider = [...this.views];
      },
      onSelectedOrganizationChanged: function(e){
        this.loadAllTables();
      },
    },
    async mounted () {
      await this.loadAllTables()
    }
  }
</script>


<style>
  .container-search-bar-and-create-button{
    margin: 0px;
  }

  .searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .v-tabs__div{
    min-width: 160px;
  }
</style>
