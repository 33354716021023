<template>
    <div class='container'>
        <object v-bind:data="datasetURL" width="100%" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;left:0px;right:0px;bottom:0px">
            <embed v-bind:src="datasetURL" width="100%" height="100%">{{$t('datasources.embed_error')}}
        </object>

    </div>
</template>

<script>
import Vue from 'vue'
import ViewService from 'src/services/view'
const config = require('config');

export default {

    data() {
        return {
            datasetURL: ''
        }
    },
    methods: {},
    created() {
        const baseUrl = config.apiUrl.split('.')[1];
        this.datasetURL = `https://studio.${baseUrl}.com/layout.html#/JavierC/audit/mapInterface#@13`;
    }
}
</script>

<style>

</style>
