<template>
  <v-app>
  <div class="content" id="marketplace">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('marketplace.title')}}</div>
              <p class="card-category">{{$t('marketplace.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <DataTable :headers="columns" :items="datawallets" :editDelete="true" page="marketplace" :linkBuilder="linktoStudio" linkTarget="_self" :editCallback="editCallback"></DataTable>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DatawalletService from "src/services/datawallet";
import DataTable from "src/components/DataTable.vue";
import Swal from "sweetalert2";

export default {
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable
  },
  data() {
    return {
      columns: [
        {
          text: this.$t("datawallets.table.name"),
          align: "left",
          value: "name"
        },
        { text: this.$t("datawallets.table.publisher"), value: "orgName" },
        {
          text: this.$t("datawallets.table.countDatasets"),
          value: "datasetsCount"
        },
        { text: this.$t("datawallets.table.status"), value: "status" }
      ],
      datawallets: [],
      data: [],
      editDelete: true
    };
  },
  methods: {
    async searchInputValue(e) {
      await this.loadDatawallets();
      let searchWord = this.$refs.searchInput.getValue().toUpperCase();
      this.datawallets = this.datawallets.filter(row =>
        row.name.toUpperCase().includes(searchWord)
      );
    },
    async loadDatawallets() {
      try {
        let ds = await DatawalletService.listDataWalletsInMarketplace();
        this.data = ds;
        this.datawallets = ds;
      } catch (err) {
        console.error('API ERROR', err)
        Swal.fire({
          title: 'Error!',
          text: 'Marketplace Datawallets not found',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    editCallback(item) {
      this.$router.push({name: "DatawalletEdit", params: {publicId: item.publicId} });
    },

    onSelectedOrganizationChanged: function(e){
      this.loadDatawallets();
    },
    linktoStudio( header, propsItem){
      if(header != 'name') return;
      return '#/dataexplorer/wallets/edit/' + propsItem['publicId'];
    },
    editCallback(item) {
      this.$router.push({
        name: "DatawalletEdit",
        params: { publicId: item.publicId }
      });
    },

    onSelectedOrganizationChanged: function(e) {
      this.loadDatawallets();
    }
  },
  async mounted() {
    await this.loadDatawallets();
  },
  created() {
    var scoper = this;
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  beforeDestroy() {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  }
};
</script>


<style>
.container-search-bar-and-create-button {
  margin: 0px;
}

.searchContainer {
  float: right;
  margin-top: -50px;
  max-width: 300px;
  margin-bottom: 30px;
}
</style>
