const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;

const baseEndpoint = '/organizations';

module.exports = {
    upload: (params) => {
        try {
            let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/signed-urls/upload?name=${params.name}&fileName=${params.fileName}&fileType=${params.fileType}`;
            if (params.orgImage) {
                endpoint += "&orgImage=true"
            }
            return api(endpoint, 'GET')
        } catch (error) {
            throw error;
        }
    }
}
