<template>
  <v-app>
  <div class="content" id="exchange">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('exchange.title')}}</div>
              <p class="card-category">{{$t('exchange.subTitle')}}</p>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput addonRightIcon="fa fa-search" ref="searchInput" placeholder="Search" :onKeyUp="searchInputValue" :onClick="searchInputValue"></BaseInput>
              </form>
            </div>
            <div style="clear: both;"/>
            <div style="margin-top: 20px; margin-bottom: 20px;">
              <v-tabs v-model="selectedTab" slider-color="#EC008C">
                <v-tab>
                  <div class="tabName">Datasets</div>
                  <div v-if="datasets_tableDataProvider"  class="tabCounter">{{datasets_tableDataProvider.length}}</div>
                </v-tab>
                <v-tab>
                  <div class="tabName">Data Apps</div>
                  <div  v-if="views_tableDataProvider" class="tabCounter">{{views_tableDataProvider.length}}</div>
                </v-tab>
              </v-tabs>
            </div>

            <div v-if="selectedTab==0">
              <div v-if="searchWord && !datasets_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && datasets_tableDataProvider.length)"
                :headers="columns_in_datasets"
                :items="datasets_tableDataProvider"
                page="exchange"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :transferCallback="transferCallback"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>

            <div v-if="selectedTab==1">
              <div v-if="searchWord && !views_tableDataProvider.length" class="loadingMessage">{{$t('general.no_results')}}</div>
              <DataTable
                v-if="!searchWord || (searchWord && views_tableDataProvider.length)"
                :headers="columns_in_views"
                :items="views_tableDataProvider"
                page="exchange"
                :linkBuilder="linktoDetails"
                linkTarget="_self"
                :transferCallback="transferCallback"
                defaultSortBy="updatedAt"
                :sortDesc="true">
              </DataTable>
            </div>
          </card>
        </div>
      </div>
    </div>
    <TransferDialog ref="transferDialog" :onConfirmTransfer="onConfirmTransfer"/>
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import DatasetService from 'src/services/dataset'
  import DataSourceService from 'src/services/datasource'
  import ViewService from 'src/services/view'
  import TransferService from 'src/services/transfer'
  import DataTable from 'src/components/DataTable.vue'
  import TransferDialog from 'src/components/TransferDialog.vue'
  import Swal from 'sweetalert2'
  import Utils from 'src/mixins/utils'
  import WalletUtils from 'src/mixins/walletUtils'

  export default {
    mixins: [Utils, WalletUtils],
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
      TransferDialog
    },
    data () {
      return {
        columns_in_datasets: [
          { text: this.$t('exchange.table.dimScore'), value: 'dimScoreTotal', sortable: true, tooltip: true, tooltipText: this.$t('datasets.dimscore_info') },
          { text: this.$t('exchange.table.name'), align: 'left', value: 'name'},
          { text: this.$t('dwallet.table.dataSource'), align: 'left', value: 'dataSourceName'},
          { text: this.$t('dwallet.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('dwallet.table.size'), value: 'size' },
          { text: this.$t('exchange.table.numTransfers'), value: 'numberOfTransfers' },
          { text: this.$t('exchange.table.openWith'), value: 'dataApps', sortable: false },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        columns_in_views: [
          { text: this.$t('dwallet.table.name'), align: 'left', value: 'name'},
          { text: this.$t('auditData.table.origin'), value: 'wrangler' },
          { text: this.$t('dwallet.table.updatedAt'), value: 'updatedAt' },
          { text: this.$t('exchange.table.numTransfers'), value: 'numTransfers' },
          { text: this.$t('dwallet.table.open'), value: 'dataApps', sortable: false },
          { text: this.$t('dwallet.table.actions'), value: 'dataActions' }
        ],
        selectedTab: 0,
        datasets: [],
        views: [],
        datasources: [],
        datasets_tableDataProvider: null,
        views_tableDataProvider: null,
        datasources_tableDataProvider: null,
        dataLoaded: false,
        editDelete: true,
        searchWord: ''
      }
    },
    methods: {
      async searchInputValue(e) {
        if( !this.dataLoaded ) {
          await this.loadAllTables();
        }
        this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
        this.datasets_tableDataProvider = this.datasets.filter(row => row.name.toUpperCase().includes(this.searchWord));
        this.views_tableDataProvider = this.views.filter(row => row.name.toUpperCase().includes(this.searchWord));
        this.datasources_tableDataProvider = this.datasources.filter(row => row.name.toUpperCase().includes(this.searchWord));
      },
      async loadAllTables() {
        await this.loadDatasets();
        await this.loadViews();
        this.dataLoaded = true;
      },
      async loadDatasets() {
        try {
          let datasets = await DatasetService.getCheckingAccountDatasets();
          this.parseDatasets( datasets );
          this.datasets_tableDataProvider = [...this.datasets];

        } catch (err) {
          console.error('API ERROR', err)
          Swal.fire({
            title: 'Error!',
            text: 'Datasets not found',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },
      async loadViews(){
          const accountViews = ViewService.getCheckingAccountViews();
          let views = await accountViews;
          this.parseViews( views );
          this.views_tableDataProvider = [...this.views];
      },
      async onConfirmTransfer(transferObject){
        try{
          let res = await TransferService.transferView(transferObject); 
          this.loadDatasets();
          return res;
        }catch(err){
          return err;
        }
      },
      quitDelete() {
        document.getElementById("deleteModal").classList.add("invisible");
      },
      onSelectedOrganizationChanged: function(e){
        this.loadAllTables();
      }
    },
    async mounted () {
      await this.loadAllTables();
    }
  }
</script>


<style>
  .container-search-bar-and-create-button{
    margin: 0px;
  }

  .searchContainer{
    float: right;
    margin-top: -50px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .v-tabs__div{
    min-width: 160px;
  }
</style>
