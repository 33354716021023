const config = require('config');
const Sentry = require('@sentry/browser');
const Integrations = require('@sentry/integrations')
const ENV = config.sentry.env;

module.exports = {
  setup: (Vue) => {
    if (ENV !== 'DEVELOPMENT') {
      Sentry.init(
        {
          dsn: config.sentry.dsn,
          environment: config.sentry.env,
          integrations: [new Integrations.Vue({ Vue, attachProps: true })]
        }
      )
    }
  }
}