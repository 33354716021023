<template>
    <v-app>
        <div class="content" id="datasourcesCreate">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                        <template slot="header">
                            <div class="page-title">{{$t('dsCreateEdit.editTitle')}}</div>
                        </template>
                        <template slot="raw-content">
                            <CreateEditModal ref="createEditModal" :onClickQuit="quitCreateEdit" :onFormValidated="onFormValidated" :isEdit="true"></CreateEditModal>
                        </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>

import Vue from 'vue'
import Card from 'src/components/Cards/Card.vue'
import CreateEditModal from 'src/components/CreateEditDatasources.vue'
import DatasourceService from 'src/services/datasource'
import DatasetService from 'src/services/dataset'
import Swal from 'sweetalert2'

export default {
    components: {
        CreateEditModal,
        Card
    },
    methods: {
        async onFormValidated(formData, pluicId = null) {
            try {
                Swal.fire({
                    title: 'Submitting',
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                // If no file is selected, reset this variable in form data so that it doesn't
                // get wrongly set in the database
                if (formData.file && formData.file.name == '') {
                    formData.file = null;
                }

                let org = await DatasourceService.updateDatasource(formData.name, formData);
                this.quitCreateEdit();
                Swal.fire({
                    title: 'Datasource Updated!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
            } catch (err) {
                console.error(err);
                let error = err[1].errors[0];
                Swal.fire({
                    title: 'Error!',
                    text: error,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    onBeforeOpen: () => Swal.hideLoading()
                });
            }
        },
        quitCreateEdit() {
            this.$router.push({name: 'Datasources'})
        },
        async loadDatasource(id) {
            try {
                let dataSource = await DatasourceService.getDatasourceByPublicId(id);
                let datasets = await DatasourceService.listDatasets(id);
                /*
                // The Dataset service could return an object instead of an array, if the
                // requested datasource hasn't compelted the metaextraction task yet.
                // The format of this object is as shown below:
                datasets = {
                    is_error: false,
                    status_msg: "Processing data...."
                } */
                this.$refs.createEditModal.editItem(dataSource, datasets);
            } catch (error) {
                throw error;
            }
        }
    },
    async mounted() {
        await this.loadDatasource(this.$route.params.publicId)
    }
}
</script>
