<template>
  <v-app>
    <div class="content" id="dwallet">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <card
              class="strpied-tabled-with-hover"
              body-classes="table-full-width table-responsive"
            >
              <template slot="header">
                <div class="page-title">{{ $t("dwallet.title") }}</div>
                <p class="card-category">{{ $t("dwallet.subTitle") }}</p>
              </template>
              <div
                class="container-search-bar-and-create-button searchContainer"
              >
                <form @submit.prevent="searchInputValue">
                  <BaseInput
                    addonRightIcon="fa fa-search"
                    ref="searchInput"
                    placeholder="Search"
                    :onKeyUp="searchInputValue"
                    :onClick="searchInputValue"
                  ></BaseInput>
                </form>
              </div>
              <div
                class="processingContainer"
                v-show="processingDatasources.length > 0"
              >
                <b>Processing Datasource(s)</b>:
                {{ processingDatasources.join(", ") }}
              </div>
              <div v-if="isTheSameUserOrg" class="connectButtonsContainer">
                <BaseButton
                  class="connectButton"
                  v-if="isCreateAllowed('data-sources')"
                  :buttonLabel="'+ ' + $t('dwallet.connectAPI')"
                  :onClick="callIntegrate"
                ></BaseButton>
                <BaseButton
                  class="connectButton"
                  v-if="isCreateAllowed('data-sources')"
                  :buttonLabel="'+ ' + $t('dwallet.createDB')"
                  :onClick="callConnect"
                ></BaseButton>
                <BaseButton
                  class="connectButton"
                  v-if="isCreateAllowed('data-sources')"
                  :buttonLabel="'+ ' + $t('dwallet.createCSV')"
                  :onClick="callUpload"
                ></BaseButton>
              </div>
              <div style="clear: both" />
              <div style="margin-top: 20px; margin-bottom: 20px">
                <v-tabs v-model="selectedTab" slider-color="#EC008C">
                  <v-tab>
                    <div class="tabName">Data Sources</div>
                    <div
                      v-if="datasources_tableDataProvider"
                      class="tabCounter"
                    >
                      {{ datasources_tableDataProvider.length }}
                    </div>
                  </v-tab>
                  <v-tab>
                    <div class="tabName">Datasets</div>
                    <div v-if="datasets_tableDataProvider" class="tabCounter">
                      {{ datasets_tableDataProvider.length }}
                    </div>
                  </v-tab>
                  <v-tab>
                    <div class="tabName">Data Apps</div>
                    <div v-if="views_tableDataProvider" class="tabCounter">
                      {{ views_tableDataProvider.length }}
                    </div>
                  </v-tab>
                </v-tabs>
              </div>

              <div v-if="selectedTab == 0">
                <div
                  v-if="searchWord && !datasources_tableDataProvider.length"
                  class="loadingMessage"
                >
                  {{ $t("general.no_results") }}
                </div>
                <DataTable
                  v-if="
                    !searchWord ||
                    (searchWord && datasources_tableDataProvider.length)
                  "
                  :headers="columns_in_datasources"
                  :items="datasources_tableDataProvider"
                  page="dwallet"
                  :linkBuilder="linktoDetails"
                  linkTarget="_self"
                  :transferCallback="transferCallback"
                  :deleteCallback="deleteRow"
                  :embedCallback="openEmbedDialog"
                  defaultSortBy="updatedAt"
                  :sortDesc="true"
                >
                </DataTable>
              </div>

              <div v-if="selectedTab == 1">
                <div
                  v-if="searchWord && !datasets_tableDataProvider.length"
                  class="loadingMessage"
                >
                  {{ $t("general.no_results") }}
                </div>
                <DataTable
                  v-if="
                    !searchWord ||
                    (searchWord && datasets_tableDataProvider.length)
                  "
                  :headers="columns_in_datasets"
                  :items="datasets_tableDataProvider"
                  page="dwallet"
                  :linkBuilder="linktoDetails"
                  linkTarget="_self"
                  :transferCallback="transferCallback"
                  :deleteCallback="deleteRow"
                  :embedCallback="openEmbedDialog"
                  defaultSortBy="updatedAt"
                  :sortDesc="true"
                >
                </DataTable>
              </div>

              <div v-if="selectedTab == 2">
                <div
                  v-if="searchWord && !views_tableDataProvider.length"
                  class="loadingMessage"
                >
                  {{ $t("general.no_results") }}
                </div>
                <DataTable
                  v-if="
                    !searchWord ||
                    (searchWord && views_tableDataProvider.length)
                  "
                  :headers="columns_in_views"
                  :items="views_tableDataProvider"
                  page="dwallet"
                  :linkBuilder="linktoDetails"
                  linkTarget="_self"
                  :transferCallback="transferCallback"
                  :deleteCallback="deleteRow"
                  :embedCallback="openEmbedDialog"
                  defaultSortBy="updatedAt"
                  :sortDesc="true"
                >
                </DataTable>
              </div>
            </card>
          </div>
        </div>
      </div>
      <TransferDialog
        ref="transferDialog"
        :onConfirmTransfer="onConfirmTransfer"
      />
      <EmbedDialog ref="embedDialog" />
    </div>
  </v-app>
</template>


<script>
import Vue from "vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import DatasetService from "src/services/dataset";
import DataSourceService from "src/services/datasource";
import ViewService from "src/services/view";
import TransferService from "src/services/transfer";
import DataTable from "src/components/DataTable.vue";
import DelModal from "src/components/DelModal.vue";
import CreateEditModal from "src/components/CreateEditDatasources.vue";
import EmbedDialog from "src/components/EmbedDialog.vue";
import TransferDialog from "src/components/TransferDialog.vue";
import Swal from "sweetalert2";
import Utils from "src/mixins/utils";
import WalletUtils from "src/mixins/walletUtils";

export default {
  mixins: [Utils, WalletUtils],
  components: {
    Card,
    BaseInput,
    BaseButton,
    DataTable,
    DelModal,
    CreateEditModal,
    TransferDialog,
    EmbedDialog,
  },
  data() {
    return {
      columns_in_datasets: [
        {
          text: this.$t("dwallet.table.dimScore"),
          align: "center",
          value: "dimScoreTotal",
          sortable: true,
          tooltip: true,
          tooltipText: this.$t("datasets.dimscore_info"),
        },
        { text: this.$t("dwallet.table.name"), align: "left", value: "name" },
        {
          text: this.$t("dwallet.table.dataSource"),
          align: "left",
          value: "dataSourceName",
        },
        { text: this.$t("auditData.table.origin"), value: "wrangler" },
        { text: this.$t("dwallet.table.updatedAt"), value: "updatedAt" },
        { text: this.$t("dwallet.table.size"), value: "size" },
        {
          text: this.$t("dwallet.table.openWith"),
          value: "dataApps",
          sortable: false,
        },
        { text: this.$t("dwallet.table.actions"), value: "dataActions" },
      ],
      columns_in_views: [
        { text: this.$t("dwallet.table.name"), align: "left", value: "name" },
        { text: this.$t("auditData.table.origin"), value: "wrangler" },
        { text: this.$t("dwallet.table.updatedAt"), value: "updatedAt" },
        {
          text: this.$t("dwallet.table.open"),
          value: "dataApps",
          sortable: false,
        },
        { text: this.$t("dwallet.table.actions"), value: "dataActions" },
      ],
      columns_in_datasources: [
        { text: this.$t("dwallet.table.name"), align: "left", value: "name" },
        { text: this.$t("dwallet.table.updatedAt"), value: "updatedAt" },
        {
          text: this.$t("auditData.table.num_datasets"),
          value: "numberOfDatasets",
        },
        { text: this.$t("dwallet.table.size"), value: "numberOfCells" },
        { text: this.$t("dwallet.table.actions"), value: "dataActions" },
        // { text: this.$t('dwallet.table.openWith'), value: 'dataApps', sortable: false }
      ],
      selectedTab: 1,
      datasets: [],
      views: [],
      datasources: [],
      datasets_tableDataProvider: null,
      views_tableDataProvider: null,
      datasources_tableDataProvider: null,
      dataLoaded: false,
      editDelete: true,
      searchWord: "",
      autorefresh: null,
      processingDatasources: [],
      refreshComponent: null,
      isBeingDestroyed: false,
      isTheSameUserOrg: true,
      datasourcesBeingProcessedInPreviousIteration: 0,
    };
  },
  methods: {
    async searchInputValue(e) {
      if (!this.dataLoaded) {
        await this.loadAllTables();
      }
      this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
      this.datasets_tableDataProvider = this.datasets.filter((row) =>
        row.name.toUpperCase().includes(this.searchWord)
      );
      this.views_tableDataProvider = this.views.filter((row) =>
        row.name.toUpperCase().includes(this.searchWord)
      );
      this.datasources_tableDataProvider = this.datasources.filter((row) =>
        row.name.toUpperCase().includes(this.searchWord)
      );
    },
    async loadAllTables() {
      // starts all processes in parallel
      const ds = this.loadDatasources();
      const d = this.loadDatasets();
      const v = this.loadViews();

      // but wait for them to finish before moving on
      await d;
      await ds;
      await v;

      this.dataLoaded = true;
      this.isTheSameUserOrg =
        this.getOrganization().publicId == this.getOrganizationPublicId();
    },
    async loadDatasources() {
      try {
        const datasources = await DataSourceService.listDatasources();
        const datasourcesInProcess = datasources.filter(
          (x) => x.status === "processing"
        );
        const datasourcesNotComplete = datasources.filter(
          (x) => x.status !== "success"
        );

        if (
          this.datasourcesBeingProcessedInPreviousIteration > 0 &&
          datasourcesInProcess.length == 0
        ) {
          this.loadDatasets();
        }

        this.datasourcesBeingProcessedInPreviousIteration =
          datasourcesInProcess.length;

        this.processingDatasources = [
          ...datasourcesNotComplete.map((x) => x.name),
        ];
        this.datasources = datasources;
        this.datasources_tableDataProvider = this.datasources.map((x) => {
          x.type = "datasource";
          x.isCurrentUser = true;
          return x;
        });

        if (datasourcesInProcess.length > 0) {
          const scoper = this;
          this.refreshComponent = setTimeout(async () => {
            await this.loadDatasources();

            let searchWord = scoper.$refs.searchInput.getValue();
            if (searchWord) {
              scoper.searchInputValue();
            }
          }, 10000);
        }
      } catch (err) {
        if (this.isBeingDestroyed) {
          // do nothing
          return;
        }

        console.error("API ERROR", err);
        Swal.fire({
          title: "Error!",
          text: "Error loading Datasources",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    async loadDatasets() {
      try {
        let datasets = await DatasetService.getSavingsAccountDatasets();
        this.parseDatasets(datasets);
        this.datasets_tableDataProvider = [...this.datasets];
      } catch (err) {
        if (this.isBeingDestroyed) {
          // do nothing
          return;
        }

        console.error("API ERROR", err);
        Swal.fire({
          title: "Error!",
          text: "Error loading Datasources",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    async loadViews() {
      try {
        let views = await ViewService.getSavingsAccountViews();
        this.parseViews(views);
        this.views_tableDataProvider = [...this.views];
      } catch (err) {
        if (this.isBeingDestroyed) {
          // do nothing
          return;
        }
      }
    },

    openEmbedDialog(item) {
      this.$refs.embedDialog.updateData(item);
    },

    async deleteRow(item) {
      let type = item.type;
      try {
        if (item.isCurrentUser) {
          switch (item.type) {
            case "dataset":
              type = "dataset";
              if (item.transferredTo.length > 0) {
                Swal.fire({
                  html: this.$t("dwallet.delete.dataset.error.text", [
                    item.name,
                  ]),
                  type: "info",
                  confirmButtonText: "Ok",
                });
              } else if (item.dataSource.type == "csv") {
                const result = await Swal.fire({
                  title: this.$t("dwallet.delete.confirm.title"),
                  type: "warning",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.$t(
                    "dwallet.delete.confirm.confirmButton"
                  ),
                  cancelButtonText: this.$t(
                    "dwallet.delete.confirm.cancelButton"
                  ),
                });
                if (result.value) {
                  Swal.fire({
                    title: "Removing",
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  await DataSourceService.deleteDatasource(
                    item.dataSource.publicId
                  );
                  await this.loadAllTables();
                  Swal.fire({
                    html: this.$t("dwallet.delete.success", [item.name]),
                    type: "success",
                    showConfirmButton: true,
                    onBeforeOpen: () => {
                      Swal.hideLoading();
                    },
                  });
                }
              } else {
                Swal.fire({
                  html: this.$t("dwallet.delete.dataset.warning.text", [
                    item.dataSource.name,
                    item.name,
                  ]),
                  type: "info",
                  confirmButtonText: "Ok",
                });
              }
              break;
            case "datasource":
              type = "data source";
              const hasDataSetsTransfered = this.datasets_tableDataProvider.filter(
                (x) =>
                  x.dataSourceName == item.name && x.transferredTo.length > 0
              );
              if (hasDataSetsTransfered.length > 0) {
                Swal.fire({
                  html: this.$t("dwallet.delete.datasource.error.text", [
                    item.name,
                    hasDataSetsTransfered.length,
                  ]),
                  type: "info",
                  confirmButtonText: "Ok",
                });
              } else {
                const result = await Swal.fire({
                  title: this.$t("dwallet.delete.confirm.title"),
                  type: "warning",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.$t(
                    "dwallet.delete.confirm.confirmButton"
                  ),
                  cancelButtonText: this.$t(
                    "dwallet.delete.confirm.cancelButton"
                  ),
                });
                if (result.value) {
                  Swal.fire({
                    title: "Removing",
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  await DataSourceService.deleteDatasource(item.publicId);
                  await this.loadAllTables();
                  Swal.fire({
                    html: this.$t("dwallet.delete.success", [item.name]),
                    type: "success",
                    showConfirmButton: true,
                    onBeforeOpen: () => {
                      Swal.hideLoading();
                    },
                  });
                }
              }
              break;
            case "dataproduct":
              type = "data app";
              if (item.transferredTo.length > 0) {
                Swal.fire({
                  html: this.$t("dwallet.delete.dataApp.error.text", [
                    item.name,
                  ]),
                  type: "info",
                  confirmButtonText: "Ok",
                });
              } else {
                const result = await Swal.fire({
                  title: this.$t("dwallet.delete.confirm.title"),
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: this.$t(
                    "dwallet.delete.confirm.confirmButton"
                  ),
                  cancelButtonText: this.$t(
                    "dwallet.delete.confirm.cancelButton"
                  ),
                });
                if (result.value) {
                  Swal.fire({
                    title: "Removing",
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  await ViewService.deleteView(item.publicId);
                  await this.loadAllTables();
                  Swal.fire({
                    html: this.$t("dwallet.delete.success", [item.name]),
                    type: "success",
                    showConfirmButton: true,
                    onBeforeOpen: () => {
                      Swal.hideLoading();
                    },
                  });
                }
              }
          }
        } else {
          switch (item.type) {
            case "dataset":
              type = "dataset";
              let result = await Swal.fire({
                title: this.$t("unshare.confirm_dataset.title"),
                html: this.$t("unshare.confirm_dataset.text", [
                  item.name,
                  item.wrangler,
                ]),
                type: "warning",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t(
                  "unshare.confirm_dataset.confirmButton"
                ),
                cancelButtonText: this.$t(
                  "unshare.confirm_dataset.cancelButton"
                ),
              });
              if (result.value) {
                Swal.fire({
                  title: "Unsharing",
                  onOpen: () => {
                    Swal.showLoading();
                  },
                });
                await TransferService.deleteTransferFromMe(
                  "datasets",
                  item.publicId
                );
                await this.loadAllTables();
                Swal.fire({
                  html: this.$t("unshare.success", [item.name]),
                  type: "success",
                  showConfirmButton: true,
                  onBeforeOpen: () => {
                    Swal.hideLoading();
                  },
                });
              }
              break;
            case "dataproduct":
              type = "data app";
              result = await Swal.fire({
                title: this.$t("unshare.confirm_data_app.title"),
                html: this.$t("unshare.confirm_data_app.text", [
                  item.name,
                  item.wrangler,
                ]),
                type: "warning",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t(
                  "unshare.confirm_data_app.confirmButton"
                ),
                cancelButtonText: this.$t(
                  "unshare.confirm_data_app.cancelButton"
                ),
              });
              if (result.value) {
                Swal.fire({
                  title: "Unsharing",
                  onOpen: () => {
                    Swal.showLoading();
                  },
                });
                await TransferService.deleteTransferFromMe(
                  "views",
                  item.publicId
                );
                await this.loadAllTables();
                Swal.fire({
                  html: this.$t("unshare.success", [item.name]),
                  type: "success",
                  showConfirmButton: true,
                  onBeforeOpen: () => {
                    Swal.hideLoading();
                  },
                });
              }
              break;
          }
        }
      } catch (error) {
        console.error("API ERROR", error);
        Swal.fire({
          title: "Error!",
          text: `Error deleting the ${type}`,
          type: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    callConnect() {
      this.$router.push({
        name: "DatasourceCreate",
        params: { mode: "database" },
      });
    },
    callIntegrate() {
      this.$router.push({ name: "DatasourceCreate", params: { mode: "api" } });
    },
    callUpload() {
      this.$router.push({
        name: "DatasourceCreate",
        params: { mode: "upload" },
      });
    },
    async onConfirmTransfer(transferObject) {
      try {
        Swal.fire({
          title: this.$t("share.processing"),
          onOpen: () => {
            Swal.showLoading();
          },
        });
        let res = await TransferService.transferView(transferObject);
        await this.loadAllTables();
        return res;
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: this.$t("share.error"),
          type: "error",
          confirmButtonText: "Ok",
          onBeforeOpen: () => {
            Swal.hideLoading();
          },
        });
        return err;
      }
    },
    onSelectedOrganizationChanged: function (e) {
      this.loadAllTables();
    },
  },
  async mounted() {
    await this.loadAllTables();
  },
  beforeDestroy() {
    this.isBeingDestroyed = true;
    clearTimeout(this.refreshComponent);
  },
};
</script>


<style>
.container-search-bar-and-create-button {
  margin: 0px;
}

.searchContainer {
  float: right;
  margin-top: -50px;
  max-width: 300px;
  margin-bottom: 10px;
}

.tabName {
  display: inline-block;
}

.tabCounter {
  background-color: #e4e4e4;
  margin-left: 6px;
  padding: 2px 7px;
  border-radius: 10px;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
}

.v-tabs__div {
  min-width: 160px;
}

.processingContainer {
  padding: 10px 10px;
  margin: 20px 10px;
  float: left;
  color: #666;
}

.connectButtonsContainer {
  float: right;
  /* width: 600px; */
  padding: 0px;
  text-align: right;
}
.connectButton {
  width: 140px !important;
  min-width: unset !important;
  margin-right: 0px !important;
}
</style>
