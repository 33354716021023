const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;


module.exports = {
  listDatasources: () => {
    const endpoint = `/audit/data-sources?organizationId=${getOrganizationPublicId()}`;
    return api(endpoint, 'GET')
  },

  listDatasets: () => {
    const endpoint = `/audit/datasets?organizationId=${getOrganizationPublicId()}`;
    return api(endpoint, 'GET')
  },

  listViews: () => {
    const endpoint = `/audit/views?organizationId=${getOrganizationPublicId()}`;
    return api(endpoint, 'GET')
  },

  listUsers: () => {
    const endpoint = `/audit/users?organizationId=${getOrganizationPublicId()}`;
    return api(endpoint, 'GET')
  }
}
