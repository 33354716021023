<template>
  <center style="padding-top: 80px;">
      <div class="topBar">
        <img :src="organizationLogo" style="height: 40px;"/>
      </div>
    <div v-if="submitSuccess==false">
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div></div>
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <div class="logo_container"><img :src="organizationLogo" /></div>

          <h1 class="c-login-page-title">{{$t('authN.forgotPassword.title')}}</h1>
          <small style="font-size: 14px">
            <strong>{{$t('authN.forgotPassword.subTitle')}}</strong>
          </small>
          <div class="login_form" @keydown.enter="submitForm">
            <div v-if="log != ''" class="error">
              <span style="white-space: pre-line">{{log}}</span>
            </div>
            <div class="o-form-group">
              <input v-model="email" class="form-control" name="email" type="email" :placeholder="$t('authN.email')" value required autofocus style="width: 300px;
    margin: 10px;">
            </div>
            <div class="o-form-group">
              <button v-on:click="submitForm" class="baseButton">{{$t('authN.button.confirm')}}</button>
            </div>
            <br />
          </div>
          <br />
          <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <h1 class="c-login-page-title">{{$t('authN.forgotPassword.success.title')}}</h1>
          <p>{{$t('authN.forgotPassword.success.p1')}}</p>
          <p>{{$t('authN.forgotPassword.success.p2')}}</p><br>
          <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
        </div>
      </div>
    </div>
  </center>
</template>

<script>
import Vue from "vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import api from "src/services/api-caller";
import Utils from "src/mixins/utils";
import LoginService from 'src/services/login'
import defaultLogo from 'src/assets/imgs/default/drumwave_logo.png'

export default {
  mixins: [Utils],
  components: {
    BaseInput,
    BaseButton
  },
  data() {
    return {
      email: '',
      submitSuccess: false,
      log: '',
      organizationLogo: '',
      organizationPublicId: ''
    };
  },
  methods: {
    submitForm() {
      this.log = '';
      if (this.email == '') {
        this.log = this.$t('authN.forgotPassword.error.emailEmpty');
        return;
      }

      api('/user-recovery', 'POST', {
          email: this.email,
          orgPubId: this.getOrganizationPublicId()
        })
        .then(res => {
          this.submitSuccess = true;
        })
        .catch(err => {
          if (err[0] == 404) {
            this.log = this.$t('authN.forgotPassword.error.emailNotFound');
          } else if (err[0] == 422) {
            this.log = err[1].errors[0].msg; //TODO is there some commons to get these 422 messages?
          } else {
            console.error("API ERROR", err);
            this.log = this.$t('general.error.generic');
          }
        });
    }    
  },
    async beforeMount() {
      const subdomain = window.location.host.split('.')[0];

      try {
        const org = await LoginService.getOrganization(subdomain);
        this.organizationLogo = org.loginLogoUrl || defaultLogo;
        this.organizationPublicId = org.publicId;

      } catch (err) {
        console.error(err)
        this.$router.push({name: 'NotFound'}); 
      }
    }
};
</script>

<style scoped>

.topBar{
  position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background-color: #252223;
    z-index: 100;
    padding: 5px;
    text-align: left;
}

.logo_container img{
      max-width: 400px;
    max-height: 200px;
}

.logo_container{
     background-color: #ddd;
    width: min-content;
    margin: 10px 40px 40px 40px;
    border-radius: 20px;
    padding: 10px;
    display: none;
}

.login_form{
  width: 300px;
  font-family: 'titillium_webregular';
}

.login_form input {
  margin-bottom: 20px;
  font: Arial, Helvetica, sans-serif;
  border: 1px solid #ccc;
}


.login_form input:hover{
  border-color: #333;
}

.login_form input:focus{
  border-color: #333;
}

.login_form input::placeholder {
  color: #999;
}

.baseButton{
  display: inline-block;
  min-width: fit-content;
  min-width: -moz-max-content;
  width: 10px;
  margin: 0.5em;
  padding: 1em 2em;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: gray;
  font-size: 1em;
  line-height: 1;
  transition: 0.3s;
}
.baseButton:hover{
  background: #e8e5e5;
  color: #555;
}

.error {
  padding: 5px;
  background-color: #f44336;
  color: white;
  margin-bottom: 20px !important;
}
</style>
