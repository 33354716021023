<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">{{$t('general.copyright')}}</div>
    </div>
  </footer>
</template>


<script>
export default {};
</script>


<style>
.copyright {
  text-align: center !important;
}
</style>
