const api = require('./api-caller');
const baseEndpoint = '/organizations'
const { getOrganizationPublicId } = require('../mixins/utils').methods;


module.exports = {
  retrieveEmbed: (embedCode) => {
    let endpoint = `/embed/${embedCode}`;
    return api(endpoint, 'GET');
  },
  createEmbed: (obj) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/embed`;
    return api(endpoint, 'POST', obj);
  }
}
