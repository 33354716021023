<template>
  <div class="text-xs-center">
    <v-select
      v-if="multiple"
      :items="data"
      attach
      chips
      deletable-chips
      :label="label"
      color="black"
      :id="inputID"
      :name="name"
      multiple
    ></v-select>
    <v-select
      v-else
      :items="data"
      attach
      chips
      deletable-chips
      :label="label"
      :id="inputID"
      :name="name"
      color="black"
    ></v-select>
  </div>
</template>


<script>
export default {
  name: "BaseDropdown",
  props: {
    data: {
      type: Array
    },
    required: {
      type: String
    },
    label: {
      type: String
    },
    multiple: {
      type: String
    },
    inputID: {
      type: String
    }
  }
};
</script>


<style scoped>
.dropdown .dropdown-toggle {
  cursor: pointer;
}
.text-xs-center {
  width: 100%;
}
.d-none {
  display: none;
}
.invalidField {
  background-color: red;
}
</style>
