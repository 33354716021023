<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-card-title class="headline">{{ $t("transfers.title", { assetName: this.name }) }}</v-card-title>

      <div style="padding: 0px 20px;">
        <v-autocomplete
          v-model="autocompleteSelectedItem"
          :items="users"
          item-text="email"
          item-value="self"
          label="Search"
          solo
          :hide-selected="true"
          @change="onUserSelectedToShare"
          @keyup="onAutoCompleteKeyUp"
        >
          <template v-slot:item="data">
            <template v-if="data.item.in_list">
              {{data.item.email}} &nbsp;&nbsp;<b>({{$t("transfers.email_already_in_list")}})</b>
            </template>
            <template v-else-if="data.item.invalid_email">
              {{data.item.email}} &nbsp;&nbsp;<b>({{$t("transfers.invalid_email")}})</b>
            </template>
            <template v-else>
              {{data.item.email}}
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div style="padding: 0px 20px;">
        <table style="width: 100%;">
          <th class="row">
            <td class="col-4">{{$t("transfers.email")}}</td>
            <td class="col-2">{{$t("transfers.start")}}</td>
            <td class="col-2">{{$t("transfers.end")}}</td>
            <td class="col-3">{{$t("transfers.intent")}}</td>
            <td class="col-1"></td>
          </th>
          <tr v-bind:key="item.publicId" v-for="(item, index) in internalItems" class="row">
            <td class="col-4 vertical-centered">{{item.email}}
            </td>
            <td class="col-2">
              <!-- <input type="text" v-model="item.startAt"/> -->
              <v-menu
                v-model="item.startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="item.startAt"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :max="item.endAt" v-model="item.startAt" @input="item.startDateMenu = false"></v-date-picker>
              </v-menu>
            </td>
            <td class="col-2">
              <!-- <input type="text" v-model="item.endAt"/> -->
              <v-menu
                v-model="item.endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="item.endAt"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker :min="item.startAt" v-model="item.endAt" @input="item.endDateMenu = false"></v-date-picker>
              </v-menu>
            </td>
            <td class="col-3 vertical-centered">
              <select v-model="item.intent">
                <option value="" disabled :selected="item.intent!=''">{{$t("transfers.choose_intent")}}</option>
                <option v-for="intentName in intents" :value="intentName" :selected="intentName == item.intent">{{intentName}}</option>
              </select>
            </td>
            <td class="col-1">
              <i class="fa fa-trash shareBtn" @click="revokeTransfer(index)"></i>
            </td>
          </tr>
        </table>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{$t("transfers.cancel")}}</v-btn>
        <v-btn text @click="onConfirmCallback">{{$t("transfers.confirm")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserService from 'src/services/user';
import Swal from 'sweetalert2';
import validator from 'validator';
import moment from 'moment';
import Utils from 'src/mixins/utils';

export default {
  name: "TransferDialog",
  mixins: [Utils],
  data() {
    return {
      itemPublicId: null,
      type: null,
      name: null,
      dialog: false,
      users: [],
      phantomUser: {},
      intents: this.$t("transfers.intents"),
      internalItems: [],
      transferableObject: null,
      autocompleteSelectedItem: null
    }
  },
  props:{
    onConfirmTransfer: {
      type: Function
    }
  },

  methods: {
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    onAutoCompleteKeyUp (evt){
      this.phantomUser.email = evt.currentTarget.value;

      // If the typed e-mail is already in the list, do not allow adding it back (disable the item)
      let emailAlreadyPresent = false;
      this.internalItems.forEach(function(item){
        if( item.email.toLowerCase() == evt.currentTarget.value.toLowerCase() ){
          emailAlreadyPresent = true;
        }
      });

      // Also check if the introduced email is valid (well formatted)
      let validEmail = this.validateEmail( evt.currentTarget.value )

      // Reset the validation flags
      delete this.phantomUser.invalid_email;
      delete this.phantomUser.in_list;
      this.phantomUser.disabled = false;

      // Flag if validation doesn't pass
      if( emailAlreadyPresent ){
        this.phantomUser.in_list = true;
        this.phantomUser.disabled = true;
      }else if( !validEmail ){
        this.phantomUser.invalid_email = true;
        this.phantomUser.disabled = true;
      }
    },
    updateData (newData) {
      this.transferableObject = newData;
      this.dialog = true;

      let internalItems = [];
      this.transferableObject.transferredTo.forEach(element => {
        let itemCopy = {...element};
        internalItems.push(itemCopy);
      });
      this.internalItems = internalItems;

      this.itemPublicId = this.transferableObject.publicId;
      this.type = this.transferableObject.type == "dataset" ? "datasets" : "views";
      this.name = this.transferableObject.name;

      this.refreshUsersList();
    },
    validateForm(){
      let errorMessage = false;
      for (let i = 0; i < this.internalItems.length; i++) {
        const item = this.internalItems[i];

        if( !validator.isISO8601( item.startAt ) ){
          errorMessage = this.$t("transfers.start_date_invalid");
          break;
        }

        if( !validator.isISO8601( item.endAt ) ){
          errorMessage = this.$t("transfers.end_date_invalid");
          break;
        }

        if( !this.intents.includes( item.intent ) ){
          errorMessage = this.$t("transfers.intent_invalid");
          break;
        }
      }

      if( errorMessage ){
        Swal.fire({
          title: 'Error!',
          text: errorMessage,
          type: 'error',
          confirmButtonText: 'Ok'
        });
        return false;
      }else{
        return true;
      }

    },
    async onConfirmCallback( event, isConfirmed ){
      let formValid = this.validateForm();
      if( formValid ){
        // We parse the list of people to transfer to,
        // removing the publicId property on those cases tagged as 'phantom',
        // since those are fake ids and the API will handle those users
        // (the ones without publicId) as new ones that need to be created.
        // We either send email oir publicId, never both
        let cleanItems = this.internalItems.map( function(item){
          // Make a copy of the user object to prevent modifying the original one
          var cleanItem = {};
          for( let p in item ){
            cleanItem[p] = item[p];
          }
          // Keep email only for new users, publicId only for existing ones
          if( cleanItem.publicId.indexOf( 'phantom') == 0 ){
            delete cleanItem.publicId;
          }else{
            delete cleanItem.email
          }
          return cleanItem;
        } );

        // Build the request body
        let transferObject = {
            resourceType: this.type,
            resourcePublicId: this.itemPublicId,
            transferTo: cleanItems
          };

        // Add the 'confirm' attribute if requested as such.
        // This forces the API to process the transfer request instead of checking
        // existence of users
        if( isConfirmed )
          transferObject.confirm = true;


        // Call the API
        let res = await this.onConfirmTransfer(transferObject);

        // Handle API response
        // If response is 200 (OK), just inform the user and hide the transfer dialog
        // To-do: standarise the way results are received here ("ok" and this result-as-an-array
        // object with status code ion first element is weird, not sure where it's coming from)
        if( res == "OK" || res[0] == 200 ){
          this.dialog = false;
          Swal.fire(
            'Confirmed!',
            'Sharing completed!',
            'success'
          );
        // 422 = confirmation required from current user as transfer recipients
        // may not exist or belong to another organization
        }else if( res[0] == 422 && !res[1].errors ){

          var respObj = res[1];

          // Build a detailed explanation to alert the current user about
          // problematic recipients of the transfer
          var alertText = '';
          if( respObj.usersInOtherOrganization.length > 0 ){
            var otherOrgsEmails = respObj.usersInOtherOrganization.map( function(item){
              return item.email;
            } );
            alertText += `You are sharing '${this.name}' with <span style="font-weight: bold">${ otherOrgsEmails.join(', ') }</span> who are outside your organization. <br/><br/>`;
          }
          if( respObj.usersNonExistent.length > 0 ){
            var otherOrgsEmails = respObj.usersNonExistent.map( function(item){
              return item.email;
            } );
            alertText += `You are sharing '${this.name}' with <span style="font-weight: bold">${otherOrgsEmails.join(', ')}</span>, who doesn’t have a DrumWave account. The recipient will be invited to create an account to access the data.<br/><br/>`;
          }
          alertText += 'Do you want to continue?';

          // Show alert to the user asking to confirm if he wants to proceed despite the warning
          Swal.fire({
            title: 'Please confirm',
            html: alertText,
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            /* confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', */
            confirmButtonText: 'Yes',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'v-btn theme--light normalColor',
              cancelButton: 'v-btn theme--light normalColor',
            }

          }).then((result) => {
            if (result.value) {
              // If the user confirms, re-trigger the request forcing it to be processed
              this.onConfirmCallback( event, true );
            }
          });
        // If there is any other error, inform the user
        }else{
          if( res[0] == 422 ){
            Swal.fire(
                'Error!',
                res[1].errors.join(".\n"),
                'error'
              )
          }
          else
          {
              Swal.fire(
                'Error!',
                'There was an error with your request!',
                'error'
              )
          }
        }
        console.log( "Sharing call result: ", res );
      }
    },
    onUserSelectedToShare(e){
      console.log( "user selected: ", e );
      var today = moment();
      var aMonthLater = moment().add(30, "days");
      var today_str = today.toISOString().substr(0, 10);
      var aMonthLater_str = aMonthLater.toISOString().substr(0, 10);
      this.internalItems.push(
        {
          publicId: e.publicId, email:e.email, startAt: today_str, endAt: aMonthLater_str, intent:""
        }
      );
      this.$nextTick(() => {
          this.autocompleteSelectedItem = null
      })
      this.refreshUsersList();
    },
    refreshUsersList(){
      let currentUserId = this.getUserPublicId();
      let currentlyInList = this.internalItems.map( function( item ){
        return item.publicId;
      } );
      this.users = [];
      for (let i = 0; i < this.allUsers.length; i++) {
        const user = this.allUsers[i];
        if (user.publicId != currentUserId && !currentlyInList.includes(user.publicId)) {
          this.users.push(user);
        }
      }
      // Add a phantom' user to handle non-existing user input
      // This needs to be re-set everytime the list is refreshed
      this.phantomUser = { email:"", publicId: "phantom-"+new Date().getTime(), invalid_email:true, disabled:true };
      this.phantomUser.self = this.phantomUser;
      this.users.push( this.phantomUser );
    },
    async loadUsers() {
      try {
        let users = await UserService.listUsers();
        users.forEach( element => {
          element.self = element;
        });
        this.allUsers = users;
        this.users = [...users];

        // Add an initial phantom' user to handle non-existing user input
        this.phantomUser = { email:"", publicId: "phantom-"+new Date().getTime() };
        this.phantomUser.self = this.phantomUser;
        this.users.push( this.phantomUser );
      }catch(err){
        console.error('API ERROR Loading Users', err)
        Swal.fire({
          title: 'Error!',
          text: 'Users not found',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    revokeTransfer( index ){
      this.internalItems.splice(index,1);
      this.refreshUsersList();
    }
  },
  async mounted(){
    await this.loadUsers();
  }
}
</script>

<style scoped>
.row{
  border-bottom: solid 1px #ccc;
  padding: 10px 0px;
}

th td{
  font-weight: bold;
}

input{
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.vertical-centered{
  padding: 6px 15px;
}

.v-card__actions {
  padding: 40px 20px 20px 20px;
}

.shareBtn{
    cursor: pointer;
    float: right;
    padding: 10px 0px 0px;
    opacity: 0.3;
  }
  .shareBtn:hover{
    opacity: 1;
    color: rgb(231, 76, 76);
  }

  .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: -20px;
    font-size: 1em;
}

::v-deep .v-list__tile--disabled{
  color: #FF4749 !important;
  font-weight: 200;
}
</style>
