<template>
  <div class="content" id="dataexplorer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <template slot="header">
              <div class="page-title">{{$t('dataexplorer.title')}}</div>
            </template>
            <div class="container-search-bar-and-create-button searchContainer">
              <form @submit.prevent="searchInputValue">
                <BaseInput
                  addonRightIcon="fa fa-search"
                  ref="searchInput"
                  placeholder="Search"
                  :onKeyUp="searchInputValue"
                  :onClick="searchInputValue"
                ></BaseInput>
              </form>
            </div>

            <div class="row panelsContainer">
              <div class="col-5 commonPanel datasourcesPanel">
                <div class="row panelRow">
                  <div class="col-10 panelTitle">{{$t('dataexplorer.dwallet')}}</div>
                  <div class="col-2 panelCounter">{{datasources.length}}</div>
                </div>
                <div class="panelBody">
                  <div v-show="datasources_limited.length>0">
                    <div class="row panelRow columnNames">
                      <div class="col-7 cell">{{$t('dataexplorer.name')}}</div>
                      <div class="col-3 cell">{{$t('dataexplorer.type')}}</div>
                      <div class="col-2 cell">{{$t('dataexplorer.datasets')}}</div>
                    </div>
                    <div class="row panelRow" v-for="item in datasources_limited">
                      <div class="col-7 cell">{{item.name}}</div>
                      <div class="col-3 cell">
                        <div
                          :class="'ds-type-icon ' + 'ds-type-'+item.type"
                        >{{formatType( item.type )}}</div>
                      </div>
                      <div class="col-2 cell">
                        <div class="ds-count-icon">{{getDashedRibbon(item.countDatasets)}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="noResults" v-show="datasources_limited.length==0">0 results</div>
                </div>
                <div class="panelFooter">
                  <div class="row">
                    <div class="col-6 createNew">
                      <a href="/#/datasources/create">+ {{$t('dataexplorer.create_new')}}</a>
                    </div>
                    <div class="col-6 seeMore" v-show="datasources_limited.length>0">
                      <a href="/#/datasources">{{$t('dataexplorer.see_all')}}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-7 commonPanel marketplacePanel">
                <div class="row panelRow">
                  <div class="col-10 panelTitle">{{$t('dataexplorer.business_account')}}</div>
                  <div class="col-2 panelCounter">{{marketplace.length}}</div>
                </div>
                <div class="panelBody">
                  <div v-show="marketplace_limited.length>0">
                    <div class="row panelRow columnNames">
                      <div class="col-5 cell">{{$t('dataexplorer.name')}}</div>
                      <div class="col-5 cell">{{$t('dataexplorer.publisher')}}</div>
                      <div class="col-2 cell">{{$t('dataexplorer.datasets')}}</div>
                    </div>
                    <div class="row panelRow" v-for="item in marketplace_limited">
                      <div class="col-5 cell">
                        <a
                          :href="'#/dataexplorer/wallets/edit/' + item.publicId"
                          target="_self"
                        >{{item.name}}</a>
                      </div>
                      <div class="col-5 cell">{{item.orgName}}</div>
                      <div class="col-2 cell">{{item.datasetsCount}}</div>
                    </div>
                  </div>
                  <div class="noResults" v-show="marketplace_limited.length==0">0 results</div>
                </div>
                <div class="panelFooter">
                  <div class="row">
                    <div class="col-6 createNew"></div>
                    <div class="col-6 seeMore" v-show="marketplace_limited.length>0">
                      <a href="/#/dataexplorer/marketplace">{{$t('dataexplorer.see_all')}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row panelsContainer">
              <div class="commonPanel walletsPanel">
                <div class="row panelRow">
                  <div class="col-10 panelTitle">{{$t('dataexplorer.wallets')}}</div>
                  <div class="col-2 panelCounter">{{datawallets.length}}</div>
                </div>
                <div class="panelBody">
                  <div v-show="datawallets_limited.length>0">
                    <div class="row panelRow columnNames">
                      <div class="col-5 cell">{{$t('dataexplorer.name')}}</div>
                      <div class="col-5 cell">{{$t('dataexplorer.publisher')}}</div>
                      <div class="col-2 cell">{{$t('dataexplorer.datasets')}}</div>
                    </div>
                    <div class="row panelRow" v-for="item in datawallets_limited">
                      <div class="col-5 cell">
                        <a
                          :href="'#/dataexplorer/wallets/edit/' + item.publicId"
                          target="_self"
                        >{{item.name}}</a>
                      </div>
                      <div class="col-5 cell">{{item.orgName}}</div>
                      <div class="col-2 cell">{{item.datasetsCount}}</div>
                    </div>
                  </div>
                  <div class="noResults" v-show="datawallets_limited.length==0">0 results</div>
                </div>
                <div class="panelFooter">
                  <div class="row">
                    <div class="col-6 createNew">
                      <a href="/#/dataexplorer/wallets/create">+ {{$t('dataexplorer.create_new')}}</a>
                    </div>
                    <div class="col-6 seeMore" v-show="datawallets_limited.length>0">
                      <a href="/#/dataexplorer/wallets">{{$t('dataexplorer.see_all')}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import BaseInput from "src/components/Inputs/BaseInput.vue";
import DatasourceService from "src/services/datasource";
import DatawalletService from "src/services/datawallet";
import Swal from "sweetalert2";
import { format } from "path";

export default {
  components: {
    BaseInput
  },

  computed: {
    datasources_limited() {
      return this.datasources.slice(0, 5);
    },
    datawallets_limited() {
      return this.datawallets.slice(0, 5);
    },
    marketplace_limited() {
      return this.marketplace.slice(0, 5);
    }
  },

  data() {
    return {
      searchWord: "",
      datasources_full: [],
      marketplace_full: [],
      datawallets_full: [],

      datasources: [],
      marketplace: [],
      datawallets: []
    };
  },

  methods: {
    async searchInputValue(e) {
      this.searchWord = this.$refs.searchInput.getValue().toUpperCase();
      this.datasources = this.datasources_full.filter(row =>
        row.name.toUpperCase().includes(this.searchWord)
      );
      this.marketplace = this.marketplace_full.filter(row =>
        row.name.toUpperCase().includes(this.searchWord)
      );
      this.datawallets = this.datawallets_full.filter(row =>
        row.name.toUpperCase().includes(this.searchWord)
      );
    },
    async loadDatasources() {
      try {
        let ds = await DatasourceService.listDatasources();
        this.datasources_full = ds;
        this.datasources = ds;
        console.log("data sources loaded: ", ds);
      } catch (err) {
        console.error("API ERROR", err);
        Swal.fire({
          title: "Error!",
          text: "Datasources not found",
          type: "error",
          confirmButtonText: "Ok"
        });
      }
    },
    async loadDatawallets() {
      try {
        let dw = await DatawalletService.listDataWallets();
        this.datawallets_full = dw;
        this.datawallets = dw;
        console.log("data wallets loaded: ", dw);
      } catch (err) {
        console.error("API ERROR", err);
        Swal.fire({
          title: "Error!",
          text: "Datawallets not found",
          type: "error",
          confirmButtonText: "Ok"
        });
      }
    },
    async loadMarketplace() {
      try {
        let marketplace_wallets = await DatawalletService.listDataWalletsInMarketplace();
        this.marketplace_full = marketplace_wallets;
        this.marketplace = marketplace_wallets;

        console.log("Markeplace wallets loaded: ", marketplace_wallets);
      } catch (err) {
        console.error("API ERROR", err);
        Swal.fire({
          title: "Error!",
          text: "Marketplace wallets not found",
          type: "error",
          confirmButtonText: "Ok"
        });
      }
    },
    formatType(type) {
      if (type == "postgres") {
        return "pg";
      }
      return type;
    },
    getDashedRibbon(number) {
      let result = "";
      for (let i = 0; i < number; i++) {
        result += "I";
      }
      return result;
    }
  },
  beforeMount() {
    this.loadDatasources();
    this.loadDatawallets();
    this.loadMarketplace();
  }
};
</script>


<style scoped>
#dataexplorer {
  color: #333;
}

.panelBody {
  margin-bottom: 0px;
}

.panelBody .container {
  padding: 0px;
}

.panelsContainer {
  position: relative;
  padding: 20px;
  clear: both;
}

.searchContainer {
  float: right;
  margin-top: -40px;
  max-width: 300px;
}

.datasourcesPanel {
}

.marketplacePanel {
}

.columnNames {
  font-weight: bold;
}

.panelCounter {
  padding: 9px 23px 0px 0px;
  text-align: right;
  font-size: 1.4em;
  color: #666;
}

.panelFooter {
}

.panelRow {
  border-bottom: solid 1px #ccc;
  padding: 10px 0px 10px 0px;
}

.cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.createNew {
}

.seeMore {
  padding: 0px 23px 0px 0px;
  text-align: right;
}

a {
  color: #42d0ed;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #42d0ed;
  text-decoration: underline;
}

.noResults {
  margin: 10px 0px 30px;
}

.ds-type-icon {
  border: solid 1px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1px 4px;
  font-size: 10px;
  text-align: center;
  width: fit-content;
}
.ds-type-csv {
  border-color: #999;
  color: #999;
}
.ds-type-postgres {
  border-color: royalblue;
  color: royalblue;
}

.ds-count-icon {
  font-weight: bold;
  opacity: 0.7;
}
</style>
