<template>
  <v-app>
  <div class="content" id="datasets">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover grid-container" header-classes="col-6 pull-left" body-classes="table-full-width table-responsive " style="margin: 0px;">
            <template slot="header">
              <div class="page-title">{{$t('datasets.title')}}</div>

              <div class="pull-left" style="padding: 0px">
                <h4 class="boldText" style="margin: 0px">{{name}}</h4>
              </div>
              <div class="col-4 pull-left" style="padding-top: 5px">
                <p class="card-category">by {{organization}}</p>
              </div>
            </template>

            <div class="col-6 pull-left containerRight">
              <div class="row info-row ds-info-text">
                <div class="col-2 infoCol2" style="padding: 0px">{{$t('datasets.provenance')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{provenance}}</b></div>
                <div class="col-3 infoCol3" style="padding: 0px">{{$t('datasets.rightsHolder')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{enumRightsHolder[rightsHolder]}}</b></div>
              </div>
              <div class="row info-row ds-info-text">
                <div class="col-2 infoCol2" style="padding: 0px">{{$t('datasets.type')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{type}}</b></div>
                <div class="col-3 infoCol3" style="padding: 0px">{{$t('datasets.connectionType')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{connectionType}}</b></div>
              </div>

              <div class="row info-row ds-info-text">
                <div class="col-2 infoCol2" style="padding: 0px">{{$t('datasets.user')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{user}}</b></div>
                <div class="col-3 infoCol3" style="padding: 0px">{{$t('datasets.updated_date')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{createdAt}}</b></div>
              </div>

              <div class="row info-row ds-info-text">
                <div class="col-2 infoCol2" style="padding: 0px">{{$t('datasets.version')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{version}}</b></div>
                <div class="col-3 infoCol3" style="padding: 0px">{{$t('datasets.numberOfRows')}}:</div>
                <div class="col-3 infoCol3 pull-left" style="padding: 0px"><b>{{numberOfRows}}</b></div>
              </div>
            </div>

            <div id="watermark">
              <img src="../../assets/imgs/dw_logo_gradient.png" style="height: 100%;"/>
            </div>
            <div id="qrcode"></div>
            <div id="dimContainer">
              <div>
                {{$t('datasets.score')}}
                <v-icon small data-toggle="tooltip" data-placement="top" :title="$t('datasets.dimscore_info')" style="padding-bottom: 3px;">info</v-icon>
              </div>
              <div class="dimScoreValue">
                {{ score }}
              </div>

            </div>

            <div id="dimPartsContainer">
              <div><div class="dimPartValue">{{data_score}}</div> {{$t('datasets.data_score')}}</div>
              <div><div class="dimPartValue">{{info_score}}</div> {{$t('datasets.info_score')}}</div>
              <div><div class="dimPartValue">{{meaning_score}}</div> {{$t('datasets.meaning_score')}}</div>
            </div>

            <div id="piiContainer">
                <div style="color: #999">{{$t('datasets.pii')}}:</div>
                <div style="color: #000">{{strPII}}</div>
            </div>

            <div id="certificateNumber">
                <span style="color: #999">{{$t('datasets.certificate_num')}}:</span>
                <span style="color: #000">{{publicId}}</span>
            </div>

          </card>

          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">

            <div class="row panelRow">
              <div class="col-10 panelTitle">{{$t('datasets.fields')}}</div>
              <div class="col-2 pull-right" style="padding-right: 0px; text-align: right;">
                <BaseButton :onClick="updateFields" :buttonLabel="$t('dsCreateEdit.update')" style="padding: 12px 30px; margin: 0px; float: right;"></BaseButton>

              </div>
            </div>

            <div v-if="!fieldsLoaded" class="loadingMessage">{{$t('general.loading')}}</div>
            <v-data-table
              v-if="fieldsLoaded"
              :headers="columns"
              :items="fields"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPageItems"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>
                    {{ props.item.position }}
                  </td>

                  <td>
                    {{ props.item.name }}
                  </td>

                  <td>
                    {{ props.item.dataType }}
                  </td>

                  <td>
                    <select name="includesPII" :id="`pii-${props.item.publicId}`" class="form-control small" required @change="changedIncludes($event)">
                        <option v-for="includes in $t('dsCreateEdit.form.includesPIIOptions')" :value="`${props.item.publicId}|${includes.value}`" :selected="includes.value == props.item.includesPII">
                            {{includes.text}}
                        </option>
                    </select>
                  </td>

                  <td>
                    <select name="dataFormat" :id="`dataFormat-${props.item.publicId}`" class="form-control small" required @change="changedDataFormat($event)">
                        <option v-for="format in $t('dsCreateEdit.form.piiDataFormatOptions')" :value="`${props.item.publicId}|${format.value}`" :selected="format.value == props.item.piiDataFormat">
                            {{format.text}}
                        </option>
                    </select>
                  </td>

                </tr>
              </template>
            </v-data-table>

          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
  import Vue from 'vue'
  import Card from 'src/components/Cards/Card.vue'
  import BaseInput from 'src/components/Inputs/BaseInput.vue'
  import BaseButton from 'src/components/BaseButton.vue'
  import DataTable from 'src/components/DataTable.vue'
  import Swal from 'sweetalert2'
  import DatasetService from 'src/services/dataset'
  import QRCode from 'davidshimjs-qrcodejs'

  export default {
    components: {
      Card,
      BaseInput,
      BaseButton,
      DataTable,
    },
    props: {
      headers: {
        type: Array
      },
      items: {
        type: Array
      }
    },
    data () {
      return {
        columns: [
          { text: this.$t('datasets.table.position'), value: 'position' },
          { text: this.$t('datasets.table.name'), value: 'name'},
          { text: this.$t('datasets.table.type'), value: 'dataType' },
          { text: this.$t('datasets.table.includesPII'), value: 'includesPII' },
          { text: this.$t('datasets.table.piiDataFormat'), value: 'piiDataFormat'}
        ],
        rowsPerPageItems: [10, 50, 100],
        pagination: {
          sortBy: 'position',
          rowsPerPage: 100,
        },
        enumRightsHolder: this.$t('dsCreateEdit.form.rightsHolderOptions').reduce((acc, cur) => {
          if (cur.value !== '') {
            acc[cur.value] = cur.text;
          }
          return acc;
        }, {}),

        type: '',
        provenance: '',
        connectionType: '',

        publicId: '',
        name: '',
        numberOfRows: '-',
        rightsHolder: '',
        score: '',
        data_score: '',
        info_score: '',
        meaning_score: '',
        version: '',
        diffDays: '',
        createdAt: '',

        organization: '',
        user: '',

        fields: [],
        fieldsToBeEdited: [],
        fieldsLoaded: false,
        strPII: '',

        qrcode: null
      }
    },
    methods: {
      async loadDatasetInfo(id) {
        try {
          let c = await DatasetService.getCertificate(id);

          this.type = c.type;
          this.provenance = c.provenance;
          this.connectionType = c.connectionType;

          this.publicId = c.publicId;
          this.name = c.name;
          this.numberOfRows = c.numberOfRows;
          this.rightsHolder = c.rightsHolder;
          this.version = c.version;

          if (c.dimScore) {
            this.data_score = c.dimScore.D.total == null ? 0 : c.dimScore.D.total.toFixed(2);
            this.info_score = c.dimScore.I.total == null ? 0 : c.dimScore.I.total.toFixed(2);
            this.meaning_score = c.dimScore.D.total == null ? 0 : c.dimScore.M.total.toFixed(2);
            this.score = c.dimScore.total == null ? 0 : c.dimScore.total.toFixed(2);
          }

          this.diffDays = this.diffDate(c.createdAt);
          this.createdAt = this.formatDate(c.createdAt);

          this.organization = c.organizationName;
          this.user = c.userName;

          this.fields = c.fields;
          this.fieldsToBeEdited = c.fields;
          this.fieldsLoaded = true;

          if (this.fields !== null) {
            const arrPII = [];
            this.fields.map(x => arrPII.push(x.includesPII))

            if (arrPII.includes("true")) {
              this.strPII = 'Yes';

            } else if (arrPII.includes("unknown") || arrPII.includes(null)) {
              this.strPII = 'Unknown';

            } else {
              this.strPII =  'No';
            }
          }
        } catch (err) {
          console.error(err)
          Swal.fire({
            title: 'Error!',
            text: 'It was not possible to load the Certificate information. Try again later',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      },
      formatDate(date_str) {
        return date_str ? date_str.replace("T", " ").split(".")[0] : date_str;
      },
      diffDate(date_str) {
        if (date_str) {
          const update_date = new Date(date_str.replace("T", " ").split(".")[0]);
          const date = new Date();
          const diffTime = Math.abs(date - update_date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays;
        }
      },
      changedIncludes(event) {
        let publicId = event.target.value.split('|')[0];
        let includes = event.target.value.split('|')[1];
        this.fieldsToBeEdited = this.fieldsToBeEdited.map(x => {
          if (x.publicId == publicId) {
            x.includesPII = includes;
          }
          if (x.includesPII != "true" && x.piiDataFormat != "")  {
            x.piiDataFormat = "";
          }
          return x;
        })
      },
      changedDataFormat(event) {
        let publicId = event.target.value.split('|')[0];
        let format = event.target.value.split('|')[1];
        this.fieldsToBeEdited = this.fieldsToBeEdited.map(x => {
          if (x.publicId == publicId) {
            x.piiDataFormat = (x.includesPII != "true") ? "" : (format == "") ? x.piiDataFormat : format
          }
          return x;
        })
      },
      async updateFields() {
        try {
          Swal.fire({
              title: 'Submiting',
              onOpen: () => {
                  Swal.showLoading();
              }
          });
          await DatasetService.updateFields(this.publicId, this.fieldsToBeEdited);
          this.$router.go(0);
          Swal.fire({
            title: 'Fields Updated!',
            type: 'success',
            showConfirmButton: false,
            timer: 1000,
            onBeforeOpen: () => {
              Swal.hideLoading();
            }
          });
        } catch (err) {
          console.error(err);
          let error = err[1].errors[0];
          Swal.fire({
              title: 'Error!',
              text: error,
              type: 'error',
              confirmButtonText: 'Ok',
              onBeforeOpen: () => Swal.hideLoading()
          });
        }
      },
      getWindowWidth(event) {
        const GET = document.querySelectorAll.bind(document)
        let windowWidth = document.documentElement.clientWidth;
        if (windowWidth <= 1400) {
          let containers = GET('.containerRight')
          Array.from(containers).map(x => {
            if (x.classList.contains('col-6')) {
              x.classList.remove('col-6')
              x.classList.add('col-8')
            } else if (x.classList.contains('col-8')) {
              x.classList.remove('col-8')
              x.classList.add('col-6')
            }
          })
          let labels = GET('.infoCol2')
          Array.from(labels).map(x => {
            if (x.classList.contains('col-2')) {
              x.classList.remove('col-2')
              x.classList.add('col')
            }  else if (x.classList.contains('col-2')) {
              x.classList.remove('col')
              x.classList.add('col-2')
            }
          })
          let data = GET('.infoCol3')
          Array.from(data).map(x => {
            if (x.classList.contains('col-3')) {
              x.classList.remove('col3')
              x.classList.add('col')
            } else if (x.classList.contains('col-3')) {
              x.classList.remove('col')
              x.classList.add('col3')
            }
          })
        }
      }
    },
    async mounted() {
      await this.loadDatasetInfo(this.$route.params.publicId);
      this.qrcode = new QRCode("qrcode", {
        text: window.location.href,
        width: 160,
        height: 160,
        colorDark : "#000000",
        colorLight : "#ffffff",
      });
       this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        this.getWindowWidth()
      });
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
    }
  }
</script>


<style>
  .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: unset;
      margin-left: unset;
  }
  .info-row{
    padding: 2px 0px;
  }
  .panelRow{
    border-bottom: solid 1px #ccc;
    padding: 10px 0px 10px 0px;
    width: 100%;
  }

  .boldText {
    font-weight: bold;
  }

  .panelCounter{
    padding: 10px 23px 0px 0px;
    text-align: right;
  }

  .ds-info-text{
    color: #999;
  }

  .ds-info-text b{
    color: #000;
    font-weight: normal;
  }

  .score {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  #qrcode{
    position: absolute;
    right: 10px;
    top: 10px;
  }

  #certificate-num{
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  #dimContainer{
    color: #999;
    position: absolute;
    top: 10px;
    right: 340px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
  }
  .dimScoreValue{
    color: #F7941E;
    font-size: 31px;
    font-weight: bold;
    line-height: 40px;
  }
  #dimPartsContainer{
    color: #999;
    position: absolute;
    top: 10px;
    right: 200px;
    line-height: 19px;
    border-left: solid 1px #ccc;
  }
  .dimPartValue{
    width: 40px;
    text-align: right;
    margin-right: 10px;
    display: inline-block;
    color: #000;
  }

  #certificateNumber{
    position: absolute;
    top: 180px;
    right: 12px;
    font-size: 14px;
  }
  #piiContainer{
    position: absolute;
    top: 87px;
    right: 205px;
    font-size: 12px;
    text-align: center;
    width: 200px;
    border-top: solid 1px #ccc;
    padding-top: 10px;
    font-size: 16px;
  }
  #watermark{
    position: absolute;
    top: 0px;
    right: 245px;
    width: 300px;
    height: 210px;
  }
</style>
