<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
  >

    <v-card>
        <v-card-title class="headline">
            <div class="col-6 pull-left">{{$t('dwallet.public_access.title', [name])}}</div>
            <div class="col-6">
                <v-icon class="pull-right" @click="dialog = false">close</v-icon>
            </div>
        </v-card-title>
        <v-card-title class="headline">
            <div class="col-12">Link</div>
            <div class="col-10">
                <input :readonly="true" v-model="publicLink" type="text" id="publicLink" name="publicLink" class="form-control medium" />
            </div>
            <div class="col-2">
                <img style=" height: 20px; margin-left: 10px; opacity: 0.6; cursor: pointer;"  src="../assets/imgs/dataApps/copy-to-clipboard.png" title="Copy" @click="copyLink" />
                <span id="copiedLinkToClipboard" style="vertical-align: super; font-size: 12px;"></span>
            </div>
        </v-card-title>
        <v-card-title class="headline">
            <div class="col-12">Embed Code</div>
            <div class="col-10">
                <textarea :readonly="true" v-model="embedCode" type="text" id="embedCode" name="embedCode" class="form-control medium" style="min-height: 180px;"></textarea>
            </div>
            <div class="col-2">
                <img style=" height: 20px; margin-left: 10px; opacity: 0.6; cursor: pointer;"  src="../assets/imgs/dataApps/copy-to-clipboard.png" title="Copy" @click="copyCode" />
                <span id="copiedCodeToClipboard" style="vertical-align: super; font-size: 12px;"></span>
            </div>
        </v-card-title>
    </v-card>

  </v-dialog>
</template>

<script>
import EmbedService from 'src/services/embed';
export default {
    name: "EmbedDialog",
    data() {
        return { 
            dialog: false,
            publicLink: '',
            embedCode: '',
            name: ''
        }
    },
    methods: {
        async updateData (item) {
            this.name = item.name;
            const obj = {
                resourceType: 'views',
                resourcePublicId: item.publicId,
                datasetPublicIds: []
            };
            const data = await EmbedService.createEmbed(obj);
            const domain = location.host.split('.').slice(1, 3).join('.');
            const url = `${location.protocol}//embed.${domain}/#/embed/${data.publicId}`;
            this.publicLink = url;
            this.embedCode = `<iframe \n\twidth="600" \n\theight="400" \n\tsrc="${url}" frameborder="0" \n\tallowfullscreen>\n</iframe>`;
            this.dialog = true;
        },
        async copyLink() {
            // get the permission to copy from the user
            const permission = await navigator.permissions.query({ name: 'clipboard-write' });
            if (permission.state == 'granted' || permission.state == 'prompt') {
                const htmlObj = document.querySelector('#publicLink');
                const copyHtmlObj = document.querySelector("#copiedLinkToClipboard");
                htmlObj.focus();
                const linkText = htmlObj.value;
                await navigator.clipboard.writeText(linkText);
                const copyText = await navigator.clipboard.readText();
                if (linkText === copyText) {
                    copyHtmlObj.innerText = this.$t("general.copied");
                    copyHtmlObj.style.color = 'green';
                } else {
                    copyHtmlObj.innerText = this.$t("general.not_copied");
                    copyHtmlObj.style.color = 'red';
                }
            }
        },
        async copyCode() {
            // get the permission to copy from the user
            const permission = await navigator.permissions.query({ name: 'clipboard-write' });
            if (permission.state == 'granted' || permission.state == 'prompt') {
                const htmlObj = document.querySelector('#embedCode');
                const copyHtmlObj = document.querySelector("#copiedCodeToClipboard");
                const codeText = htmlObj.value;
                htmlObj.focus();
                await navigator.clipboard.writeText(codeText);
                const copyText = await navigator.clipboard.readText();
                if (codeText === copyText) {
                    copyHtmlObj.innerText = this.$t("general.copied");
                    copyHtmlObj.style.color = 'green';
                } else {
                    copyHtmlObj.innerText = this.$t("general.not_copied");
                    copyHtmlObj.style.color = 'red';
                }
            }
        }
    }
}
</script>

<style>

</style>