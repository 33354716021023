<template>
  <div class="advancedSearchContainer">
    <!-- <div class="fieldGroup">
      DIM Score: 
      <select @change="onFilterChanged($event)" name="dim" id="fieldDimScore" class="form-control micro" v-model="advancedFilters.dim">
        <option name="" value="all">Show All</option> 
        <option name="" value="20">20%</option> 
        <option name="" value="40">40%</option> 
        <option name="" value="60">60%</option> 
        <option name="" value="80">80%</option> 
        <option name="" value="100">100%</option> 
      </select>
    </div> -->
    
    <div class="fieldGroup">
      Shared: 
      <select @change="onFilterChanged($event)" name="shared" id="fieldSharing" class="form-control micro" v-model="advancedFilters.shared">
        <option name="" value="shared">Shared</option> 
        <option name="" value="notshared">Not shared</option> 
        <option name="" value="all">Show All</option> 
      </select>
    </div>
    <div class="fieldGroup">
      PII: 
      <select @change="onFilterChanged($event)" name="pii" id="fieldPII" class="form-control micro" v-model="advancedFilters.pii">
        <option name="" value="yes">Yes</option> 
        <option name="" value="no">No</option> 
        <option name="" value="unknown">Unknown</option> 
        <option name="" value="all">Show All</option> 
      </select>
    </div>
  </div>
</template>


<script>
export default {

  props: {
    advancedFilters: Object, 
    onChange: Function
  }, 

  methods: {
     onFilterChanged(event){
       console.log( "DataProductsSearch::onFilterChanged" );
      this.onChange();
     }
  }
  
}
</script>


<style scoped>

.advancedSearchContainer{
  padding: 0px 0px 40px;
  text-align: right;
  color: #666;
}

.fieldGroup select{
  height: 40px;
}

.fieldGroup{
  display: inline-block;
  margin-left: 20px;
  text-align: left;
}

</style>