<template>
  <div class="mainContainer">
    <div class="title">
      <div class="titleValue">{{ dataList.length }}</div>
      <div class="titleName"> {{title}}</div>
    </div>
    <div class="listContainer">
      <div class="listItem" v-for="item in dataList.slice(0, 1000)">
        <div class="listItemName">{{item.name}}</div>
        <div class="listItemDate">{{ moment(item.updatedAt).calendar() }}</div>
      </div>
    </div>
  </div>
</template>


<script>
import AnalyticsService from 'src/services/analytics';
import moment from 'moment';

export default {
  name: "AnalyticsPad",
  data() {
    return {
      dataList: [],
      createdAfter: null
    };
  },
  props: {
    title: {
      type: String,
      description: "Title to be displayed"
    },
    apiMethod: {
      type: String,
      description: "API service method name"
    }
  },
  methods: {
    getList(createdAfter) {
      this.createdAfter = createdAfter;
      let scoper = this;
      AnalyticsService[this.apiMethod](this.createdAfter)
        .catch(err => {
          console.error(
            `[AnalyticsPad :: ${scoper.apiMethod}] Error retrieving list of ${scoper.title}`
          );
        })
        .then(function(results) {
          scoper.dataList = results;
        });
    },
    moment(date) {
      return moment(date);
    }
  },
  mounted() {
    moment.updateLocale("en", {
      calendar: {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "[Last] dddd",
        nextWeek: "[Next] dddd",
        sameElse: "L"
      }
    });
  }
};
</script>


<style scoped>
.mainContainer {
  border-radius: 5px;
  padding: 30px 0px 30px;
  width: 320px;
  display: inline-block;
  margin: 10px;
}

.title {
  
    color: #666;
    display: flex;
    flex-wrap: wrap;
    border: none;
    align-items: flex-end;
    margin-bottom: 10px;
    padding: 0px 0px 15px;
    border-bottom: solid 1px #ccc;
}

.titleValue{
    padding: 0px 15px 0px 0px;
    font-weight: 300;
    color: #8cc640;
    font-size: 35px;
    line-height: 26px;
    display: inline-block;
}
.titleName{
  text-align: left;
  font-weight: 300;
  display: inline-block;
}

.listContainer {
  overflow: auto;
  height: 110px;
  box-sizing: border-box;
  padding: 5px 15px 5px 5px;
}
.listItem {
  position: relative;
}

.listItemName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #666;
  max-width: 190px;
  overflow: hidden;
  font-size: 17px;
}
.listItemDate {
  display: -webkit-box;
  float: right;
  font-size: 14px;
  font-style: italic;
  color: #666;
  position: absolute;
  top: 4px;
  right: 0px;
  padding-right: 1px;
}

/*          Changes for portrait mobile      */

@media (max-width: 440px){
    
  .mainContainer{
    margin: 0px;
    padding: 0px;
    width: 100%;
    border: none;
    margin-bottom: 50px;
  }      
}

</style>
