const api = require('./api-caller');

module.exports = {
    transferView: (body) => {
        let endpoint = `/transfers`;
        return api(endpoint, 'PUT', body)
    },
    deleteTransferFromMe: (type, publicId) => {
        const endpoint = `/transfers/${type}/${publicId}`;
        return api(endpoint, 'DELETE');
    }
}
