<template>
    <v-app>
       <div class="content" id="organizationCreate">
           <div class="container-fluid">
               <div class="row">
                   <div class="col-12">
                       <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                           <template slot="header">
                               <h4 class="card-title">{{$t('organizationCreate.createTitle')}}</h4>
                           </template>
                           <template slot="raw-content">
                               <CreateEditOrganization ref="createEditOrganization" :onClickQuit="backToOrganizations" :onFormValidated="onFormValidated"></CreateEditOrganization>
                           </template>
                       </card>
                   </div>
               </div>
           </div>
       </div>
    </v-app>
</template>
<script>
import Vue from 'vue'
import Card from 'src/components/Cards/Card.vue'
import CreateEditOrganization from 'src/components/CreateEditOrganization.vue'
import OrgService from 'src/services/organization'
import SignedService from 'src/services/signed'
import Utils from "src/mixins/utils"
import Swal from 'sweetalert2'

export default {
    mixins: [Utils],
    components: {
        Card,
        CreateEditOrganization
    },
    methods: {
        loadOrganizations() {
            document.getElementById("createEditOrganization").classList.add("invisible");
            OrgService.listOrganizations()
            .then(result => {
                console.log(result)
                this.organizations = result
                this.data = result
            })
            .catch(err => {
                console.error("API ERROR", err);
                Swal.fire({
                title: 'Error!',
                text: 'Organizations not found',
                type: 'error',
                confirmButtonText: 'Ok'
                })
            })
        },
        backToOrganizations() {
            this.$router.push({name: 'Organizations'});
        },
        onFormValidated: async function(formData, edit = false, publicId = null) {
            try {
                Swal.fire({
                    title: 'Submitting',
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
                const orgForm = Object.assign({}, formData)
                delete orgForm.cropImg
                let org = await OrgService.createOrganization(orgForm);
                let orgId = org.publicId;

                let uploaded = await this.uploadImage(formData);

                if( uploaded ){
                    let updateObject = {
                        name:formData.name,
                        subdomain: formData.subdomain,
                        loginLogoUrl: uploaded,
                        homeLogoUrl: uploaded,
                        licenses:formData.licenses
                    }
                    let org = await OrgService.editOrganization( orgId, updateObject);
                }

                this.backToOrganizations();
                Swal.fire({
                    title: 'Organization Created!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000,
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
                this.loadOrganizations();
            } catch (e) {
                let error = e[1].errors[0];
                Swal.fire({
                    title: 'Error!',
                    text: error,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    onBeforeOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        },
        async uploadImage( formData ){
            try{
                let params = {
                    name: formData.subdomain,
                    fileName: 'login_logo.png',
                    content: formData.cropImg,
                    fileType: 'image/png',
                    url: '',
                    orgImage: true
                }
                params.url = await SignedService.upload(params);
                let blobData = this.dataURItoBlob(params.content);
                let uploaded = await this.uploadToS3(params.url, blobData, params.fileType);
                let fileUrl = uploaded.url.split("?")[0];
                return fileUrl;
            }catch(err){
                return false;
            }
        },

        dataURItoBlob(dataURI) {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type: 'image/png'});
        },
    }
}
</script>
<style>

</style>
