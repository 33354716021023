const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;


module.exports = {
  retrieveView: (viewName) => {
    let endpoint = `/wallet/views/${viewName}`;
    return api(endpoint, 'GET')
  },
  getSavingsAccountViews: () => {
    const endpoint = `/wallet/views?section=savings`
    return api(endpoint, 'GET')
  },
  getCheckingAccountViews: () => {
    const endpoint = `/wallet/views?section=checking`
    return api(endpoint, 'GET')
  },
  deleteView: (publicId) => {
    const endpoint = `/organizations/${getOrganizationPublicId()}/views/${publicId}`;
    return api(endpoint, 'DELETE');
  }
}
