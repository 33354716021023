<template>
    <v-app>
        <div class="content" id="userCreate">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
                            <template slot="header">
                                <h4 class="card-title">{{$t('userCreate.createTitle')}}</h4>
                            </template>
                            <template slot="raw-content">
                                <CreateEditUser ref="createEditUser" :onClickQuit="backToUsersList" :onFormValidated="onFormValidated"></CreateEditUser>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
    import Vue from 'vue'
    import UserService from 'src/services/user'
    import CreateEditUser from 'src/components/CreateEditUser.vue'
    import Swal from 'sweetalert2'
    export default {
        components: {
            CreateEditUser
        },
        methods: {
            backToUsersList() {
                this.$router.push({name: 'Users'});
            },
            onFormValidated: async function(formData, edit = false, publicId = null) {
                try {
                    console.log("FormData", JSON.stringify(formData));
                    Swal.fire({
                        title: 'Submitting',
                        onOpen: () => Swal.showLoading()
                    });
                    let user = await UserService.createUser(formData);
                    this.backToUsersList();
                    Swal.fire({
                        title: 'User Created!',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 1000,
                        onBeforeOpen: () => Swal.hideLoading()
                    });
                } catch (err) {
                    let error = err[1].errors[0];
                    console.error(err);
                    Swal.fire({
                        title: 'Error!',
                        text: error,
                        type: 'error',
                        confirmButtonText: 'Ok',
                        onBeforeOpen: () => Swal.hideLoading()
                    });
                }
            }
        }
    }
</script>