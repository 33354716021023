<template>
  <div class="form-group" :class="{
          'input-group': hasIcon
       }">
    <slot name="label">
      <label v-if="label" class="control-label">{{label}}</label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <input
        :value="value"
        v-on="listeners"
        :id="inputID"
        class="form-control"
        aria-describedby="addon-right addon-left"
        :placeholder="placeholder"
        :data-class="listItemClass"
        :required="required"
        :pattern="pattern"
        :label="label"
        :type="type"
      />
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon" @click="onClick"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>


<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    label: {
      type: String,
      description: "Input label"
    },
    value: {
      type: [String, Number],
      description: "Input value"
    },
    inputID: {
      type: String
    },
    placeholder: {
      type: String
    },
    listItemClass: {
      type: String
    },
    required: {
      type: String
    },
    pattern: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right"
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left"
    },
    onClick: {
      type: Function,
      required: false
    },
    onKeyUp: {
      type: Function,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  data() {
    return {
      focused: this.focused
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
        keyup: this.onKeyUp // OBS this line is causing an error in console
      };
    }
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
      this.internalValue = evt.target.value;
      this.placeholderValue = evt.target.value;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      // this.focused = false;
    },
    resetValue: function() {
      this.internalValue = "";
      this.placeholderValue = "";
    },

    getValue() {
      return this.internalValue;
    }
  },
  data() {
    return {
      internalValue: "",
      placeholderValue: ""
    };
  }
};
</script>


<style scoped>
.form-group {
  width: 100%;
}
.invalidField {
  border-color: red;
}
</style>
