import api from '../services/api-caller';
const { setUserInfo, setLastURL } = require('../mixins/utils').methods;


const nonSecure = (to) => {
  return to.path == '/login'
    || to.path == '/password-recovery'
    || to.path == '/terms-of-use'
    || to.path == '/404'
    || to.path.startsWith('/new-password')
    || to.path.startsWith('/embed');
}

export default (router) => {
  return async (to, from, next) => {
    if (nonSecure(to)) {
      next();
      return;
    }

    try {
      const response = await api('/authz/check', null, null, null, null, true);
      const userInfo = response.body;
      const windowSubdomain = window.location.host.split('.')[0];

      if (userInfo.organization.subdomain != windowSubdomain) {
        throw Error('token of an organization different from that of the subdomain');
      }

      setUserInfo(userInfo);
      next();

    } catch (err) {
      setLastURL('/' + window.location.hash);
      router.push({ path: 'login' });
    }
  }
}
