<template>
  <v-app>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <div class="page-title">{{$t('overview.title')}}</div>
              <div class="filtersContainer">
                <div class="timeSelectorContainer filterElement">
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >{{ timeRanges[ selectedTimeRange ].label}}</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                        v-for="(item, index) in timeRanges"
                        class="dropdown-item"
                        href="#"
                        @click="changeTimeRange(index)"
                      >{{item.label}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </template>


            <div class="analyticsContainer">
              <AnalyticsPad
                v-if="isSectionAllowed('datasets')"
                ref="pad_datasets"
                :title="$t('home.cards.datasets')"
                apiMethod="listDatasets"
              />
              <AnalyticsPad
                v-if="isSectionAllowed('views')"
                ref="pad_views"
                :title="$t('home.cards.views')"
                apiMethod="listViews"
              />
              <AnalyticsPad
                v-if="isSectionAllowed('projects')"
                ref="pad_projects"
                :title="$t('home.cards.projects')"
                apiMethod="listProjects"
              />
              <AnalyticsPad
                v-if="isSectionAllowed('users')"
                ref="pad_users"
                :title="$t('home.cards.users')"
                apiMethod="listUsers"
              />
              <AnalyticsPad
                v-if="isSectionAllowed('organizations')"
                ref="pad_organizations"
                :title="$t('home.cards.organizations')"
                apiMethod="listOrganizations"
              />
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  </v-app>
</template>


<script>
import AnalyticsService from 'src/services/analytics';
import AnalyticsPad from 'src/components/AnalyticsPad.vue';
import Utils from 'src/mixins/utils';
import BaseButton from 'src/components/BaseButton.vue';
import moment from 'moment';

export default {
  mixins: [Utils],
  components: {
    AnalyticsPad,
    BaseButton
  },
  data() {
    return {
      selectedTimeRange: 5,
      timeRanges: [
        { label: "Past 7 days", days: 7 },
        { label: "Past 14 days", days: 14 },
        { label: "Past month", days: 30 },
        { label: "Past 3 months", days: 91 },
        { label: "Past 6 months", days: 182 },
        { label: "Past year", days: 365 }
      ]
    };
  },
  methods: {
    changeTimeRange(optionIndex) {
      this.selectedTimeRange = optionIndex;
      this.updatePads();
    },
    updatePads() {
      let offsetDays = this.timeRanges[this.selectedTimeRange].days;

      let startdate = moment();
      startdate = startdate.subtract(offsetDays, "days");
      startdate = startdate.format("YYYY-MM-DD");

      if (this.$refs.pad_organizations) {
        this.$refs.pad_organizations.getList(startdate);
      }

      if (this.$refs.pad_users) {
        this.$refs.pad_users.getList(startdate);
      }

      if (this.$refs.pad_views) {
        this.$refs.pad_views.getList(startdate);
      }

      if (this.$refs.pad_projects) {
        this.$refs.pad_projects.getList(startdate);
      }

      if (this.$refs.pad_datasets) {
        this.$refs.pad_datasets.getList(startdate);
      }
    },
    onSelectedOrganizationChanged(e) {
      this.updatePads();
    }
  },
  created() {
    window.addEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  beforeDestroy() {
    window.removeEventListener('selectedOrganizationChanged', this.onSelectedOrganizationChanged);
  },
  mounted() {
    this.updatePads();
  }
};
</script>


<style>


.filtersContainer {
  position: absolute;
  top: 0px;
  right: 20px;
}
.filtersContainer .dropdown-menu {
  max-height: 355px;
  overflow: auto;
  top: 40px !important;
}

.filterElement {
  display: inline-block;
}


</style>
