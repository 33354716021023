// @ts-nocheck
import DashboardLayout from '../layout/DashboardLayout.vue'

import Embed from 'src/pages/Embed.vue'
import Login from 'src/pages/Login.vue'
import TermsOfUse from 'src/pages/TermsOfUse.vue'
import PasswordSetup from 'src/pages/PasswordSetup.vue'
import PasswordRecovery from 'src/pages/PasswordRecovery.vue'

import NotFound from '../pages/NotFoundPage.vue'
import NotFoundOrganization from '../pages/NotFoundOrganization.vue'

import Home from 'src/pages/ddv-home/Home.vue'
import Overview from 'src/pages/ddv-home/Overview.vue'
import SavingsAccount from 'src/pages/ddv-home/SavingsAccount.vue'
import CheckingAccount from 'src/pages/ddv-home/CheckingAccount.vue'
import View from 'src/pages/View.vue'

import Projects from 'src/pages/ddv-home/Projects.vue'

import AuditData from 'src/pages/ddv-home/AuditData.vue'
import AuditUsers from 'src/pages/ddv-home/AuditUsers.vue'
import AuditMap from 'src/pages/AuditMap.vue'

import Users from 'src/pages/ddv-home/Users.vue'
import UserCreate from 'src/pages/UserCreate.vue'

import Organizations from 'src/pages/ddv-home/Organizations.vue'
import OrganizationCreate from 'src/pages/OrganizationCreate.vue'

import DatasourceView from 'src/pages/DatasourceView.vue'
import DatasetView from 'src/pages/DatasetView.vue'
import DatasourceEdit from 'src/pages/DatasourceEdit.vue'
import DatasourceCreate from 'src/pages/DatasourceCreate.vue'

import Landscapes from 'src/pages/ddv-home/Landscapes.vue'
import Dataexplorer from 'src/pages/ddv-home/DataExplorer.vue'
import DatasetEdit from 'src/pages/ddv-home/DatasetEdit.vue'
import Marketplace from 'src/pages/ddv-home/Marketplace.vue'
import Datawallets from 'src/pages/ddv-home/Datawallets.vue'
import DatawalletCreate from 'src/pages/DataWalletCreate.vue'
// import DatawalletEdit from 'src/pages/DataWalletEdit.vue'


function findBootstrapEnvironment() {
  let envs = ['xs', 'sm', 'md', 'lg', 'xl'];

  let el = document.createElement('div');
  document.body.appendChild(el);

  let curEnv = envs.shift();

  for (let env of envs.reverse()) {
    el.classList.add(`d-${env}-none`);

    if (window.getComputedStyle(el).display === 'none') {
      curEnv = env;
      break;
    }
  }

  document.body.removeChild(el);
  return curEnv;
}


export default [
  { path: '/embed/:embedCode', component: Embed },
  { path: '/login', component: Login },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/new-password/:recoverId', component: PasswordSetup },
  { path: '/password-recovery', component: PasswordRecovery },
  {
    path: '/',
    component: DashboardLayout,
    redirect: () => {
      if (findBootstrapEnvironment() != 'xl') {
        return '/home';
      }

      return '/dwallet';
    },
    children: [
      {
        path: 'landscapes',
        name: 'Landscapes',
        component: Landscapes
      },
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'users/create',
        name: 'UserCreate',
        component: UserCreate
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: Organizations
      },
      {
        path: 'organizations/create',
        name: 'OrganizationCreate',
        component: OrganizationCreate
      },
      {
        path: 'projects',
        name: 'Projects',
        component: Projects
      },
      {
        path: 'dwallet',
        name: 'dWallet',
        component: SavingsAccount
      },
      {
        path: 'auditdata',
        name: 'AuditData',
        component: AuditData
      },
      {
        path: 'auditusers',
        name: 'AuditUsers',
        component: AuditUsers
      },
      {
        path: 'exchange',
        name: 'Exchange',
        component: CheckingAccount
      },
      {
        path: 'datasource/edit/:publicId',
        name: 'DatasourceEdit',
        component: DatasourceEdit
      },
      {
        path: '/datasources/create/:mode',
        name: 'DatasourceCreate',
        component: DatasourceCreate
      },
      {
        path: '/dataexplorer',
        name: 'Dataexplorer',
        component: Dataexplorer
      },
      {
        path: 'datasets/:publicId',
        name: 'DatasetEdit',
        component: DatasetEdit
      },
      {
        path: '/dataexplorer/wallets',
        name: 'Datawallets',
        component: Datawallets
      },
      {
        path: '/dataexplorer/wallets/create',
        name: 'DatawalletCreate',
        component: DatawalletCreate
      },
      // {
      //   path: '/dataexplorer/wallets/edit/:publicId',
      //   name: 'DatawalletEdit',
      //   component: DatawalletEdit
      // },
      {
        path: '/dataexplorer/marketplace',
        name: 'Marketplace',
        component: Marketplace
      }
    ]
  },
  // TODO is this being used?
  { path: '/view', component: View },
  { path: '/datasource/:projectName/:datasourceName', component: DatasourceView },
  { path: '/datasets/:datasetPublicId/:projectName', component: DatasetView },
  { path: '/auditmap', component: AuditMap },
  { path: '/:landscapeName/:viewName', component: View },
  { path: '/404', name: 'NotFound', component: NotFoundOrganization },
  { path: '*', component: NotFound }
]
