const api = require('./api-caller')
const SignedService = require('./signed')
const { getOrganizationPublicId, uploadToS3 } = require('../mixins/utils').methods;

// this needs to be a function because it would call getOrganizationPublicId()
// on import time, causing problems
const getBaseEndpoint = () => `/organizations/${getOrganizationPublicId()}/data-sources`;


export default {
  listDatasources: (unsuccessful) => {
    let endpoint = '/wallet/data-sources';

    if (unsuccessful) {
      endpoint += `?status=success&statusOperator=!=`;
    }

    return api(endpoint, 'GET');
  },
  listDatasets: (id) => {
    const endpoint = `/wallet/data-sources/${id}/datasets`;
    return api(endpoint, 'GET');
  },
  getDatasourceByPublicId: (id) => {
    const endpoint = `/wallet/data-sources/edit/${id}`;
    return api(endpoint, 'GET');
  },
  deleteDatasource: publicId => {
    const endpoint = `${getBaseEndpoint()}/${publicId}`;
    return api(endpoint, "DELETE");
  },
  createDatasource: (formData, params = null) => {
    if (!params) {
      return api(getBaseEndpoint(), 'POST', formData);
    }
    return new Promise(function (resolve, reject) {
      delete formData.file.content;

      api(getBaseEndpoint(), 'POST', formData).then(res => {
        SignedService.upload(params).then(res => {
          params.url = res;
          uploadToS3(params.url, params.content, params.fileType).then(res => resolve(res)).catch(err => reject(err))
        }).catch(err => reject(err))
      }).catch(err => reject(err))
    })
  },
  updateDatasource: (dsName, dataset) => {
    const endpoint = `${getBaseEndpoint()}/${encodeURI(dsName)}`;
    return api(endpoint, 'PUT', dataset);
  }
}
