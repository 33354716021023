const api = require('./api-caller')
const baseEndpoint = '/organizations'
const { getOrganizationPublicId } = require('../mixins/utils').methods;


module.exports = {
  listDatasetFields: (id) => {
    const endpoint = `/wallet/datasets/${id}/fields`
    return api(endpoint, 'GET');
  },
  getDatasetByPublicId: (id) => {
    const endpoint = `/wallet/datasets/${id}`
    return api(endpoint, 'GET')
  },
  getAllDatasets: () => {
    const endpoint = `/wallet/datasets`
    return api(endpoint, 'GET')
  },
  getSavingsAccountDatasets: () => {
    const endpoint = `/wallet/datasets?section=savings`
    return api(endpoint, 'GET')
  },
  getCheckingAccountDatasets: () => {
    const endpoint = `/wallet/datasets?section=checking`
    return api(endpoint, 'GET')
  },
  getCertificate: (id) => {
    const endpoint = `/certificates/${id}`
    return api(endpoint, 'GET')
  },
  updateRightHolder: (id, json) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/datasets/${id}`
    return api(endpoint, 'PATCH', json)
  },
  updateFields: (id, fields) => {
    const endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/datasets/${id}/fields`
    return api(endpoint, 'PATCH', fields)
  }
}
