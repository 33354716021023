<template>
  <div class="sidebar">
    <div class="sidebar-wrapper">
      <slot name="content"></slot>

      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link,index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            @click="closeNavbar"
            :link="link"
          >
            <i :class="link.icon"></i>
            <p>{{link.name}}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
</template>


<script>
import SidebarLink from "./SidebarLink.vue";
import Utils from "src/mixins/utils";
import SideBarBg from "../../assets/imgs/side_bar_bg.jpg";

export default {
  mixins: [Utils],
  components: {
    SidebarLink
  },
  data() {
    return {
    };
  },
  methods: {
  },
  props: {
    title: {
      type: String,
      default: "Lichen"
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style>
.sidebar-wrapper {
  background: #fff;
  top: 50px;
}
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
  padding: 0px 40px;
  margin-top: 40px !important;
}
.sidebar .nav li .nav-link, body > .navbar-collapse .nav li .nav-link {
  color: #6D6E71 !important;
  margin: 0px 15px !important;
  padding: 1px 0px !important;
  border-radius: 0 !important;
  margin: 1px !important;
  border-bottom: solid 1px #E6E7E8 !important;
}
.sidebar .nav li .nav-link:hover, body > .navbar-collapse .nav li .nav-link:hover, .sidebar .nav li.active .nav-link, body > .navbar-collapse .nav li.active .nav-link {
  border-bottom: solid 1px #EC008C !important;
  opacity: 1;
}
.sidebar .nav li.active .nav-link p, body > .navbar-collapse .nav li.active .nav-link p{
  font-weight: 700 !important;
  color: #414042 !important;
}
.sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
  margin: 0;
  line-height: 31px;
  font-size: 15px !important;
  font-weight: 400 !important;
}
body > .navbar-collapse .nav li .nav-link p {
  text-transform: capitalize !important;
}
</style>
