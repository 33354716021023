const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;

const baseEndpoint = '/organizations';


module.exports = {
    listUsers: () => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/users`;
        return api(endpoint, 'GET')
    },
    deleteUser: (userID) => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/users/${userID}`;
        return api(endpoint, 'DELETE')
    },
    createUser: (createUser) => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/users/`;
        return api(endpoint, 'POST', createUser)
    },
    editUser: (userID, editUser) => {
        let endpoint = `${baseEndpoint}/${getOrganizationPublicId()}/users/${userID}`;
        return api(endpoint, 'PUT', editUser);
    }
}
