<template>
  <center style="padding-top: 80px;">
    <div class="topBar">
      <img :src="organizationLogo" style="height: 40px;"/>
    </div>
    <div  v-if="submitSuccess==false">
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div></div>
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <div class="logo_container"><img :src="organizationLogo" /></div>

          <h1 class="c-login-page-title">
            {{ this.isFirstLogin ? $t('authN.newPassword.firstTimeTitle') : $t('authN.newPassword.title') }}
          </h1>
          <small style="font-size: 14px">
            <strong>{{$t('authN.email')}}:</strong> {{this.userRecover.user.email}}
          </small>
          <div class="login_form" @keydown.enter="submitForm">
            <div v-if="log != ''" class="error">
              <span style="white-space: pre-line">{{log}}</span>
            </div>
            <div class="o-form-group">
              <input v-model="password" class="form-control" name="password" type="password" :placeholder="$t('authN.password')" value required autofocus />
            </div>
            <div class="o-form-group">
              <input v-model="passwordConfirm" class="form-control" name="password" type="password" :placeholder="$t('authN.passwordConfirm')" value required />
            </div>
            <div class="o-form-group terms-of-use" v-if="isFirstLogin==true">
              <input class="c-login-page__checkbox" v-model="terms" name="accepted_terms" type="checkbox" value="on" required />
              {{$t('authN.newPassword.termsOfUse1')}}
              <a id="terms" href="/#/terms-of-use" target="_blank" class="nav-link" style="color: rgb(153, 153, 153);text-decoration: underline;display: inline-block;padding-left: 2px;">{{$t('authN.newPassword.termsOfUse2')}}</a>
              <br />
            </div>
            <div class="o-form-group">
              <button v-on:click="submitForm" class="baseButton">{{$t('authN.button.confirm')}}</button>
            </div>
            <br />
          </div>
          <br />
          <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="o-page c-login-page">
        <div class="c-login-page__content">
          <div><img src="../assets/imgs/form-header.png" style="max-width: 320px;margin-bottom: 20px;"></div>
          <h1 class="c-login-page-title">{{$t('authN.newPassword.success.title')}}</h1>
          <p>{{$t('authN.newPassword.success.p1')}}</p>
          <p>{{$t('authN.newPassword.success.p2')}}</p><br>
          <div class="c-login-page__footer__text">{{$t('general.copyright')}}</div>
        </div>
      </div>
    </div>
  </center>
</template>

<script>
import Vue from "vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import BaseButton from "src/components/BaseButton.vue";
import api from "src/services/api-caller";
import LoginService from 'src/services/login';
import Swal from 'sweetalert2'
import defaultLogo from 'src/assets/imgs/default/drumwave_logo.png'

export default {
    components: {
        BaseInput,
        BaseButton
    },
    data() {
        return {
            password: "",
            passwordConfirm: "",
            terms: false,
            userRecover: {},
            isFirstLogin: true,
            submitSuccess: false,
            log: '',
            organizationLogo: '',
            organizationPublicId: ''
        };
    },
    methods: {
        submitForm() {
            this.log = '';
            if (this.password == '' || this.passwordConfirm == '') {
                this.log = this.$t('authN.newPassword.error.empty');
            }
            if (this.password !== this.passwordConfirm && this.password != '' && this.passwordConfirm != '') {
                if (this.log.length > 0) this.log += '\n';
                this.log += this.$t('authN.newPassword.error.dontMatch');
            }
            if (this.terms == false && this.isFirstLogin == true) {
                if (this.log.length > 0) this.log += '\n';
                this.log += this.$t('authN.newPassword.error.termsOfUse');
            }
            if(this.password.length < 6) {
                if (this.log.length > 0) this.log += '\n';
                this.log += this.$t('authN.newPassword.error.length');
            }
            if (this.log.length > 0) {
                return;
            }
            let checked = this.terms ? "on" : "off"
            api(`/user-recovery/${this.$route.params.recoverId}`, "POST", {
                    user_name: this.username,
                    password: this.password,
                    confirm_password: this.passwordConfirm,
                    accepted_terms: checked
                })
                .then(res => {
                    this.submitSuccess = true
                    setTimeout(function(){window.location = '/'}, 3000);
                })
                .catch(err => {
                  console.log(err);
                  let error = err[1];
                  this.log = error.message;
                   Swal.fire({
                      title: 'Error!',
                      text: error.message,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      onBeforeOpen: () => Swal.hideLoading()
                    });
                });
        }
    },
    mounted() {
    },
    async beforeMount() {
        const subdomain = window.location.host.split('.')[0];

        try {
          const org = await LoginService.getOrganization(subdomain);
          this.organizationLogo = org.loginLogoUrl || defaultLogo;
          this.organizationPublicId = org.publicId;

        } catch (err) {
          console.error(err)
        }

        /*
        // Mock object to work on this page without an actual recoverId
        this.userRecover = { user:{ acceptedAt:"2020-06-08", email:"john@dummy.com"}} //recover.userRecovery
        return;
        */

        api(`/user-recovery/${this.$route.params.recoverId}`, "GET")
        .then(recover => {
            this.userRecover = recover.userRecovery
            if(this.userRecover.user.acceptedAt){this.isFirstLogin = false}
        })
        .catch(err => console.log(err))
    }
};
</script>

<style scoped>

.topBar{
  position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background-color: #252223;
    z-index: 100;
    padding: 5px;
    text-align: left;
}

.logo_container img{
      max-width: 400px;
    max-height: 200px;
}

.logo_container{
     background-color: #ddd;
    width: min-content;
    margin: 10px 40px 40px 40px;
    border-radius: 20px;
    padding: 10px;
    display: none;
}

.login_form{
  width: 300px;
  font-family: 'titillium_webregular';
}

.login_form input {
  margin-bottom: 20px;
  font: Arial, Helvetica, sans-serif;
  border: 1px solid #ccc;
}


.login_form input:hover{
  border-color: #333;
}

.login_form input:focus{
  border-color: #333;
}

.login_form input::placeholder {
  color: #999;
}

.baseButton{
  display: inline-block;
  min-width: fit-content;
  min-width: -moz-max-content;
  width: 10px;
  margin: 0.5em;
  padding: 1em 2em;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: gray;
  font-size: 1em;
  line-height: 1;
  transition: 0.3s;
}
.baseButton:hover{
  background: #e8e5e5;
  color: #555;
}

.form-control{
  margin-top: 10px;
  margin-bottom: 10px;

}

.error {
  padding: 5px;
  background-color: #f44336;
  color: white;
  margin-bottom: 20px !important;
}
</style>
