<template>
  <div id="deleteModal" class="screen-area invisible">
    <div class="modal-container">
      <h3>{{title}}</h3>
      <p v-html="$t('modal.delete.question')"></p>
      <BaseButton
        :buttonLabel="$t('modal.delete.confirm')"
        :onClick="onClickDelete"
        style="color: red;"
      ></BaseButton>
      <BaseButton :buttonLabel="$t('modal.delete.cancel')" :onClick="onClickQuit"></BaseButton>
    </div>
  </div>
</template>


<script>
import BaseButton from "./BaseButton.vue";
export default {
  name: "DelModal",
  components: {
    BaseButton: BaseButton
  },
  props: {
    onClickDelete: {
      type: Function,
      required: true
    },
    onClickQuit: {
      type: Function,
      required: true
    },
    title: {
      type: String
    }
  }
};
</script>


<style scoped>
.screen-area {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(128, 128, 128, 0.5);
}

.modal-container {
  border: 1px solid gray;
  background-color: white;
  padding: 25px 50px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invisible {
  display: none;
}
</style>
