const api = require('./api-caller');
const { setAuthZToken, setOrganization, removeUserInfo, removeAuthZToken } = require('../mixins/utils').methods;


export default {
  authenticate: async (organizationPublicId, username, password) => {
    let authNBody = {
      orgPubId: organizationPublicId,
      username,
      password
    };
    let { token } = await api('/authn', 'POST', authNBody);
    setAuthZToken(token);
    return token;
  },

  getOrganization: async (subdomain) => {
    const org = await api(`/gtw/${subdomain}`, 'GET');
    setOrganization(org);
    return org;
  },

  logout: async () => {
    await api('/authn/revoke', 'POST');
    removeUserInfo();
    removeAuthZToken();
  }
}
