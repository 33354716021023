const api = require('./api-caller');
const { getOrganizationPublicId } = require('../mixins/utils').methods;

const baseEndpoint = '/organizations';


module.exports = {
    listOrganizations: () => {
        let endpoint = baseEndpoint;
        return api(endpoint, 'GET');
    },
    getOrganization: (organizationId) => {
        let endpoint = baseEndpoint + '/' + organizationId;
        return api(endpoint, 'GET');
    },
    deleteOrganization: (organizationId) => {
        let endpoint = baseEndpoint + '/' + organizationId;
        return api(endpoint, 'DELETE');
    },
    createOrganization: (formData) => {
        let endpoint = baseEndpoint + '/';
        return api(endpoint, 'POST', formData);
    },
    editOrganization: (organizationId, editDataset) => {
        let endpoint = baseEndpoint + '/' + organizationId;
        return api(endpoint, 'PUT', editDataset);
    },
    listLicenseTypes: () => {
        let endpoint = baseEndpoint + '/' + getOrganizationPublicId() + '/license-types';
        return api(endpoint, 'GET');
    },
    listRoles: () => {
        let endpoint = baseEndpoint + '/' + getOrganizationPublicId() + '/roles';
        return api(endpoint, 'GET', );
    }

}
