<template>
  <div class="foldersDisplayConteiner">
    <h4 class="acessedFolder">
      <span :id="folderId" class="folderName" @click="onClick">{{acessedFolder}}</span>
      <i class="fa fa-chevron-right"></i>&nbsp;
    </h4>
  </div>
</template>


<script>
export default {
  name: "FolderDisplay",
  props: {
    acessedFolder: {
      type: String,
      description: "Acessed folder"
    },
    onClick: {
      type: Function,
      required: true
    },
    folderId: {
      type: Number,
      description: "Folder id"
    }
  }
};
</script>


<style scoped>
.acessedFolder {
  font-weight: bold;
}

.folderName {
  cursor: pointer;
}

.folderName:hover {
  text-decoration: underline;
}
</style>
